import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "@envs/environment";
import { AddContactOptions } from "@models/addContact.getResponse.model";

@Injectable({providedIn: 'root'})
export class GetResponseService {

  constructor(private http: HttpClient) {
  }

  /**
   * Adds contact to GetResponse
   * @param data Data to send to GetResponse, Contact Data
   * @returns Promise<boolean>
   */
  addContact(data: AddContactOptions): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/GetResponse/AddContact`, data)
      .toPromise()
  }

  /**
   * Adds contact to GetResponse
   * @param data Data to send to GetResponse, Contact Data
   * @returns Promise<boolean>
   */
  addContactV2(data: AddContactOptions): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/GetResponse/AddContactV2`, data)
      .toPromise()
  }

  // Gets client IPV4 address
  getClientIp(): Promise<string> {
    return this.http.get <string>("https://api.ipify.org/?format=json")
      .toPromise()
  }
}
