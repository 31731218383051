<div *ngIf="modalShow" [@fade]="modalShow" aria-labelledby="modal-title"
     aria-modal="true" class="z-30 fixed inset-0 overflow-y-auto text-focus-in-fast" role="dialog">
  <div
    class="transition-std flex items-end justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div (click)="toggleModal($event)" aria-hidden="true"
         class="fixed inset-0 bg-customDark opacity-70 transition-opacity"></div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

    <div
      class="inline-flex m-auto rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg sm:w-2/3">
      <svg (click)="toggleModal($event)"
           class="text-white absolute top-5 right-5 h-6 w-6 cursor-pointer hover:text-redCustom transition duration-200 ease-in-out"
           fill="none"
           stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </svg>

      <div
        class="flex bg-customDark border rounded-lg overflow-visible border-gray-700 px-4 pt-5 pb-4 sm:p-10 sm:pb-4 w-full">
        <div class="mx-auto">
          <div class="flex w-full">
            <div class="mt-5 md:mt-0 md:col-span-6">
              <div class="flex m-auto w-full overflow-hidden sm:rounded-md">
                <ng-container [ngSwitch]="isUserInGuild">
                  <app-subscription-disclaimer
                    *ngSwitchCase="true"
                    [isIndicator]="subscriptionData?.product.productType === productType.Pacchetto_Indicatori"
                    [disclaimerShow]="subscriptionData.show"
                    [subscriptionCycle]="subscriptionData?.product.subscriptionCycle"
                    [subscriptionFreeTrial]="subscriptionData?.product.freeTrialDuration"
                    [subscriptionPrice]="subscriptionData?.product.price"
                    [secondSubscriptionPrice]="subscriptionData?.product.payPalPrice"
                    [firstSubscriptionPriceId]="subscriptionData?.product.stripePriceId"
                    [secondSubscriptionPriceId]="subscriptionData?.product.stripeSubscriptionPriceId"
                    (confirmationCallback)="startSubscription($event)">
                  </app-subscription-disclaimer>
                  <div *ngSwitchCase="false">
                    <div class="flex flex-col w-full h-full m-auto space-y-4">
                      <h2
                        class="underline underline-offset-4 decoration-redCustom py-3 text-center text-2xl sm:text-3xl text-bold text-white">
                        Accedi con Discord
                      </h2>
                      <p class="text-gray-400 text-sm text-center">
                        Effettua l'accesso con Discord per poter attivare
                        l'abbonamento {{subscriptionData.product.name}}
                      </p>
                      <div class="w-full m-auto text-center items-center justify-center">
                        <button type="button" (click)="loginWithDiscord()"
                                class="inline-flex m-auto button-discord">
                          <span class="inline-flex my-auto">Continua</span>
                          <svg class="pl-1 m-auto" width="25" height="25" fill="currentColor"
                               viewBox="0 0 24 24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M21.75 3.118A2.344 2.344 0 0 0 19.412.797L4.577.75A2.327 2.327 0 0 0 2.25 3.071v15.304c0 1.28 1.044 2.25 2.327 2.25H17.25l-.61-2.063 5.11 4.688V3.118ZM15.218 15.46s-.409-.487-.75-.906c1.488-.419 2.055-1.335 2.055-1.335a6.517 6.517 0 0 1-1.305.668c-.53.22-1.082.383-1.646.487a7.973 7.973 0 0 1-2.94-.011 8.65 8.65 0 0 1-1.668-.488 6.647 6.647 0 0 1-.829-.385c-.034-.022-.068-.034-.102-.057-.023-.01-.034-.022-.047-.022-.204-.114-.318-.193-.318-.193s.545.895 1.987 1.325c-.34.43-.76.929-.76.929-2.51-.08-3.463-1.71-3.463-1.71 0-3.612 1.635-6.545 1.635-6.545C8.7 6.006 10.245 6.04 10.245 6.04l.113.136c-2.043.577-2.973 1.472-2.973 1.472s.25-.136.67-.318c1.214-.532 2.179-.668 2.577-.713.064-.013.128-.02.193-.023a9.64 9.64 0 0 1 2.293-.022 9.451 9.451 0 0 1 3.417 1.076s-.897-.851-2.827-1.428l.159-.18s1.555-.035 3.179 1.179c0 0 1.634 2.932 1.634 6.544 0-.013-.954 1.618-3.462 1.697Z"></path>
                            <path
                              d="M9.94 10.219c-.647 0-1.158.555-1.158 1.245 0 .69.522 1.246 1.158 1.246.647 0 1.158-.555 1.158-1.246.012-.692-.511-1.245-1.158-1.245Z"></path>
                            <path
                              d="M14.083 10.219c-.647 0-1.158.555-1.158 1.245 0 .69.522 1.246 1.158 1.246.647 0 1.157-.555 1.157-1.246 0-.69-.521-1.245-1.157-1.245Z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
