<div *ngIf="loading" [@fade]="loading" class="relative flex bg-customDark h-screen">
  <div class="m-auto text-center relative">
    <div class="w-48 h-48 border-4 border-white border-solid rounded-full animate-spin"
         style="border-top-color:transparent"></div>
    <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-2/3">
      <img alt="" class="animate-pulse m-auto" src="../../../../assets/images/tt_logo.png" width="100">
    </div>
    <p class="leading-relaxed text-white text-2xl mt-2">Carico...</p>
  </div>
</div>
