<div
  class="flex justify-center min-h-screen w-full h-full sm:block sm:p-0 bg-customDark bg-cover bg-no-repeat text-white">
  <div class="container mx-auto pb-8 text-focus-in px-8">
    <div class="flex flex-col text-center pt-24 font-bold">
      <h1 class="text-3xl font-bold underline decoration-redCustom underline-offset-2 text-center">
        Termini & Condizioni
      </h1>
      <h1 class="text-xl mx-auto py-8">
        TTGAMES SRL TERMINI E CONDIZIONI D’USO, anno 2020 Versione 1
      </h1>
    </div>
    <div class="flex flex-col mx-auto max-w-6xl">
      <p>
        Benvenuti sul sito Internet (sito web) “top-tradingacademy.com” logo registrato, di proprietà di TTGames s.r.l. con sede legale in Via Edomondo Borri 8,
        28922 Verbania (VB) Italia, P.iva 01978000030 (in seguito chiamata TTG).
      </p>

      <h1 class="text-xl font-bold py-6">
        1) Termini e condizioni per l’uso del Sito Web
      </h1>
      <p>
        L’utilizzo di questo sito è soggetto alle Condizioni per l’uso di Siti Internet (“Contratto Utente”). Siete
        pregati di leggere attentamente il presente contratto per l’Utente e tutte le altre informazioni a cui si fa
        riferimento, assicurandovi di comprendere quanto esplicato. Questo contratto è legalmente vincolante e contiene
        importanti informazioni relative al nostro Sito web, i servizi offerti, la vostra autorizzazione all’uso e le
        vostre responsabilità in riferimento all’utilizzo. Prima di poter accedere al sito durante la prima
        registrazione all’Utente compaiono: i “Termini e Condizioni” del sito, il “Disclaimer” e la “Normativa sulla
        Privacy” affinché possano essere letti con attenzione e accettati. La lettura e l’accettazione sono obbligatori
        per poter terminare la registrazione ed accedere al sito. TTG potrà modificare unilateralmente di volta in volta
        il presente contratto e le condizioni di utilizzo. La lettura e l’accettazione saranno obbligatori per poter
        accedere al sito, anche ogni volta che ci siano state modifiche unilaterali. Nel caso l’Utente non volesse
        accettare le condizioni e le modifiche effettuate da TTG, potrà rifiutarsi e non utilizzare più il sito. L’uso
        del Sito Web costituisce in ogni caso accettazione dei vincoli e del rispetto di tutti i termini e le condizioni
        del presente contratto per l’Utente. Nel caso foste in disaccordo con i termini e le condizioni in esso
        riportate, questo Sito Web non potrà essere utilizzato.
      </p>

      <h1 class="text-xl font-bold py-6">
        2) Diritto d’uso del Sito web
      </h1>
      <p>
        TTG vi concede una licenza limitata, non esclusiva e revocabile, per l’uso del presente Sito Web. Gli strumenti del
        sito sono resi disponibili agli Utenti secondo tutti i termini del presente contratto. Il Sito Web contiene
        materiali relativi a TTG ed ai suoi prodotti e servizi (denominato il “Contenuto”). Il contenuto può essere
        sotto forma di: dirette, informazioni, testo, dati, immagini, grafici, video, video Corsi, filmati, fotografie,
        icone dei tasti, indicatori, marchi registrati e non, illustrazioni, audio clip, musica, suoni, figure, software,
        algoritmi o altre forme e formati attualmente noti e presenti o inventati in seguito. Nell’utilizzare questo
        Sito dovete rispettare i diritti di proprietà intellettuale di TTG e dei propri operatori, tutti come elencati
        di seguito. L’uso non autorizzato del Contenuto può rappresentare una violazione delle leggi sul Copyright,
        marchi registrati, proprietà intellettuale, privacy, pubblicità e comunicazioni o altre leggi. Qualunque uso di
        tale tipologia, può risultare vostra responsabilità personale, inclusa la potenziale responsabilità penale.
      </p>

      <h1 class="text-xl font-bold py-6">
        3) Durata, diritto di recesso e regole del contratto di fornitura dei servizi
      </h1>
      <p>
        L’Utente che acquista prodotti il Contenuto sul sito TTG accetta espressamente che tutti gli acquisti effettuati
        vengano forniti a pagamento, ad eccetto del primo mese di prova dei soli Contenuti che lo prevedono. Il pagamento
        prevede solo la possibilità di visionare i contenuti ma non di effettuarne il Download o copie. Una volta acquistato
        il Contenuto, resta visibile all’utente a tempo indeterminato, a meno che TTgames non decida di eliminarne i
        contenuti in quanto considerati obsoleti. Affinché possano essere considerarti obsoleti i contenuti dovranno essere
        trascorsi almeno 60 (sessanta) giorni dall’acquisto. TTgames potrà ugualmente sostituire i Contenuti acquistati
        dall’Utente, quando lo riterrà necessario dandone semplice comunicazione di avvenuta variazione. L’Utente accetta
        inderogabilmente e senza diritto di opposizione le condizioni previste nel presente articolo, nessuna somma potrà
        essere pretesa quale risarcimento da parte dell’Utente nei confronti di TTG.
      </p>

      <h1 class="text-xl font-bold py-6">
        4) Divieti all’Utente
      </h1>
      <p>
        Il Contenuto è protetto da copyright di proprietà e da proprietà intellettuale, deve essere utilizzato
        esclusivamente secondo le autorizzazione di TTG previste nei presenti Termini e Condizioni di Utilizzo.
        Ad eccezione di quanto disposto nel presente contratto, l’Utente non può riprodurre, distribuire, pubblicare,
        trasmettere, condividere, modificare, adattare, tradurre, esporre, distribuire, vendere, concedere in licenza,
        esibire pubblicamente, preparare opere derivate basate su componenti del sito, e/o  corsi in esso presenti,
        o altrimenti utilizzare o sfruttare il Contenuto. L’utente non potrà in alcun modo sviluppare, senza
        autorizzazione scritta di TTgames s.r.l. nessuna opera informatica derivante dai prodotti esposti sul sito, a
        mero titolo di esempio non potrà sviluppare e/o riprodurre: Expert  Advisor, Trading System, Bot operativi
        nel trading online ed ogni altro software, anche sviluppato tramite intelligenza artificiale, che abbia come
        fine l’operatività in ambito finanziario rivolto al trading o agli investimenti , la gestione di portafogli,
        od ogni altro ambito finanziario e di borsa valori. L’utente si obbliga a non sviluppare nessun tipo di
        sistema automatico per l’operatività nel trading online tramite il Know How acquisito attraverso il Contenuto
        del sito TTG, a tal proposito TTGames sarà legittimata a sospendere la visione del Contenuto all’Utente che
        avrà manifestato l’intenzione di sviluppare tali sistemi automatici al fine di proteggere il Contenuto stesso.
        L’Utente accetta inderogabilmente e senza diritto di opposizione la facoltà di TTGames di sospendere la
        fornitura dei Contenuti, ove sia comprovato l’interesse dell’Utente di sviluppare sistemi automatici, pertanto
        nessuna somma potrà essere pretesa quale risarcimento da parte dell’Utente nei confronti di TTG. L’utente
        non può scaricare su proprio personal computer una copia, o parte del Contenuto e dei servizi, siano gratuiti
        che a pagamento, ne può effettuarne dupplicati di registrazione, ne per propria visione né ad uso personale,
        né commerciale, né può diffonderli in rete. Nel caso in cui sia sospetta una distribuzione e/o condivisione
        con terzi, da parte dell’Utente, TTG avrà il diritto di sospendere immediatamente la fornitura dei Contenuti,
        nessuna somma potrà essere pretesa quale risarcimento da parte dell’Utente nei confronti di TTG. Nel caso sia
        comprovata una duplicazione e/o una diffusione dei contenuti, TTgames potrà richiedere un rimborso di 50.000,00
        euro (Cinquantamila), oltre l’eventuale maggior danno subito. L’Utente accetta inderogabilmente e senza diritto
        di opposizione tale condizione in caso di diffusione, da parte sua, del Contenuto. L’uso del Contenuto e dei
        Servizi, anche onerosi, non autorizza ad alcuna proprietà, l’Utente è consapevole che il pagamento dei servizi
        di TTG è relativo al solo uso personale e non attribuisce passaggio di alcuna proprietà. Nessuna parte contenuta
        nel presente Contratto per l’Utente può essere interpretata quale conferimento (per implicazione, preclusione,
        o altrimenti) di licenza o diritto nei meriti del Contenuto secondo alcuni Copyright o altro di proprietà intellettuale.
      </p>

      <h1 class="text-xl font-bold py-6">
        5) Marchi registrati
      </h1>
      <p>
        I marchi registrati, logo e marchi di servizio presenti in questo Sito Web sono di proprietà di TTG e la
        conformazione commerciale di questo Sito Web è anch’essa di TTG. Niente di quanto contenuto in questo Sito Web
        può essere interpretato per implicazione, preclusione o altrimenti come una concessione di diritti o licenze
        all’uso dei marchi registrati.
      </p>

      <h1 class="text-xl font-bold py-6">
        6) Limitazioni d'uso
      </h1>
      <p>
        L’Utente accetta di non: (a) usare questo Sito Web per scopi commerciali o politici (inclusi, senza limitazione
        alcuna per scopi pubblicitari: richiesta fondi, raccolta prezzi di prodotti o per vendere prodotti); (b)
        monitorare, raccogliere o copiare il Contenuto di questo Sito Web utilizzando robot, “bot”, spider, crawler,
        spyware, motori, dispositivi, software, strumenti di estrapolazione o altri dispositivi automatici, utility, o
        processi manuali di alcun tipo; (c) eseguire il frame o utilizzare tecniche di framing per allegare Marchi
        Registrati o altre informazioni di proprietà (inclusi, senza limitazione alcuna, immagini, testi o layout di
        pagina); (d) usare meta tag o altri “testi nascosti” utilizzando Marchi Registrati; (e) intraprendere attività
        attraverso o in connessione con questo Sito Web per cercare di arrecare danno a minori o che siano illegali,
        offensive, oscene, intimidanti, moleste o abusive, o che violino i diritti di terzi; (f) intraprendere attività
        che interferiscano con l’accesso degli utenti a questo Sito Web o al suo corretto funzionamento; (g) Utilizzare
        questo Sito Web per spacciarsi come altre persone o entità.
      </p>

      <h1 class="text-xl font-bold py-6">
        7) Investigazioni; Cooperazione con le Forze dell’Ordine; Interruzione
      </h1>
      <p>
        TTG si riserva il diritto, senza limitazione alcuna, di: (a) investigare ogni violazione sospetta della
        sicurezza del proprio Sito Web o della sua tecnologia di informazione o di altri sistemi o reti, (b)
        investigare ogni violazione sospetta del presente Contratto per l’Utente (c) coinvolgere e cooperare
        con le forze dell’ordine per le indagini relative alle suddette questioni, (d) perseguire le violazioni del
        presente Contratto per l’Utente per quanto ammesso dalla legge e (e) interrompere questo Sito Web o impedirne
        l’ accesso ad esso, od al Contenuto, in qualunque momento senza alcun preavviso, per qualunque ragione e
        senza obbligo alcuno nei confronti dell’utente, che accetta sin d’ora l’interruzione del servizio da parte di
        TTG, a proprio insindacabile giudizio. Nel caso in cui TTG decida di interrompere all’Utente, un Contenuto in
        abbonamento mensile prima della relativa scadenza , restituirà parte della somma pagata all’Utente, in
        proporzione tra il periodo pagato e quanto mancante alla scadenza dell’abbonamento e/o dell’acquisto (per
        scadenza di acquisto si intendono 60 giorni, trascorsi dall’acquisto, come precisato nel precedente articolo 3).
      </p>

      <h1 class="text-xl font-bold py-6">
        8) Privacy e Misure di Sicurezza
      </h1>
      <p>
        TTG può raccogliere informazioni specifiche per il funzionamento del Sito Web e per soddisfare le richieste e/o
        consentire la partecipazione ad attività online specifiche, sempre rispettando la privacy dei propri visitatori.
        TTG adotta misure di sicurezza valide e consone per prevenire la perdita, l’abuso e l’alterazione dei dati
        personali sotto il proprio controllo (Vedere Privacy Policy Sito)
      </p>

      <h1 class="text-xl font-bold py-6">
        9) Servizi offerti per esclusiva finalità didattica
      </h1>
      <p>
        Tutti i Contenuti offerti da TTG attraverso il sito, tra cui Ammiratore, corsi di formazione, lezioni private,
        percorsi fomrmativi , indicatori, ecc., hanno “esclusivo scopo didattico”, fanno parte di un percorso formativo
        erogato da TTG, o altri siti di proprietà. Per nessun motivo i servizi, e le dirette di trading live offerte,
        possono essere considerati consulenza o “raccomandazione di investimento” o “incentivo all’ investimento”, né
        in forma esplicita o implicita. Ogni utente è personalmente ed esclusivamente responsabile di un utilizzo del
        servizio diverso da quello didattico con finalità di analisi, esercizio e formazione. Per nessun motivo
        TTG può essere considerata responsabile per utilizzi diversi, da parte dell’utente, rispetto a quelli delineati
        nel presente articolo che, se intrapresi, sono di “esclusiva iniziativa” e responsabilità dell’Utente stesso.
      </p>

      <h1 class="text-xl font-bold py-6">
        9.1 Indicatori analisi tecnica e fondamentale
      </h1>
      <p>
        Tutti gli indicatori (anche ciclici) presenti on-line sul sito, sono concessi in uso e visione agli Utenti a
        mero scopo didattico per sviluppare proprie ed “autonome” analisi di mercato e per esercitarsi nel Trading
        “simulato”. Nessun indicatore può essere considerato in alcun modo consulenza o “raccomandazione di
        investimento” o “incentivo all’ investimento”, né in forma esplicita che implicita. Nessun indicatore è
        necessariamente predittivo dell’andamento dei mercati. Risultati passati ottenuti attraverso gli indicatori, se
        pur positivi, non sono in alcun modo garanzia di risultati futuri altrettanto positivi. L’uso degli indicatori
        fa parte di un percorso formativo e didattico, gli Utenti sono quindi messi adeguatamente al corrente sulle
        finalità esclusivamente didattiche dell’uso di tali indicatori. TTG ed i propri autori negano nel modo più
        assoluto un uso degli indicatori presenti sul sito per finalità “operative reali” nel trading o negli
        investimenti. Per nessun motivo TTG (o i propri autori) potranno essere considerati responsabili per utilizzi
        diversi, da parte dell’utente, rispetto a quelli delineati nel presente articolo che, se intrapresi, sono di
        “esclusiva iniziativa” e responsabilità dell’Utente stesso.
      </p>

      <h1 class="text-xl font-bold py-6">
        9.2 Ammiratore
      </h1>
      <p>
        Le strategie (anche definite Trading System) presenti in diretta sul sito, sono visibili agli Utenti a mero
        scopo didattico per poter confrontare la propria operatività di trading “simulato” con quella dell’autore. Ogni
        tipo di strategie o Trading System vengono simulate da TTG ad esclusivo scopo didattico.
        Nessun tipo di strategia o Trading System può essere considerato in alcun modo consulenza
        o “raccomandazione di investimento” o “incentivo all’ investimento”, né in forma esplicita che implicita. Per
        nessun motivo TTG può essere considerata responsabile per utilizzi diversi da parte dell’utente, rispetto a
        quelli delineati nel presente articolo che, se intrapresi, sono di “esclusiva iniziativa” dell’Utente. Le
        strategie o i Trading System espongono idee meramente soggettive, non sono necessariamente predittive
        dell’andamento dei mercati. Sono un mero esercizio didattico dell’autore ed in quanto tale devono essere
        recepite.
        TTG specifica che tali strategie o Trading System non danno alcuna garanzia di correttezza e servono solo per
        scopo didattico nel trading “simulato”. Risultati passati ottenuti attraverso tali strategie o Trading System,
        se pur positivi, non sono in alcun modo garanzia di risultati futuri altrettanto positivi. La visione di tali
        strategie o Trading System fa parte di un percorso formativo e didattico, gli Utenti sono quindi messi
        adeguatamente al corrente sulla finalità esclusivamente didattica di tali strategie o Trading System.
        TTG ed i propri autori non autorizzano nessun utente a copiare le strategie o i Trading System per finalità
        “operative reali” nel trading, o negli investimenti. Per nessun motivo TTG (o i propri autori) potranno essere
        considerati responsabili per utilizzi diversi, da parte dell’utente, rispetto a quelli delineati nel presente
        articolo che, se intrapresi, sono di “esclusiva iniziativa” e responsabilità dell’Utente stesso.
      </p>

      <h1 class="text-xl font-bold py-6">
        10) Banner, Pubblicità e Promozioni
      </h1>
      <p>
        TTG si riserva il diritto di inserire banner, pubblicità, promozioni e contenuti simili in questo Sito. Le
        interazioni, corrispondenze e trattative commerciali che l’Utente può intraprendere con pubblicitari o terzi
        riscontrati su o intrapresi mediante questo Sito Web (incluso via Siti Linkati) sono esclusivamente tra l’Utente
        e i terzi (inclusi, senza alcuna limitazione, i problemi relativi al contenuto delle pubblicità, pagamenti,
        consegna di merci, garanzie, privacy e sicurezza dei dati e simili). TTG declina ogni responsabilità ivi
        connessa.
      </p>

      <h1 class="text-xl font-bold py-6">
        11) Clausole Generali
      </h1>
      <ul class="list-disc">
        <li>
          <span class="font-bold">
            A. Indennità:
          </span>
          L’Utente accetta di risarcire qualunque danno causato a TTG, compreso il rimborso delle spese sostenute da
          TTG
          (quali a puro titolo esemplificativo e non esaustivo, spese per eventuali danni, responsabilità, costi e
          spese
          generiche, inclusi onorari ragionevoli di avvocati in caso di richieste
          di risarcimento, azioni legali, azioni, richieste o procedimenti intrapresi o avanzati, anche
          investigazione,
          difesa o risoluzione dei suddetti) derivanti a seguito dell’uso del presente Sito Web.
        </li>
        <li>
          <span class="font-bold">
            B. Giurisdizione:
          </span>
          Competenza Territoriale e Risoluzione di Dispute Alternative
          Per ogni controversia o rivendicazione relativa a questo sito Web, al Contenuto o al presente Contratto
          Utente,
          fra l’utente e TTG, sarà esclusivamente competente il Foro di Milano ove altrimenti non disposto da norme
          inderogabili di legge con applicazione della sola legge italiana.
        </li>
      </ul>
    </div>
    <!--<div class="flex m-auto mt-8 flex-row font-bold">
      <a (click)="back()" class="group">
        <h3 class="mx-auto cursor-pointer group-hover:text-redCustom transition duration-300 ease-in-out">
          <svg class="group-hover:text-redCustom transition duration-300 ease-in-out inline-flex pb-1 h-5 w-5"
               fill="none"
               stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 19l-7-7m0 0l7-7m-7 7h18" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
          </svg>
          Torna indietro
        </h3>
      </a>
    </div>-->
  </div>
</div>
