import { animate, style, transition, trigger } from '@angular/animations';

export const value =
  trigger('value', [
    transition(':increment', [
      style({ color: 'green', fontSize: '25px' }),
      animate('0.3s ease-out', style('*'))
    ]),
    transition(':decrement', [
      style({ color: 'red', fontSize: '25px' }),
      animate('0.2s ease-out', style('*'))
    ])
  ])