import { Component, EventEmitter, Output } from '@angular/core';
import { SubscriptionService } from "@services/subscription.service";
import { fade } from "@animations/fade.animation";
import { SubscriptionData } from "@models/subscriptionData.model";
import { PlayerService } from "@services/players.service";
import { DiscordService } from "@services/discord.service";
import { IAuthenticateResponse } from "@models/authenticate.model";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe-decorator";
import { Subscription } from "rxjs";
import { AuthService } from "@services/auth.service";
import { Player } from "@models/player.model";
import { StripeTTAService } from "@services/stripeTTA.service";
import { DiscordLoginResponse } from "@/helpers/modals/discordLoginResponse.model";
import { Router, RouterStateSnapshot } from "@angular/router";
import { ProductType } from "@enums/productType.enum";
import { OrderDetailService } from '@/helpers/services/orderDetails.service';
import { StripeService } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { TypePurchases } from '@/helpers/enums/typePurchases.enum';
import { OrderDetail } from '@/helpers/models/orderDetail.model';
import { OrderService } from '@/helpers/services/orders.service';

const defaultValues: OrderDetail = {
  accountId: '',
  productId: 0,
  orderId: 0,
  quantity: 1,
  orderStatus: 1,
  duration: 0,
  evasion: 0,
  freeTrialDuration: 0, 
  price: 0,
  typePurchases: TypePurchases.Days
}

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styles: [],
  animations: [fade]
})
export class SubscriptionModalComponent {

  @Output() isAlreadySubscribed: EventEmitter<boolean> = new EventEmitter<boolean>()

  subscriptionData: SubscriptionData
  currentUser: IAuthenticateResponse
  currentPlayer: Player
  snapshot: RouterStateSnapshot
  productType = ProductType

  modalShow: boolean = false
  loading: boolean = false
  hasRole: boolean = false
  isUserInGuild: boolean = false
  loadingDiscord: boolean = false
  orderDetail = defaultValues;
  orderId = 0;

  @AutoUnsubscribe()
  userSubscription: Subscription
  @AutoUnsubscribe()
  playerSubscription: Subscription
  @AutoUnsubscribe()
  dataSubscription: Subscription

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private authenticationService: AuthService,
    private stripe: StripeTTAService,
    private auth: AuthService,
    private playerService: PlayerService,
    private discordService: DiscordService,
    public subscriptionService: SubscriptionService,
    private orderDetailService: OrderDetailService,
    private stripeService: StripeService,
    private orderService: OrderService) {

    this.snapshot = router.routerState.snapshot

    this.userSubscription = this.auth.currentUser.subscribe(user => {
      this.currentUser = user
    })

    this.playerSubscription = this.playerService.currentPlayer.subscribe(async player => {
      this.currentPlayer = player
      if (player != undefined && player.username) {
        await this.checkPlayer(player?.username)
      } else {
        this.loading = false
      }
    })

    this.dataSubscription = this.subscriptionService.subscriptionData.subscribe(async data => {
      if (data != undefined) {
        let checked: boolean = false
        if (!checked) {
          checked = true
          this.modalShow = data.show
          this.subscriptionData = data
          if (data.product.productType !== ProductType.Pacchetto_Indicatori)
            await this.checkUserInGuild(this.currentUser?.username)
          else
            this.isUserInGuild = true
        }
      }
    })
  }

  //toggleModal = (event: any) => this.modalShow = !this.modalShow

  async toggleModal(event: any)
  {
    this.modalShow = !this.modalShow
  }

  /**
   * Controlla se l'utente è già iscritto al server discord,
   * se lo è verifica se ha il ruolo corrisponde al prodotto.
   * In caso lo username non fosse valido verifica se l'utente
   * ha un player associato all'account e prova con quello username
   * che se non è valido termina il controllo con una risposta negativa
   * @param userName
   */
  async checkUserInGuild(userName?: string) {
    this.loading = true
    //if (DiscordPattern.test(userName)) {
      await this.discordService.isUserInGuildByUsername(userName)
        .then(() => {
          this.isUserInGuild = true
          if (this.subscriptionData?.isAlsoDiscord) {
            this.hasDiscordRole(userName)
          }
        })
        .catch(() => {
        })
      /*} else {
      await this.checkPlayer()
    }*/
  }

  async checkPlayer(username?: string) {
    // get player
    if (username) {
      await this.checkUserInGuild(username)
    } else {
      if (this.currentUser) {
        await this.playerService.getPlayer(this.currentUser?.accountId)
      }
    }
  }

  /**
   * Controlla se l'utente ha il ruolo Discord corrispondente al prodotto, se il prodotto ha un ruolo Discord
   */
  async hasDiscordRole(validUsername?: string) {
    // Se l'utente è iscritto al server, controllo se ha il ruolo
    if (validUsername && this.subscriptionData.product.name !== '') {
      await this.discordService.hasRole(validUsername, this.subscriptionData.product.name)
        .then(() => {
          this.hasRole = true
          this.isAlreadySubscribed.emit(true)
        }).catch(() => {
          this.hasRole = false
        }).finally(() => {
          this.loading = false
        })
    }
  }

  /**
   * effettua il login con discord salvando il returnUrl,
   * in modo che quando l'utente avrà completato il login sarà
   * reindirizzato alla pagina da cui è partito il login (questa).
   * Il valore nel local storage verrà cancellato una volta letto e reindirizzato
   * l'utente
   */
  async loginWithDiscord() {
    localStorage.setItem('tta-temp-returnUrl', this.snapshot.url)
    this.loadingDiscord = true;
    await this.authenticationService.loginWithDiscord()
      .then((res: DiscordLoginResponse) => {
        window.location.href = res.url
      })
      .catch(() => {
        this.loadingDiscord = false
      })
  }

  /**
   * Crea l'evento di abbonamento lato API fornendo i dati richiesti.
   * Reindirizza l'utente alla pagina di pagamento
   * @param event evento di callBack del disclaimer abbonamento
   */
  startSubscription(event: any) {
    const isIndicator: boolean = this.subscriptionData.product.productType === ProductType.Pacchetto_Indicatori
    let chosenPrice: string = this.subscriptionData.product.stripePriceId
    if (isIndicator) {
      chosenPrice = this.subscriptionService.whichPrice.value ?? this.subscriptionData.product.stripePriceId
    }
    this.stripe.checkoutSubscription(
      this.subscriptionData.product.name,
      chosenPrice,
      this.subscriptionData.product.productId,
      this.subscriptionData.product.freeTrialDuration,
      (this.subscriptionData.product.productType === ProductType.Pacchetto_Indicatori) ? false : this.subscriptionData.isAlsoDiscord,
      isIndicator,
      this.subscriptionData.orderId = this.subscriptionData.orderId
    ).then(async (session: any) => {
/*       await this.getCurrentOrderId(this.currentUser.accountId)
            .then(async (_currentOrderId: number) => {
              await this.orderDetailService.deleteHangingOrderDetails(this.currentUser.accountId, _currentOrderId)
            }) */
      await this.createOrderDetail()
      .then(() => {
        return this.stripeService.redirectToCheckout({sessionId: session.session}).subscribe(() => {})
      })
    }).catch(() => {
      this.toastr.warning("C'è stato un errore nell'inizializzare il pagamento", "Attenzione")
     }).finally(() => {
      this.loading = false;
     })
  }

  async getCurrentOrderId(accountId: string) {
    return await this.orderService.getCurrentOrderId(accountId)
      .then(res => {
        return this.orderId = res
      })
  }
  async createOrderDetail() {
    //Periodo di prova:
    this.orderDetail.accountId = this.currentUser.accountId
    this.orderDetail.productId = this.subscriptionData.product.productId
    this.orderDetail.typePurchases = this.subscriptionData.product.typePurchases
    this.orderDetail.freeTrialDuration = this.subscriptionData.product.freeTrialDuration
    this.orderDetail.duration = this.subscriptionData.product.freeTrialDuration = 0 ? this.subscriptionData.product.duration : 0
    this.orderDetail.price = 0
    await this.orderDetailService.createBasketOrder(this.orderDetail)
    .then(async () => { 
    }).catch(() => {
      this.toastr.warning("C'è stato un errore nell'aggiunta ordine.", 'Attenzione')
    })
  }
}
