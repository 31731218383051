import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TransactionTTAPlayerRequest} from '../models/transactionTTAPlayer.request';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BankAccountService {

  public currentBalanceSubject: BehaviorSubject<number> = new BehaviorSubject(0)

  constructor(private http: HttpClient) {
  }

  public get currentBalanceValue(): number {
    return this.currentBalanceSubject.value
  }

  getBalanceMinimal(): Promise<any> {
    return this.http.get(`${environment.apiUrl}/BankAccounts/GetBalanceMinimal`)
      .toPromise()
  }

  credit(request: TransactionTTAPlayerRequest): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/BankAccounts/Credit`, request)
      .toPromise()
  }

  debit(request: TransactionTTAPlayerRequest): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/BankAccounts/Debit`, request)
      .toPromise()
  }
}
