import { animate, style, transition, trigger } from '@angular/animations';

export const exit =
  trigger(
    'exit', [
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('300ms ease-in-out', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]
  )