<div *ngIf="this.auth.shouldShowDiscordAppDownload.asObservable() | async" [@fade]
     aria-labelledby="modal-title" aria-modal="true"
     class="z-30 fixed inset-0 overflow-y-auto text-focus-in-fast" role="dialog">
  <div
    class="transition-std flex items-end justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div (click)="toggleModal($event)" aria-hidden="true"
         class="fixed inset-0 bg-customDark opacity-70 transition-opacity"></div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

    <div
      class="inline-flex m-auto rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg sm:w-2/3">
      <svg (click)="toggleModal($event)"
           class="text-white absolute top-5 right-5 h-6 w-6 cursor-pointer hover:text-redCustom transition-std"
           fill="none"
           stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </svg>

      <div
        class="flex bg-customDark border rounded-lg overflow-visible border-gray-700 px-4 pt-5 pb-4 sm:p-10 sm:pb-4 w-full">
        <div class="mx-auto">
          <div class="flex flex-col w-full">
            <div class="mt-5 md:mt-0 md:col-span-6">
              <h2
                class="underline underline-offset-4 decoration-redCustom py-3 text-center text-4xl text-bold text-white">
                Scarica l'app Discord
              </h2>
              <p class="text-gray-400 text-sm text-center">
                Clicca uno dei bottoni sottostanti per scaricare l'app Discord e rimanere aggiornato sulle novità
                Top-Trading Academy.
              </p>
            </div>
            <div class="flex flex-col m-auto items-center space-y-4 my-12">
              <a href="https://apps.apple.com/it/app/discord-parla-e-chatta/id985746746" target="_blank"
                 rel="noreferrer">
                <img src="../../../../assets/images/discord/download_appstore.png" width="180" height="auto" alt="">
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.discord&hl=it&gl=US" target="_blank"
                 rel="noreferrer">
                <img src="../../../../assets/images/discord/download_googleplay.png" width="180" height="53" alt="">
              </a>
            </div>
            <p class="text-gray-400 text-sm text-center">
              Scarica l'app e fai il login con il tuo account Discord.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>