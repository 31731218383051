<div [@fade]="product"
     class="mx-auto relative bg-grayBgDark w-full rounded-2xl transition-std border overflow-hidden border-gray-700 hover:border-gray-500">
  <a [routerLink]="['product', product.productId, product.name.split(' ').join('-').toLowerCase()]"
     class="block relative w-full h-44 sm:h-72 sm:w-72 h-72 w-77 rounded-2xl overflow-hidden"
     routerLinkActive="router-link-active">
    <!-- COMING SOON -->
    <div *ngIf="product.productAvailability === productAvailability.ComingSoon"
         class="flex absolute top-0 left-0 z-10 w-full h-full bg-black/75 backdrop-blur-sm">
      <p class="m-auto text-white font-semibold text-md md:text-xl">Coming soon...</p>
    </div>
    <!--PRODOTTO TERMINATO-->
    <div *ngIf="product.productAvailability === productAvailability.Terminato"
         class="flex absolute top-0 left-0 z-10 w-full h-full bg-black/75 backdrop-blur-sm">
      <p class="m-auto text-white font-semibold text-md md:text-xl">Terminato</p>
    </div>
    <!-- END COMING SOON -->
    <img [src]="product.imageUrl != '' ? serverUrl + product.imageUrl : defaultImage"
         alt="{{ product.name }} by Top Trading Academy"
         class="absolute object-cover object-center rounded-2xl w-full h-full block overflow-hidden"
         title="{{ product.name }} by Top Trading Academy">
  </a>
  <div class="py-4 sm:py-6">
    <h2 class="text-gray-400 text-xs tracking-widest mb-1">
      {{ productType[product.productType] | replaceAll: "_": " " }}
    </h2>
    <h3
      class="text-white title-font max-w-[18rem] text-md sm:text-lg font-medium text-ellipsis overflow-hidden">
      {{ product.name }}
    </h3>
    <ng-container *ngIf="!purchased && product.productAvailability !== productAvailability.ComingSoon
    && product.productAvailability !== productAvailability.Terminato">
      <p *ngIf="product.productType !== productType.Membership && product.productType !== productType.Pacchetto_Indicatori && product.typeCovers !== typeCovers.CoverFree"
         class="mt-1 font-bold text-gray-300">
        <span>{{product.price === 0 ? '&nbsp;' : (product.price | currency:'EUR') }}</span>
      </p>
      <p *ngIf="product.productType === productType.Membership"
         class="mt-1 font-bold text-gray-300">
        <span>{{ product.price | currency:'EUR' }} <span class="text-xs">/ Mese</span></span>
      </p>
      <p *ngIf="product.productType === productType.Pacchetto_Indicatori"
         class="flex flex-col mt-1 font-bold text-gray-300">
        <span>
        <span class="text-xs">Da&#160;</span>
          {{ product.price | currency:'EUR' }}
          <span class="text-xs">/ 3 Mesi</span>
        </span>
      </p>
    </ng-container>
    <p *ngIf="purchased" class="text-green-400">
      <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex h-4 w-4 m-auto" viewBox="0 0 20 20"
           fill="currentColor">
        <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"/>
      </svg>
      Acquistato
    </p>
    <p *ngIf="product.productAvailability === productAvailability.ComingSoon
    || product.productAvailability === productAvailability.Terminato">
      <span class="mt-3 font-bold text-gray-400">
        {{ product.productAvailability === productAvailability.ComingSoon ? 'Coming Soon...' : 'Terminato'}}
      </span>
    </p>
  </div>
</div>
