import { PlatformType } from '@/helpers/enums/platformType.indicator';
import { ProductType } from '@/helpers/enums/productType.enum';
import { TypeCovers } from '@/helpers/enums/typeCovers.enum';
import { Product } from '@/helpers/models/product.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-platform',
  templateUrl: './product-platform.component.html',
  styleUrls: ['./product-platform.component.scss']
})
export class ProductPlatformComponent implements OnInit {

  @Input() products : Product[];
  @Input() type: ProductType;

  platformType = PlatformType;
  productType = ProductType;
  typeCovers = TypeCovers;
  availablePlatformType: PlatformType [] = null;

  constructor() { }

  async ngOnInit() {
    await this.getAvailablePlatformType(this.products)
  }

  async getAvailablePlatformType(products: Product[]) {
    this.availablePlatformType = this.products
    .filter((thing, i, arr) => arr.findIndex(t => t.platformType === thing.platformType)  === i)
    .map(x => x.platformType);
  }

}
