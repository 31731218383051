<div *ngIf="!hidden" [@exitAnimation] class="flex h-24 bg-gradient-to-b bg-black">
  <svg (click)="close()" xmlns="http://www.w3.org/2000/svg"
    class="text-white absolute top-3 left-3 h-6 w-6 cursor-pointer hover:text-redCustom-light transition duration-200 ease-in-out"
    fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
  </svg>
  <div class="flex m-auto px-10">
    <h3 class="text-white text-sm md:text-base mx-auto">Questo sito web utilizza i cookie per assicurarti la migliore esperienza sul nostro
      sito web.
      <a href="https://www.iubenda.com/privacy-policy/534659/cookie-policy" target="_blank" rel="noreferrer">
        <span class="text-redCustom cursor-pointer hover:text-redCustom-light">Scopri di più sui cookie.</span>
      </a>
    </h3>
  </div>
</div>
