<div class="flex justify-center min-h-screen w-full h-full pt-4 px-4 pb-20 text-center bg-customDark">
  <app-loading-animation *ngIf="loading"></app-loading-animation>
  <div *ngIf="products && products.length === 0 && !loading" class="flex h-screen mx-auto text-focus-in">
    <h3 class="m-auto text-3xl font-bold text-white">Non ci sono prodotti disponibili...</h3>
  </div>
  <div *ngIf="products && products.length > 0" class="container md:px-8 py-6 mx-auto">
    <h1 class="title-main text-focus-in-fast">Prodotti</h1>
    <p class="mx-auto text-gray-400 mt-4 text-focus-in-fast">
      Scopri e acquista i prodotti di Top Trading Academy
    </p>
    <div class="flex flex-wrap justify-center">
      <div *ngFor="let type of availableTypes" class="flex flex-col">
        <div>
          <app-product-platform [products]="products | filterProductArray:type" [type]="type"></app-product-platform>
        </div>
      </div>
    </div>
  </div>
</div>
