import { Component, Input } from '@angular/core';
import { fade } from '@animations/fade.animation';
import { ProductAvailability } from '@enums/productAvailability.enum';
import { ProductType } from '@enums/productType.enum';
import { Product } from '@models/product.model';
import { environment } from '@envs/environment';
import { TypeCovers } from '@/helpers/enums/typeCovers.enum';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  animations: [fade]
})
export class ProductCardComponent {

  @Input() product: Product
  @Input() purchased: boolean = false;

  productType = ProductType
  productAvailability = ProductAvailability
  serverUrl: string = environment.storageUrl;
  defaultImage: string = "https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/tt_logo.png"
  typeCovers = TypeCovers;

  constructor() {
  }
}
