import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Order } from "../models/order.model";

@Injectable({providedIn: 'root'})
export class OrderService {

  constructor(private http: HttpClient) {
  }

  getOrder(accountId: string, orderId: number, refillId: number): Promise<Order> {
    return this.http.get<Order>(`${environment.apiUrl}/Orders/GetOrder/${accountId}/${orderId}/${refillId}`)
      .toPromise()
  }

  getCurrentOrder(): Promise<Order> {
    return this.http.get<Order>(`${environment.apiUrl}/Orders/GetCurrentOrder`)
      .toPromise()
  }

  getOrderNoRefill(accountId: string, orderId: number): Promise<Order> {
    return this.http.get<Order>(`${environment.apiUrl}/Orders/GetOrderNoRefill/${accountId}/${orderId}`)
      .toPromise()
  }

  searchOrders(search: string, page: number, count: number): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/Orders/SearchOrders`,
      {search: search, page: page, count: count})
      .toPromise()
  }

  searchMyOrders(search: string, page: number, count: number): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/Orders/SearchMyOrders`,
      {search: search, page: page, count: count})
      .toPromise()
  }

  createOrder(order: Order): Promise<Order> {
    return this.http.post<Order>(`${environment.apiUrl}/Orders/CreateOrder`, order)
      .toPromise()
  }

  updateOrder(order: Order): Promise<Order> {
    return this.http.post<Order>(`${environment.apiUrl}/Orders/UpdateOrder`, order)
      .toPromise()
  }

  deleteOrder(accountId: string, refillId: number, orderId: number): Promise<number> {
    return this.http.post<number>(`${environment.apiUrl}/Orders/DeleteOrder`,
      {accountId: accountId, refillId: refillId, orderId: orderId})
      .toPromise()
  }

  getCurrentOrderId(accountId: string): Promise<number> {
    return this.http.get<number>(`${environment.apiUrl}/Orders/GetCurrentOrderId/${accountId}`)
      .toPromise()
  }
}