<div *ngIf="showIntroVideo" [@fade]="showIntroVideo" aria-labelledby="modal-title"
     aria-modal="true" class="fixed z-10 inset-0 overflow-y-auto text-focus-in-fast" role="dialog">
  <div
    class="transition-std flex mx-auto items-end justify-center min-h-screen w-4/5 pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div aria-hidden="true" class="fixed inset-0 bg-customDark/70 transition-opacity"></div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

    <div
      class="inline-block align-bottom border rounded-lg overflow-visible border-gray-700 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-2/3">
      <svg (click)="toggleVideoModal($event)"
           class="text-white absolute top-5 right-5 h-6 w-6 cursor-pointer hover:text-redCustom transition-std"
           fill="none"
           stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </svg>
      <div class="flex flex-col bg-customDark rounded-lg w-full h-full pt-5">
        <h1 class="text-white mx-auto font-bold text-2xl underline decoration-redCustom underline-offset-2">
          {{ introChildName }}
        </h1>
        <div class="relative flex w-full">
          <app-video-player
            [facadeId]="introChildName"
            [label]="'Breve video di presentazione del prodotto'"
            [vimeoLink]="introChildLink"
            class="m-auto w-full h-full">
          </app-video-player>
        </div>
      </div>
    </div>
  </div>
</div>
