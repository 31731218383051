import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UploadImageComponent } from "@/components/upload-image/upload-image.component";
import { EnumToArrayPipe } from "../pipes/enumToArray.pipe";
import { FilterAssistenzaPipe } from "../pipes/filterAssistenza.pipe";
import { LoadingAnimationSmallComponent } from "../spinners/loading-animation-small/loading-animation-small.component";
import { LoadingAnimationComponent } from "../spinners/loading-animation/loading-animation.component";
import { CountdownPipe } from "../pipes/countdown.pipe";
import { FilterProductArrayPipe } from "../pipes/filterProductArray.pipe";
import { ReplaceAllPipe } from "../pipes/replaceAll.pipe";
import { InviteAFriendComponent } from "../modals/invite-a-friend/invite-a-friend.component";
import { SanitizePipe } from "../pipes/sanitize.pipe";
import { ShortNumberPipe } from "../pipes/shortNumber.pipe";
import { AnimatedDigitComponent } from "../spinners/animated-digit/animated-digit.component";
import { FilterSectionPipe } from "../pipes/filterSection.pipe";
import { HasBeenSeenPipe } from "../pipes/hasBeenSeen.pipe";
import { FilterSectionObjectArrayPipe } from "../pipes/filterSectionObject.pipe";
import { PlyrModule } from "ngx-plyr";
import { VideoPlayerComponent } from "../../components/video-player/video-player.component";
import { ModulePipe } from "../pipes/module.pipe";
import { FilterPlatformTypePipe } from "../pipes/filterProductType.pipe";
import { TitleCaseCustomPipe } from "@/helpers/pipes/titleCaseCustom.pipe";
import { FormContattoComponent } from "@/components/form-contatto/form-contatto.component";
import { DiscordAppDownloadComponent } from "@/helpers/modals/discord-app-download/discord-app-download.component";
import {
  SubscriptionDisclaimerComponent
} from "@/helpers/modals/subscription-disclaimer/subscription-disclaimer.component";
import { SubscriptionModalComponent } from "@/components/subscription-modal/subscription-modal.component";
import { FilterProductPlatformArrayPipe } from "../pipes/filterProductPlatformArray.pipe";
import { filterProductPlatformCoverArrayPipe } from "../pipes/filterProductPlatformCoverArray.pipe";

@NgModule({
  declarations: [
    FormContattoComponent,
    TitleCaseCustomPipe,
    FilterPlatformTypePipe,
    ModulePipe,
    FilterSectionObjectArrayPipe,
    HasBeenSeenPipe,
    FilterSectionPipe,
    SanitizePipe,
    FilterProductArrayPipe,
    EnumToArrayPipe,
    ReplaceAllPipe,
    FilterAssistenzaPipe,
    CountdownPipe,
    LoadingAnimationComponent,
    LoadingAnimationSmallComponent,
    UploadImageComponent,
    InviteAFriendComponent,
    ShortNumberPipe,
    AnimatedDigitComponent,
    VideoPlayerComponent,
    DiscordAppDownloadComponent,
    SubscriptionDisclaimerComponent,
    SubscriptionModalComponent,
    FilterProductPlatformArrayPipe,
    filterProductPlatformCoverArrayPipe
  ],
  imports: [
    FormsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PlyrModule
  ],
  exports: [
    FormContattoComponent,
    TitleCaseCustomPipe,
    FilterPlatformTypePipe,
    ModulePipe,
    FilterSectionObjectArrayPipe,
    HasBeenSeenPipe,
    FilterSectionPipe,
    SanitizePipe,
    FormsModule,
    PlyrModule,
    HttpClientModule,
    FilterProductArrayPipe,
    EnumToArrayPipe,
    ReplaceAllPipe,
    FilterAssistenzaPipe,
    CountdownPipe,
    LoadingAnimationComponent,
    LoadingAnimationSmallComponent,
    UploadImageComponent,
    ReactiveFormsModule,
    InviteAFriendComponent,
    ShortNumberPipe,
    AnimatedDigitComponent,
    VideoPlayerComponent,
    DiscordAppDownloadComponent,
    SubscriptionDisclaimerComponent,
    SubscriptionModalComponent,
    FilterProductPlatformArrayPipe,
    filterProductPlatformCoverArrayPipe
  ]
})

export class SharedModule {
}
