<div class="flex flex-wrap mx-auto">
    <div *ngFor="let cover of availableTypeCovers" class="flex flex-col sm:mx-auto md:mx-auto w-full sm:w-auto w-auto">
      <h1
        class="pt-8 text-3xl text-white font-semibold text-focus-in-fast underline decoration-redCustom underline-offset-2 text-center md:text-left">
        {{ !productType[type].includes('_') ? productType[type] : productType[type].replaceAll('_', ' ') }}
        <span *ngIf="platform !== platformType.Scegli"> {{ platformType[platform] }} </span>
        <span *ngIf="cover === typeCovers.CoverFree"> Free </span>
      </h1>
      <h2 class="pb-8 pt-2 text-md text-gray-400 text-focus-in-fast text-center md:text-left">
        Tutti i prodotti di
        tipo {{ !productType[type].includes('_') ? productType[type] : productType[type].replaceAll('_', ' ') }}
        <span *ngIf="platform !== platformType.Scegli"> {{ platformType[platform] }} </span>
        <span *ngIf="cover === typeCovers.CoverFree"> Free </span>
      </h2>
      <div class="w-full mx-auto grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6">
        <div *ngFor="let product of products | filterProductPlatformCoverArray:type:platform:cover">
          <app-product-card [product]="product"></app-product-card>
        </div>
      </div>
    </div>
  </div>