import { Pipe, PipeTransform } from '@angular/core';
import { ProductType } from '../enums/productType.enum';
import { PlatformType } from '../enums/platformType.indicator';
import { TypeCovers } from '../enums/typeCovers.enum';

@Pipe({
  name: 'filterProductPlatformCoverArray',
  pure: false
})

export class filterProductPlatformCoverArrayPipe implements PipeTransform {
  transform(items: any[], productType: ProductType, platformType: PlatformType, typeCovers: TypeCovers): any {
    return items.filter(item => item.productType === productType && item.platformType === platformType && item.typeCovers === typeCovers)
  }
}