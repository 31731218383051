import { Pipe, PipeTransform } from "@angular/core";
import { Product } from "../models/product.model";

@Pipe({
    name: 'filterSection'
})
export class FilterSectionPipe implements PipeTransform {
    transform(products: Product[], section: string) {
        return products.filter(x => x.productSection === section)
    }
}