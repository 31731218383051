<div
  class="flex justify-center min-h-screen w-full h-full sm:block sm:p-0 bg-customDark text-white">
  <div class="container mx-auto pb-8 text-focus-in px-8">
    <h1 class="text-3xl font-bold underline decoration-redCustom underline-offset-2 text-center pt-24">
      Disclaimer Top-Trading Academy
    </h1>
    <div class="flex flex-col mx-auto max-w-6xl pt-8 space-y-8">
      <p>
        Tutti i Servizi offerti in questo sito sono di proprietà di TTGames s.r.l. ed hanno esclusiva finalità educativa
        e didattica, il loro contenuto non costituisce alcuna forma di consulenza o “raccomandazione di investimento” o
        “incentivo all’investimento” né in forma esplicita o implicita, come delineato con precisione nei termini e
        condizioni, che devono essere “attentamente lette ed obbligatoriamente accettare” prima di poter accedere ai
        servizi.
      </p>
      <p>
        Tutte le dirette, i corsi, le lezioni private, le notifiche, i percorsi, gli indicatori, ecc., disponibili
        online, all’interno di questo sito, fanno parte di percorsi formativi ed educativi che illustrano come operare
        con il “trading” esclusivamente per scopi didattici e formativi.
      </p>
      <p>
        Lo scopo di questo sito è diffondere la cultura che il trading e gli investimenti sono attività rischiose, non
        adatte a tutti, che vanno affrontate con conoscenza e preparazione. La formazione e l’esercizio sono
        l’obbiettivo primario degli autori del sito.
      </p>
      <p>
        Qualsiasi concetto, nozione, parere o tecnica espressi all’interno delle dirette sono semplici pareri personali
        degli autori, di carattere generale, non hanno lo scopo di prestare consigli operativi personalizzati, di
        acquisto e/o vendita, né raccomandazioni personalizzate riguardo una o più operazioni relative ad un determinato
        strumento finanziario. Nessuna opinione espressa riguardante investimenti o strategie di investimento può
        pertanto considerarsi adeguata alle caratteristiche di una specifica persona in merito alla sua conoscenza ed
        esperienza del trading online ed alla sua situazione finanziaria.
      </p>
      <p>
        Ogni Utente è personalmente ed esclusivamente responsabile di un utilizzo dei Servizi diverso da quello
        didattico con finalità di analisi, esercizio e formazione. Per nessun motivo TTgames s.r.l, e/o i propri soci,
        possono essere considerati responsabili per eventuali perdite che l’Utente abbia subito durante un utilizzo dei
        Servizi diverso da quello delineato nel presente Disclaimer o sul contratto “Termini e Condizioni” che delinea
        un corretto utilizzo del sito.
      </p>
      <a [routerLink]="['/termini-condizioni']" class="hover:text-redCustom transition-std">
        Vai a Termini e Condizioni
      </a>
    </div>
  </div>
</div>