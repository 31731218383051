import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { ToastrService } from "ngx-toastr";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    private toastr: ToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(err => {
          switch (err.status) {
            case 400:
              return throwError(err)
            case 401:
              // auto logout if 401 response returned from api
              this.authenticationService.logout();
              break;
            case 429:
              // show message for rate limiting
              this.toastr.warning(err.error.details, err.error.message);
              break;
          }

          const error = err.error?.message || err.statusText;
          return throwError(error);
        }))
  }
}
