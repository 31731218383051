export enum Title {
    'Sig' = 1,
    'Sig.ra' = 2
}

export enum SocialNetworks {
    Facebook = 1,
    Twitter = 2,
    Instagram = 3,
    YouTube = 4,
    TikTok = 5,
    Snapchat = 6,
    Reddit = 7
}

export enum SocialOutlets {
    WhatsApp = 1,
    Telegram = 2,
    iMessage = 3,
    SMS = 4
}

export  enum KindOfClient {
    Privato = 1,
    Impresa = 2
}