<!-- JSON LD -->
<ngx-json-ld [json]="website"></ngx-json-ld>
<ngx-json-ld [json]="organization"></ngx-json-ld>

<!-- HOME -->
<div class="flex relative h-screen">
  <div class="flex w-full h-full bg-customDark bg-orange-blob-dark bg-cover bg-no-repeat">
    <div class="flex md:flex-row flex-col-reverse m-auto sm:px-10 items-center">
      <div
        class="flex flex-col m-auto lg:flex-row md:w-2/3 lg:pl-24 md:pr-16 md:items-start md:mb-0">
        <div class="flex flex-col m-auto space-y-4 lg:-translate-y-12">
          <h3 class="text-redCustom text-center md:text-left ml-1 sm:text-3xl tracking-in-expand">
            TopTrading
          </h3>
          <h1 class="home-title tracking-in-expand">
            Academy
          </h1>
          <p class="text-lg px-4 md:px-0 text-center md:text-left text-gray-400 text-focus-in-fast">
            Entra nel nostro mondo, ti accompagneremo verso la via del successo nel <span
            class="font-bold underline underline-offset-2 decoration-redCustom text-white">Trading Online</span>.
          </p>
          <div class="flex justify-center md:justify-start flex-wrap gap-x-4 text-black">
            <div class="flex flex-row gap-4 text-focus-in-fast">
              <button [routerLink]="['/shop']" class="button-small">
                <svg class="inline-flex my-auto h-5 w-5 mr-2" fill="none" stroke="#000"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2"/>
                </svg>
                <span class="text-black font-semibold">Shop</span>
              </button>
              <button (click)="toggleLoginModal()" *ngIf="!(currentUser && currentUser.jwtToken)"
                      [@fade]="currentUser"
                      class="button-small">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="inline-flex my-auto h-5 w-5 mr-2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"/>
                </svg>
                <span class="text-black font-semibold">Accedi</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="m-auto w-1/3 lg:w-2/3 text-focus-in-fast rounded-lg">
          <picture>          
            <img alt="Simone e Marco, istruttori di Top Trading Academy."
                 class="object-cover object-center rounded-2xl w-full h-full shadow-dark"
                 height="300"
                 src="../../../assets/images/simone.jpg"
                 title="Simone, istruttore di Top Trading Academy."
                 width="300">
          </picture>
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirm-account
  [confirmShow]="confirmShow">
</app-confirm-account>
<app-login-modal
  (confirmModal)="showConfirm($event)"
  (modalCallback)="showLogin($event)"
  [loginShow]="loginShow">
</app-login-modal>
<app-discord-app-download></app-discord-app-download>