export const environment = {
  production: true,
  // apiUrl: 'https://ttacademyapi.azurewebsites.net',
  // apiUrl: 'https://localhost:5001',
  clientUrl: 'https://shop-stage.top-tradingacademy.com',
  topTraderApi: 'https://ttgapi.toptradergame.eu',
  topTraderSecret: '984f2f05-94bb-44d5-b3a5-73274a4f4eec',
  apiUrl: 'https://api-prod.top-tradingacademy.com',
  storageUrl: 'https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/',
  functionRaceUrl: 'https://race-prod.toptradergame.eu',
  //functionAmmiratoreUrl: 'https://ttacademy-ammiratore-prod.azurewebsites.net',
  functionAmmiratoreUrl: 'https://ttacademy-ammiratore-prod.azurewebsites.net', // BACKUP
  signalRTTGProd: 'https://tickbus-prod.toptradergame.eu',
  paymentApiKey: 'pk_live_cgs3NIav0WLIN4HHt8GN2n5S00HZqEbRkT',
  DiscordAmmiratoreRoleId: '888354173144416276',
  DiscordInviteLink: 'https://discord.gg/fVZ2CFPBqn',
  ttFooterLogo: 'https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/tt_logo.png',
  getResponseCustomFieldId: 'VgYrXL', // PROD
  eBookUrl: 'https://online.fliphtml5.com/sayqh/psvb/#p=1',
  calendlyUrl: 'https://calendly.com/appuntamenti-toptradingacademy/30min',
  calendlyUrlOneToOne: 'https://calendly.com/appuntamenti-toptradingacademy/one-to-one-alberto'
};
