import { Component } from '@angular/core';
import { IAuthenticateResponse } from '../../models/authenticate.model';
import { Subscription } from "rxjs";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe-decorator";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  ttaLogoUrlWebp: string = 'https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/logo-rosso-verde.png'
  ttaLogoUrlPng: string = 'https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/logo-rosso-verde.webp'

  currentUser: IAuthenticateResponse
  @AutoUnsubscribe()
  currentUserSubscription: Subscription

  constructor() {
  }
}
