<div *ngIf="loginShow" [@fade]
     aria-labelledby="modal-title" aria-modal="true"
     class="z-30 fixed inset-0 overflow-y-auto text-focus-in-fast" role="dialog">
  <div
    class="transition-std flex items-end justify-center min-h-screen w-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div (click)="toggleModal($event)" aria-hidden="true"
         class="fixed inset-0 bg-customDark opacity-70 transition-opacity"></div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

    <div
      class="inline-flex m-auto rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg sm:w-2/3">
      <svg (click)="toggleModal($event)"
           class="text-white absolute top-5 right-5 h-6 w-6 cursor-pointer hover:text-redCustom transition-std"
           fill="none"
           stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </svg>

      <div
        class="flex bg-customDark border rounded-lg overflow-visible border-gray-700 px-4 pt-5 pb-4 sm:p-10 sm:pb-4 w-full">
        <div class="mx-auto">
          <div class="flex w-full">
            <div class="mt-5 md:mt-0 md:col-span-6">
              <form (ngSubmit)="onSubmit()" [formGroup]="form">
                <div class="overflow-hidden sm:rounded-md">
                  <div class="w-full py-5 px-4 sm:p-6">
                    <h2
                      class="mb-6 underline underline-offset-4 decoration-redCustom py-3 text-center text-4xl text-bold text-white">
                      Login
                    </h2>
                    <!-- ANAGRAFICA -->
                    <div class="grid grid-cols-6 space-y-6 pb-2">
                      <div class="col-span-6">
                        <label class="label-std-left">Email</label>
                        <input [(ngModel)]="request.email" class="input-std-dark" formControlName="email" name="email"
                               placeholder="mario@mario.it"
                               type="email">
                        <p *ngIf="m.email.invalid && m.email.dirty"
                           class="invalid-label">
                          Usa la tua email per fare l'accesso
                        </p>
                      </div>

                      <div class="col-span-6">
                        <label class="label-std-left">Password</label>
                        <input [(ngModel)]="request.password" class="input-std-dark" formControlName="password"
                               name="firstName"
                               type="password">
                        <p *ngIf="m.password.invalid && m.password.dirty"
                           class="invalid-label">
                          Inserisci la tua password
                        </p>
                      </div>
                    </div>
                    <!-- <a>
                       <p class="text-sm text-gray-300">
                         Non hai un'account?
                         <span (click)="showRegisterModal()"
                               class="text-redCustom hover:text-redCustom-light transition-std cursor-pointer">Registrati</span>
                       </p>
                     </a>-->
                    <div class="flex flex-col space-y-4 justify-center mt-4">
                      <button
                        class="button-small justify-center items-center"
                        type="submit">
                        <svg *ngIf="!loading && !succeeded" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="inline-flex my-auto h-5 w-5 mr-2">
                          <path stroke-linecap="round" stroke-linejoin="round"
                                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"/>
                        </svg>
                        <app-loading-animation-small *ngIf="loading" class="mr-2"></app-loading-animation-small>
                        <span *ngIf="!loading">Accedi</span>
                        <span *ngIf="loading">Carico</span>
                      </button>
                      <div
                        class="flex flex-row space-x-2 bg-discordBlue hover:bg-discordBlue-dark text-white transition-std rounded-full py-2 px-8 cursor-pointer">
                        <svg *ngIf="!loadingDiscord" class="pl-1 m-auto" width="20" height="20" fill="currentColor"
                             viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M21.75 3.118A2.344 2.344 0 0 0 19.412.797L4.577.75A2.327 2.327 0 0 0 2.25 3.071v15.304c0 1.28 1.044 2.25 2.327 2.25H17.25l-.61-2.063 5.11 4.688V3.118ZM15.218 15.46s-.409-.487-.75-.906c1.488-.419 2.055-1.335 2.055-1.335a6.517 6.517 0 0 1-1.305.668c-.53.22-1.082.383-1.646.487a7.973 7.973 0 0 1-2.94-.011 8.65 8.65 0 0 1-1.668-.488 6.647 6.647 0 0 1-.829-.385c-.034-.022-.068-.034-.102-.057-.023-.01-.034-.022-.047-.022-.204-.114-.318-.193-.318-.193s.545.895 1.987 1.325c-.34.43-.76.929-.76.929-2.51-.08-3.463-1.71-3.463-1.71 0-3.612 1.635-6.545 1.635-6.545C8.7 6.006 10.245 6.04 10.245 6.04l.113.136c-2.043.577-2.973 1.472-2.973 1.472s.25-.136.67-.318c1.214-.532 2.179-.668 2.577-.713.064-.013.128-.02.193-.023a9.64 9.64 0 0 1 2.293-.022 9.451 9.451 0 0 1 3.417 1.076s-.897-.851-2.827-1.428l.159-.18s1.555-.035 3.179 1.179c0 0 1.634 2.932 1.634 6.544 0-.013-.954 1.618-3.462 1.697Z"></path>
                          <path
                            d="M9.94 10.219c-.647 0-1.158.555-1.158 1.245 0 .69.522 1.246 1.158 1.246.647 0 1.158-.555 1.158-1.246.012-.692-.511-1.245-1.158-1.245Z"></path>
                          <path
                            d="M14.083 10.219c-.647 0-1.158.555-1.158 1.245 0 .69.522 1.246 1.158 1.246.647 0 1.157-.555 1.157-1.246 0-.69-.521-1.245-1.157-1.245Z"></path>
                        </svg>
                        <app-loading-animation-small
                          *ngIf="loadingDiscord" class="mr-1 inline-flex my-auto"
                          [textColor]="'#FFFFFF'">
                        </app-loading-animation-small>
                        <button type="button" (click)="loginWithDiscord()"
                                class="inline-flex text-sm m-auto">
                          Accedi o Registrati con Discord
                        </button>

                      </div>
                    </div>
                    <div class="flex flex-col justify-center text-center mt-4">
                      <a [routerLink]="['account/forgot-password']">
                        <p class="text-sm text-gray-400 hover:text-redCustom transition-std">
                          Dimenticato la password?
                        </p>
                      </a>
                      <a [routerLink]="['account/resend-email']">
                        <p class="pt-2 text-sm text-gray-400 hover:text-redCustom transition-std">
                          Non hai ricevuto la mail di conferma?
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-registration-modal
  (registerModalCallback)="toggleRegisterModal($event)"
  [registerShow]="registerShow">
</app-registration-modal>
