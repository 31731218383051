<div
  class="flex flex-col justify-center min-h-screen w-full h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-customDark">
  <div class="flex h-screen mt-24 xs:mt-12 sm:mt-0">
    <div class="container relative md:px-12 lg:px-5 sm:py-24 m-auto">
      <div class="flex flex-wrap justify-center mx-auto relative w-full lg:w-4/5">
        <!-- <div *ngIf="product != undefined
           && (product.productAvailability === productAvailability.ComingSoon
               || product.productAvailability === productAvailability.Terminato)"
              [@fade]="product"
              class="hidden lg:flex absolute top-0 left-0 lg:translate-x-[9.75rem] z-10 lg:w-1/4 h-full object-cover border border-gray-700 object-center bg-black/75 rounded-2xl backdrop-blur-sm">
           <p class="m-auto text-white font-bold text-xl">
             {{ product.productAvailability === productAvailability.ComingSoon ? 'Coming Soon...' : 'Terminato'}}
           </p>
         </div>-->
        <!-- MOBILE -->
        <div class="relative flex lg:hidden mx-auto lg:mx-0 md:w-2/5 lg:w-1/3">
          <img *ngIf="product != undefined" [@fade]="product"
               [src]="product.imageUrl != '' && product.imageUrl != undefined ? serverUrl + product.imageUrl : defaultImage"
               alt="{{ product.name }} by Top Trading Academy"
               class="bg-customDark md:h-auto h-48 object-cover border border-gray-700 object-center bg-black/75 rounded-2xl backdrop-blur-sm"
               title="{{ product.name }} by Top Trading Academy">
          <!-- fixme proprietà probabilmente opzionale quindi potrebbe servire il "?" -->
          <div
            *ngIf="product != undefined &&
            (product.productAvailability === productAvailability.ComingSoon ||
            product.productAvailability === productAvailability.Terminato)"
            [@fade]="product"
            class="flex absolute top-0 left-0 z-10 w-full h-full object-cover object-center border border-gray-700 bg-black/75 rounded-2xl backdrop-blur-sm">
            <p class="m-auto text-white font-bold text-xl">
              {{ product.productAvailability === productAvailability.ComingSoon ? 'Coming Soon...' : 'Terminato' }}
            </p>
          </div>
          <!-- SHOW VIDEO BUTTON -->
          <a (click)="showVideo()" *ngIf="product && product?.presentationVideo !== undefined"
             class="block lg:hidden">
            <div
              class="flex absolute -top-5 -right-5 bg-red-600 hover:bg-red-700 transition-std text-white h-12 w-12 rounded-full cursor-pointer">
              <svg class="inline-flex m-auto h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="2"
                   stroke="currentColor">
                <path stroke-linecap="round"
                      d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"/>
              </svg>
            </div>
          </a>
        </div>
        <!-- END MOBILE -->
        <!-- DESKTOP -->
        <div class="relative hidden lg:flex my-auto lg:w-1/4">
          <img *ngIf="product != undefined" [@fade]="product"
               [src]="product.imageUrl != '' && product.imageUrl != undefined ? serverUrl + product.imageUrl : defaultImage"
               title="{{ product.name }} by Top Trading Academy"
               alt="{{ product.name }} by Top Trading Academy"
               class="bg-customDark shadow-dark border border-gray-700 object-cover object-center rounded-2xl">
          <div *ngIf="product != undefined
          && (product.productAvailability === productAvailability.ComingSoon
              || product.productAvailability === productAvailability.Terminato)"
               [@fade]="product"
               class="flex absolute top-0 left-0 z-10 w-full h-full object-cover object-center border border-gray-700 bg-black/75 rounded-2xl backdrop-blur-sm">
            <p class="m-auto text-white font-bold text-xl">
              {{ product.productAvailability === productAvailability.ComingSoon ? 'Coming Soon...' : 'Terminato' }}
            </p>
          </div>
          <!-- SHOW VIDEO BUTTON -->
          <a (click)="showVideo()" *ngIf="product && product?.presentationVideo !== undefined"
             [@fade]="product?.presentationVideo"
             class="hidden lg:block text-focus-in-fast">
            <div
              class="flex absolute -top-5 -right-5 bg-red-600 hover:bg-red-700 transition-std text-white h-12 w-12 rounded-full cursor-pointer">
              <svg class="inline-flex m-auto h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke-width="2"
                   stroke="currentColor">
                <path stroke-linecap="round"
                      d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"/>
              </svg>
            </div>
          </a>
        </div>
        <!-- SCHEDA PRODOTTO -->
        <div *ngIf="product != undefined" [@fade]="product"
             class="flex flex-col lg:w-2/3 xl:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0 text-left">
          <h1 class="text-center lg:text-left text-sm title-font text-gray-400 tracking-widest">
            {{ productType[product.productType] | replaceAll: "_": " " }}
          </h1>
          <h2
            class="text-center lg:text-left text-white text-xl underline decoration-redCustom underline-offset-4 sm:text-3xl title-font font-medium mb-3">
            {{ product.name }}
          </h2>
          <p
            class="text-center text-sm md:text-base text-gray-300 lg:text-left">
            {{ product.description }}
          </p>
          <div class="flex mt-1 items-center border-b-2 border-gray-700 pb-5 mb-3 md:mb-5">
            <p *ngIf="product.note && product.productType !== productType.Pacchetto_Indicatori
                && !(product.productType === productType.Indicatore && product.typeCovers === typeCovers.Cover)"
               [@fade]="product.note"
               class="leading-relaxed text-center md:text-left text-sm md:text-base text-gray-400">
              Note: {{ product.note }}
            </p>
          </div>

          <div class="flex flex-col justify-center mx-auto lg:mx-0">
            <div
              class="flex flex-col items-center lg:flex-row mx-auto lg:mx-0">

              <div
                *ngIf="product.productType !== productType.Membership && product.productType !== productType.Pacchetto_Indicatori && product.typeCovers !== typeCovers.CoverFree"
                [@fade]="product"
                class="flex flex-row w-full lg:w-1/3 justify-center lg:justify-start">
                <span *ngIf="
                product.price != 0 &&
                product.productAvailability !== productAvailability.ComingSoon &&
                product.productAvailability !== productAvailability.Terminato"
                      class="inline-flex title-font font-medium text-2xl text-white">
                    {{ product.price | currency:'EUR' }}
                </span>
                <span *ngIf="product.productAvailability === productAvailability.ComingSoon
                    || product.productAvailability === productAvailability.Terminato"
                      class="inline-flex title-font font-medium text-xl text-gray-300">
                    {{ product.productAvailability === productAvailability.ComingSoon ? 'Coming Soon...' : 'Terminato' }}
                </span>
              </div>
              <div *ngIf="product.productType === productType.Membership" [@fade]="product"
                   class="flex flex-row w-full lg:w-1/3 justify-center lg:justify-start text-white">
                <span class="inline-flex title-font font-medium text-2xl">
                  {{ product.price | currency:'EUR' }}
                </span>
                <span class="ml-2 mb-1 inline-flex mt-auto text-sm"> / Mese</span>
              </div>
              <div *ngIf="product.productType === productType.Pacchetto_Indicatori" [@fade]="product"
                   class="flex flex-row w-full lg:w-1/3 justify-center lg:justify-start text-white">
                <span class="inline-flex title-font font-medium text-2xl">
                  {{ product.price | currency:'EUR' }}
                </span>
                <span class="ml-2 mb-1 inline-flex mt-auto text-sm"> / 3 Mesi</span>
              </div>


              <!--UTENTE LOGGATO-->
              <div
                *ngIf="(currentUser | json) != '{}'
                && product.productAvailability !== productAvailability.ComingSoon
                && product.productAvailability !== productAvailability.Terminato"
                [@fade]="currentUser"
                class="flex ml-auto max-w-sm my-2 sm:my-5 lg:my-0">
                <!-- ACQUISTO STANDARD -->
                <button
                  (click)="addToBasket()"
                  *ngIf="currentUser?.jwtToken
                    && product.productType !== productType.Membership
                    && product.productType !== productType.Pacchetto_Indicatori
                    && !(product.productType === productType.Indicatore && product.typeCovers === typeCovers.Cover)
                    && product.typeCovers !== typeCovers.CoverFree && product.price != 0"
                  class="button-small">
                  <app-loading-animation-small
                    *ngIf="loadingAction"
                    class="mr-2 inline-flex my-auto">
                  </app-loading-animation-small>
                  <svg *ngIf="!loadingAction" class="inline-flex my-auto h-5 w-5 mr-2"
                       fill="none" stroke="currentColor" stroke-width="5" viewBox="0 0 24 24"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                      stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
                  </svg>
                  Carrello
                </button>
                <!--MEMBERSHIP-->
                <ng-container
                  *ngIf="currentUser?.jwtToken && product.productType === productType.Membership
                  || ((product.productType === productType.Pacchetto_Indicatori)
                    && isAbleToRenew)
                  && !loading">
                  <!--NON ABBONATO-->
                  <button (click)="toggleSubscriptionModal()" *ngIf="!isAlreadySubscribed"
                          class="button-medium">
                    <svg *ngIf="!loadingAction" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke-width="1.5" stroke="currentColor"
                         class="inline-flex my-auto h-4 w-4 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"/>
                    </svg>
                    Abbonati
                  </button>
                  <!--ABBONATO-->
                </ng-container>
              </div>
              <!-- ACQUISTO NON LOGGATO -->
              <div *ngIf="!currentUser?.jwtToken
                && product.productAvailability !== productAvailability.ComingSoon
                && product.productAvailability !== productAvailability.Terminato"
                   class="flex ml-auto items-center my-2 sm:my-5 lg:my-0">
                <!-- PAYPAL -->
                <div *ngIf="!currentUser?.jwtToken && product.payPalPrice != 0"
                     class="flex max-w-fit ml-auto mt-2 relative z-10">
                  <div #paypalDetailRef class="mr-2 my-auto text-white text-focus-in-fast-fast"></div>
                </div>
                <div *ngIf="!currentUser?.jwtToken"
                     class="lg:flex ml-auto justify-center translate-y-3 xs:translate-y-0 items-center mt-2 my-2 sm:my-5 lg:my-0 overflow-visible">
                  <button (click)="startNoRegCheckout()"
                          *ngIf="
                          product.productType != productType.Pacchetto_Indicatori &&
                          product.productType != productType.Membership &&
                          product.price != 0"
                          [@fade]="product"
                          class="button-small whitespace-nowrap">
                    <app-loading-animation-small
                      *ngIf="loadingAction"
                      class="mr-2 inline-flex my-auto">
                    </app-loading-animation-small>
                    <svg *ngIf="!loadingAction" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         stroke-width="1.5" stroke="currentColor"
                         class="inline-flex my-auto h-4 w-4 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"/>
                    </svg>
                    Acquista
                  </button>
                </div>
              </div>
            </div>
            <div
              *ngIf="product.productAvailability !== productAvailability.ComingSoon
              && product.productAvailability !== productAvailability.Terminato"
              [@fade]="product"
              class="flex flex-col m-auto lg:mx-0 mt-2">
              <p *ngIf="product.productType === productType.Corso && product.price != 0"
                 class="text-gray-400 text-right text-sm">
                PayPal, Carte.
              </p>
              <p
                *ngIf="(product.productType === productType.Membership || product.productType === productType.Pacchetto_Indicatori) && isAbleToRenew"
                class="text-gray-400 text-right text-sm">
                Carte.
              </p>
            </div>
          </div>
          <div class="flex flex-row w-full lg:w-2/3 justify-center lg:justify-start">
            <p *ngIf="isVolumasterUser" class="flex flex-col text-xs text-center lg:text-left text-gray-400">
              <span>Prezzo dedicato esclusivamente a Utenti Volumaster.</span>
              <span>Prezzo standard: {{ 357 | currency: 'EUR' }}</span>
            </p>
          </div>
        </div>
      </div>
      <!--LOADING CHILDS-->
      <div *ngIf="childLoading" [@fade]="childLoading" class="flex flex-col space-y-4 mx-auto translate-y-24">
        <app-loading-animation-small class="m-auto invert scale-150 text-white">
        </app-loading-animation-small>
        <p class="m-auto text-gray-400 text-lg">Carico...</p>
      </div>
      <!-- ASSISTENZA CHILDS -->
      <div *ngIf="product && assistenzaChildren.length > 0" [@fade]="product"
           class="flex relative flex-wrap mx-auto mt-4 md:mt-8">
        <div class="mx-auto">
          <div *ngFor="let product of assistenzaChildren"
               class="relative bg-customDark border border-gray-700 px-4 md:px-12 w-full mx-auto rounded-lg">
            <div class="py-6">
              <h3 class="text-gray-400 text-xs tracking-widest title-font mb-1"> {{ productType[product.productType] }}
              </h3>
              <h2 class="text-redCustom title-font text-base md:text-lg font-medium">{{ product.name }}</h2>
              <p class="text-gray-400 text-xs pt-2">
                Questo prodotto offre un servizio di assistenza.<br>
                Servizio in abbonamento attivabile a prodotto acquistato.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- INDICATORI CUSTOM MESSAGE -->
      <div *ngIf="product && (product.productType === productType.Pacchetto_Indicatori)" [@fade]="product"
           class="flex relative mx-auto mt-8">
        <div class="mx-auto">
          <div class="relative bg-customDark border-gray-700 border px-8 sm:px-12 w-full mx-auto rounded-lg">
            <div class="py-6 sm:max-w-sm">
              <h3 class="text-redCustom text-md font-semibold title-font mb-1 uppercase">Attenzione</h3>
              <h2 class="text-white title-font text-md sm:text-lg font-medium">{{ product.name }}</h2>
              <h3 class="text-gray-300 pt-2 text-xs sm:text-sm title-font mb-1">
                Effettua la richiesta tramite
                <a [routerLink]="['/dashboard/request-indicators']"
                   class="text-redCustom hover:text-redCustom-dark"
                   routerLinkActive="router-link-active">
                  Questa Pagina
                </a>
                per ricevere i tuoi indicatori via mail (utenti MultiCharts e MT4)
                o attivare gli indicatori (utenti MultiCharts .NET).
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- SCORRI -->
  <div *ngIf="productChildren.length > 0 && !childLoading" [@fade]="childLoading"
       class="hidden sm:flex flex-col md:-translate-y-12 lg:-translate-y-24">
    <div class="flex flex-col m-auto mt-4 text-focus-in-fast">
      <svg class="hidden lg:inline-flex my-auto animate-bounce h-8 w-8" fill="none" stroke="#D97706"
           viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 13l-5 5m0 0l-5-5m5 5V6" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </svg>
    </div>
  </div>
</div>
<!-- CHILDS OPZIONALI -->
<div *ngIf="!childLoading && (productChildrenOptional.length > 0 || assistenzaProductOptional.length > 0)"
     [@fade]="childLoading"
     class="flex flex-col h-full bg-customDark">
  <div class="flex flex-col py-8 sm:py-12">
    <h1
      class="mt-4 m-auto text-2xl md:text-3xl font-medium text-white underline decoration-redCustom underline-offset-2">
      {{ product && product.productType === productType.Indicatore && product.typeCovers !== typeCovers.Cover ? 'Indicatori Opzionali' : 'Contenuti opzionali' }}
    </h1>
    <p class="m-auto text-gray-400">
      Lista di contenuti acquistabili per <span class="font-bold">{{ product.name }}</span>
    </p>
    <h3 *ngIf="product && product.productType === productType.Indicatore && product.typeCovers !== typeCovers.Cover"
        class="px-4 max-w-2xl m-auto text-focus-in-fast text-white">
      In caso tu abbia la <span class="font-semibold">MultiCharts .NET</span> gli indicatori saranno disponibili al
      download nella sezione <span class="text-redCustom font-semibold">I Miei
      Prodotti</span> una volta completato l'acquisto.
    </h3>
  </div>

  <div *ngIf="assistenzaProductOptional.length > 0"
       class="text-lg text-gray-300 mx-auto px-36 w-full md:w-3/4 lg:w-2/3 divide-y divide-gray-700">
    <div *ngFor="let item of assistenzaProductOptional" class="flex justify-between items-center text-redCustom">
      <div class="flex flex-col">
        <h2 class="text-redCustom title-font text-base md:text-lg font-medium"> {{ item.name }} </h2>
        <p class="text-gray-400 text-xs">
          Questo prodotto offre un servizio di assistenza.<br>
          Servizio in abbonamento attivabile a prodotto acquistato.
        </p>
      </div>
      <button (click)="toggleSubscriptionChildModal(item.productId)" *ngIf="currentUser?.jwtToken"
              class="button-medium">
        <svg *ngIf="!loadingAction" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke-width="1.5" stroke="currentColor"
             class="inline-flex my-auto h-4 w-4 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"/>
        </svg>
        Abbonati
      </button>
    </div>
  </div>

  <!-- NGFOR CATEGORIE -->
  <div *ngFor="let section of productSectionsOptional; let j = index" class="flex flex-col">
    <h1 class="py-4 sm:pt-12 m-auto text-4xl font-medium text-white underline decoration-redCustom underline-offset-2">
      {{ section | replaceAll:'_':' ' | titleCaseCustom }}
    </h1>
    <div *ngIf="productChildrenOptional.length > 0"
         class="text-lg text-gray-300 mx-auto px-36 w-full md:w-3/4 lg:w-2/3 divide-y divide-gray-700 justify-between items-center">
      <div *ngFor="let item of productChildrenOptional | filterSection: section">
        <div class="flex flex-row items-center">
          <p> {{ item.name }}</p>
        </div>
        <button (click)="addChildToBasket(item.productId)"
                *ngIf="currentUser?.jwtToken
          && product.productType !== productType.Membership
          && product.productType !== productType.Pacchetto_Indicatori
          && !(product.productType === productType.Indicatore && product.typeCovers === typeCovers.Cover)
          && product.typeCovers !== typeCovers.CoverFree"
                class="button-medium">
          <svg *ngIf="!loadingAction" class="inline-flex my-auto h-4 w-4 mr-2"
               fill="none" stroke="currentColor" stroke-width="5" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              stroke-linecap="round" stroke-linejoin="round"
              stroke-width="2"/>
          </svg>
          Carrello
        </button>

      </div>
    </div>
  </div>
</div>

<!-- CHILDS -->
<div *ngIf="!childLoading && productChildren.length > 0" [@fade]="childLoading"
     class="flex flex-col h-full bg-customDark">
  <div class="flex flex-col py-8 sm:py-12">
    <h1
      class="mt-4 m-auto text-2xl md:text-3xl font-medium text-white underline decoration-redCustom underline-offset-2">
      {{ product && product.productType === productType.Indicatore && product.typeCovers !== typeCovers.Cover ? 'Indicatori Offerti' : 'Contenuti offerti' }}
    </h1>
    <p class="m-auto text-gray-400">
      Lista di contenuti offerti per <span class="font-bold">{{ product?.name || '' }}</span>
    </p>
    <h3 *ngIf="product && product.productType === productType.Indicatore && product.typeCovers !== typeCovers.Cover"
        class="px-4 max-w-2xl m-auto text-focus-in-fast text-white">
      In caso tu abbia la <span class="font-semibold">MultiCharts .NET</span> gli indicatori saranno disponibili al
      download nella sezione <span class="text-redCustom font-semibold">I Miei
        Prodotti</span> una volta completato l'acquisto.
    </h3>
  </div>
  <!-- NGFOR CATEGORIE -->
  <div *ngFor="let section of productSections; let j = index" class="flex flex-col">
    <h1
      class="py-4 sm:pt-12 m-auto text-4xl font-medium text-white underline decoration-redCustom underline-offset-2">
      {{ section | replaceAll:'_':' ' | titleCaseCustom }}
    </h1>
    <p *ngIf="section !== 'ALTRO'" class="m-auto text-gray-400 pb-4">
      Video {{ section | replaceAll:'_':' ' | titleCaseCustom }}
    </p>
    <p *ngIf="section === 'ALTRO'" class="m-auto text-gray-400 pb-4">
      Lezioni non categorizzate.
    </p>
    <table *ngIf="product" [id]="'section-'+section"
           class="mx-auto px-36 w-full md:w-3/4 lg:w-2/3 divide-y divide-gray-700">
      <thead class="bg-customDark text-left text-sm md:text-md">
      <tr class="space-x-2 md:space-x-5 xl:space-x-8">
        <th
          *ngIf="product.productType !== productType.Pacchetto_Indicatori && !(product.productType === productType.Indicatore && product.typeCovers === typeCovers.Cover)"
          class="pl-2 md:pl-3 py-3 text-left font-medium text-gray-300 uppercase tracking-wider"
          scope="col">ID
        </th>
        <th class="pl-2 py-3 text-left font-medium text-gray-300 uppercase tracking-wider" scope="col">Nome</th>
        <th *ngIf="product.productType === productType.Corso"
            class="py-3 text-center font-medium text-gray-300 uppercase tracking-wider"
            scope="col">Difficoltà
        </th>
        <th
          *ngIf="product.productType === productType.Pacchetto_Indicatori || (product.productType === productType.Indicatore && product.typeCovers === typeCovers.Cover)"
          class="pl-2 py-3 text-left font-medium text-gray-300 uppercase tracking-wider"
          scope="col">Descrizione
        </th>
      </tr>
      </thead>
      <tbody class="bg-customDark divide-y divide-gray-700 text-gray-300">
      <!-- NG FOR CON PAGINAZIONE -->
      <tr
        *ngFor="let item of productChildren | filterSection: section | paginate: { itemsPerPage: 10, currentPage: currentPage[j], id: section }; let i = index"
        [attr.data-index]="i"
        class="space-x-2 md:space-x-5 xl:space-x-8 text-focus-in-fast">
        <td
          *ngIf="item.productType !== productType.Indicatore"
          class="pl-2 md:pl-3 py-4 whitespace-nowrap font-medium">
          {{ currentPage[j] ? currentPage[j] == 1 ? i + 1 : currentPage[j] * 10 - 10 + i + 1 : i + 1 }}
        </td>
        <td class="pl-2 py-4 text-sm md:text-base font-medium">{{ item.name }}</td>
        <td *ngIf="product.productType === productType.Corso"
            class="text-center py-4 whitespace-nowrap font-medium">
          <div class="inline-flex flex-row m-auto mt-1 text-center space-x-1">
            <div class="inline-flex flex-row m-auto text-center space-x-1">
              <span *ngIf="item.productRanking > 1">
                {{ item.productRanking }}
              </span>
              <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"/>
              </svg>
            </div>
          </div>
        </td>
        <td
          *ngIf="item.productType === productType.Indicatore || (product.productType === productType.Indicatore && product.typeCovers === typeCovers.Cover)"
          class="ml-2 px-6 py-4 lg:px-0 lg:pr-2 font-medium text-sm lg:text-base">
          {{ item.description }}
        </td>
      </tr>
      </tbody>
    </table>
    <div class="w-full py-8 flex m-auto">
      <pagination-controls
        (pageChange)="currentPage[j] = $event"
        [autoHide]="true"
        [id]="section"
        [maxSize]="5"
        class="m-auto my-pagination font-urbanist font-semibold tracking-wide text-gray-500"
        nextLabel=""
        previousLabel="">
      </pagination-controls>
    </div>
  </div>
</div>

<app-subscription-modal
  (isAlreadySubscribed)="toggleAlreadySubscribed($event)">
</app-subscription-modal>

<app-intro-video
  (modalCallback)="videoShow($event)"
  *ngIf="product?.presentationVideo !== undefined"
  [introChildLink]="product?.presentationVideo"
  [introChildName]="product?.name"
  [showIntroVideo]="showIntroVideo">
</app-intro-video>
