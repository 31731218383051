<div class="relative font-urbanist text-gray-300 flex bg-green-blob-dark bg-cover bg-no-repeat align-middle h-screen">
  <div class="flex flex-col lg:flex-row m-auto w-full lg:w-2/3 text-center">
    <!-- LEFT/TOP COLUMN -->
    <div class="flex flex-col m-auto">
      <!-- CARD PRODOTTO -->
      <div *ngIf="product"
           class="hidden sm:block mx-auto relative mt-4 md:mt-8 bg-grayBgDark rounded-lg text-focus-in-fast hover:scale-105 shadow-dark transition duration-300 ease-in-out border-[1px] border-gray-700 hover:border-gray-600">
        <div class="block relative h-72 w-72 rounded overflow-hidden">
          <img alt="{{ product.name }} by Top Trading Academy" title="{{ product.name }} by Top Trading Academy"
               class="absolute object-cover object-center w-full h-full block rounded-2xl"
               [src]="serverUrl + product.imageUrl">
        </div>
        <div class="py-6">
          <h2
            class="text-gray-400 text-xs tracking-widest title-font mb-1">{{ productType[product.productType] | replaceAll: "_": " " }}
          </h2>
          <h3 class="text-white title-font text-lg font-medium">{{ product.name }}</h3>
          <p class="text-green-400">
            <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex h-4 w-4 m-auto" viewBox="0 0 20 20"
                 fill="currentColor">
              <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"/>
            </svg>
            Acquistato
          </p>
        </div>
      </div>
    </div>
    <!-- RIGHT/BOTTOM COLUMN -->
    <div class="flex flex-col m-auto">
      <!-- MESSAGGI -->
      <h1 [@fade] class="mt-4 lg:mt-0 mx-auto max-w-xl text-3xl font-bold text-white">
        {{ titleMessage }}
      </h1>
      <h2 *ngIf="registrationMessage === ''" class="mx-auto text-lg md:text-xl mt-8 mb-8 text-center text-white">
        Stiamo processando il tuo ordine, riceverai una conferma tra qualche instante.
      </h2>
      <h2 *ngIf="registrationMessage !== ''"
          class="mx-auto text-lg md:text-xl mt-4 md:mt-8 mb-4 md:mb-8 text-center text-white">
        {{ registrationMessage }}
      </h2>
      <!-- BOTTONE LOGIN -->
      <div [@fade] *ngIf="alreadyAssigned" class="flex flex-col mx-auto">
        <a [routerLink]="['/']" [state]="{ data: { loginShow: true }}">
          <button
            class="inline-flex rounded-full bg-redCustom shadow-2xl border-0 py-2 px-8 focus:outline-none hover:bg-redCustom-dark transition duration-300 ease-in-out transform hover:scale-105 text-lg items-center">
            <span class="text-black font-semibold">
              Accedi
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex my-auto h-5 w-5 ml-2" fill="none"
                 viewBox="0 0 24 24" stroke="#000">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"/>
            </svg>
          </button>
        </a>
      </div>
      <p *ngIf="alreadyAssigned" class="mt-2 text-sm md:text-base">Clicca sul bottone per effettuare l'accesso</p>
      <p *ngIf="alreadyAssigned" class="mt-2 text-xs"><span class="text-redCustom">Attenzione:</span> Devi avere
        confermato la tua email.</p>
      <hr *ngIf="alreadyAssigned" class="border-redCustom w-1/2 md:w-1/3 mx-auto mt-4">
      <div class="flex flex-col md:flex-row space-y-2 md:space-x-4 md:space-y-0 text-gray-300 mx-auto text-sm mt-4">
        <a (click)="resendEmailVerification()">
          <p [@fade] *ngIf="alreadyAssigned"
             class="hover:text-redCustom transition duration-200 ease-in-out cursor-pointer">
            Non ho ricevuto la mail di conferma
          </p>
        </a>
        <a (click)="resendCredentials()">
          <p [@fade] *ngIf="alreadyAssigned"
             class="hover:text-redCustom transition duration-200 ease-in-out cursor-pointer">
            Non ho ricevuto le credenziali
          </p>
        </a>
      </div>
      <!-- CARICAMENTI -->
      <p *ngIf="!finishedProcess"><span class="text-redCustom font-semibold">Attenzione</span>: Non lasciare questa
        pagina mentre il processo è in corso.</p>
      <div class="flex flex-row mx-auto mt-4">
        <img [ngClass]="{'animate-bounce': loading}" src="../../../assets/images/tt_logo.png" alt="TT Logo"
             width="35 md:50" height="auto">
        <p *ngIf="loading" class="text-lg">Carico...</p>
        <p *ngIf="!loading" class="text-sm md:text-lg m-auto">Processo Completato</p>
      </div>
    </div>
  </div>
</div>