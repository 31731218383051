import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fade } from '@animations/fade.animation';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SubscriptionService } from "@services/subscription.service";

@Component({
  selector: 'app-subscription-disclaimer',
  templateUrl: './subscription-disclaimer.component.html',
  styleUrls: ['./subscription-disclaimer.component.scss'],
  animations: [fade]
})
export class SubscriptionDisclaimerComponent {

  @Input() isIndicator: boolean = false
  @Input() disclaimerShow: boolean = false
  @Input() subscriptionPrice: number = 0
  @Input() secondSubscriptionPrice: number = 0
  @Input() subscriptionFreeTrial: number = 0
  @Input() subscriptionCycle: number = 0
  @Input() firstSubscriptionPriceId: string;
  @Input() secondSubscriptionPriceId: string;
  @Output() modalCallback: EventEmitter<boolean> = new EventEmitter();
  @Output() confirmationCallback: EventEmitter<boolean> = new EventEmitter();

  subscriptionConfirmation: boolean = false;
  whichPrice: string;
  form: FormGroup

  constructor(
    private subscriptionService: SubscriptionService,
    private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      whichPrice: new FormControl(this.whichPrice, [Validators.required])
    })
  }

  toggleModal = (event: any) => this.modalCallback.emit(this.disclaimerShow)

  toggleDisclaimerConfirmation(event: any) {
    if (this.isIndicator)
      this.subscriptionService.whichPrice.next(this.whichPrice)
    this.subscriptionConfirmation = true
    this.confirmationCallback.emit(this.subscriptionConfirmation)
  }
}
