import { Injectable, isDevMode } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.jwtToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.jwtToken}`,
          //refreshToken: isDevMode() ? currentUser.refreshToken : ''
        }
      });
    }

    return next
      .handle(request)
    /*.pipe(
      tap((ev: HttpEvent<any>) => {
        if (isDevMode()) {
          if (ev instanceof HttpResponse) {
            console.log('event of type response', ev);
          }
        }
      })
    )*/
  }
}
