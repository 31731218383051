import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Player } from "../models/player.model";


@Injectable({providedIn: 'root'})
export class PlayerService {
  private currentPlayerSubject: BehaviorSubject<Player>;
  public currentPlayer: Observable<Player>;

  constructor(private http: HttpClient) {
    this.currentPlayerSubject = new BehaviorSubject<Player>(JSON.parse(localStorage.getItem('tta-currentPlayer') || '{}'));
    this.currentPlayer = this.currentPlayerSubject.asObservable();
  }

  public get currentPlayerValue(): Player {
    return this.currentPlayerSubject.value;
  }

  getPlayer(accountId: string): Promise<Player> {
    return this.http.get<Player>(`${environment.apiUrl}/Player/GetPlayer/${accountId}`)
      .pipe(map((player: Player) => {
        if (player) {
          localStorage.setItem('tta-currentPlayer', JSON.stringify(player));
          this.currentPlayerSubject.next(player)
        }
        return player;
      }))
      .toPromise()
  }
}