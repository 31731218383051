import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of, Subject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { environment } from '@envs/environment';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IAuthenticateRequest, IAuthenticateResponse } from "@models/authenticate.model";
import { IRegisterRequest, RegisterMinimalRequest } from '@/helpers/requests/registration.request';
import { IForgotPasswordRequest } from '@requests/forgotPassword.request'
import { IResetPasswordRequest } from '@requests/resetPassword.request';
import { IVerifyEmailRequest } from '@requests/verifyEmail.request';
import { SendEmailVerification } from '@requests/sendEmailVerification.request';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscordLoginResponse } from "@/helpers/modals/discordLoginResponse.model";
import { DiscordPattern } from "@/helpers/patterns/validators.patterns";

@Injectable({providedIn: 'root'})
export class AuthService {
  // necessario completamento account
  public shouldConfirmAccount: BehaviorSubject<boolean>;
  // necessaria associazione discord
  public shouldAddDiscordAccount: BehaviorSubject<boolean>
  // messaggio informativo per download app discord
  public shouldShowDiscordAppDownload: BehaviorSubject<boolean>
  // messaggio informativo
  public shouldShowPendingDiscordRedirection: BehaviorSubject<boolean>
  public currentUserSubject: BehaviorSubject<IAuthenticateResponse>;
  public currentUser: Observable<IAuthenticateResponse>;
  public userNameSubject = new Subject();

  public isUserPlayingSubject: BehaviorSubject<boolean>

  private refreshTokenTimeout;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient) {
    this.shouldConfirmAccount = new BehaviorSubject<boolean>(false);
    this.shouldAddDiscordAccount = new BehaviorSubject<boolean>(false);
    this.shouldShowDiscordAppDownload = new BehaviorSubject<boolean>(false);
    this.shouldShowPendingDiscordRedirection = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<IAuthenticateResponse>(JSON.parse(localStorage.getItem('tta-currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
    this.isUserPlayingSubject = new BehaviorSubject<boolean>(false)
  }

  public get currentUserValue(): IAuthenticateResponse {
    return this.currentUserSubject.value;
  }

  setCurrentUser(user: IAuthenticateResponse) {
    localStorage.setItem('tta-currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    this.userNameSubject.next(user.email);
    //this.showDiscordRedirectModal(user)
  }

  /**
   * controllo se l'utente deve associare discord ad account già esistente
   * @param currentUser
   */
  showDiscordRedirectModal(currentUser: IAuthenticateResponse) {
    if (currentUser && currentUser.jwtToken && !DiscordPattern.test(currentUser.username)) {
      this.shouldShowPendingDiscordRedirection.next(true)
    }
  }

  login(request: IAuthenticateRequest): Observable<IAuthenticateResponse> {
    return this.http.post <IAuthenticateResponse>(`${environment.apiUrl}/Accounts/Authenticate`, request)
      .pipe(map((user: IAuthenticateResponse) => {
        if (user && user.jwtToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setCurrentUser(user)
          this.startRefreshTokenTimer(user);
        }
        return user;
      }));
  }

  loginWithDiscord(accountId?: string): Promise<DiscordLoginResponse> {
    let url = `${environment.apiUrl}/Accounts/LoginWithDiscord`
    if (accountId != null)
      url += `?state=${accountId}`
    return this.http.get<DiscordLoginResponse>(url)
      .toPromise()
  }

  refreshToken(): Promise<Observable<IAuthenticateResponse>> {
    return this.http.post <IAuthenticateResponse>(`${environment.apiUrl}/Accounts/RefreshToken`,
      {},
      {withCredentials: true})
      .pipe(map((user: any) => {
        if (user && user.jwtToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.setCurrentUser(user)
          this.startRefreshTokenTimer(user);
        }
        return user;
      })).toPromise()
  }

  register(request: IRegisterRequest) {
    return this.http.post <any>(`${environment.apiUrl}/Accounts/Register`, request)
      .toPromise()
  }

  registerMinimal(request: RegisterMinimalRequest) {
    return this.http.post <any>(`${environment.apiUrl}/Accounts/RegisterMinimal`, request)
      .toPromise()
  }

  updateDetails(request: IAuthenticateResponse): Observable<IAuthenticateResponse> {
    return this.http.post <any>(`${environment.apiUrl}/Accounts/Update`, request)
      .pipe(map((user: IAuthenticateResponse) => {
        if (user && user.jwtToken) {
          localStorage.setItem('tta-currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          this.userNameSubject.next(user.email);
        }
        this.updateCurrentUser(request)
        return user;
      }))
  }

  updateAccount(request: any) {
    return this.http.post<any>(`${environment.apiUrl}/Accounts/Update`, request)
      .toPromise()
  }

  forgotPassword(request: IForgotPasswordRequest) {
    return this.http.post <any>(`${environment.apiUrl}/Accounts/ForgotPassword`, request)
      .toPromise()
  }

  resetPassword(request: IResetPasswordRequest) {
    return this.http.post <any>(`${environment.apiUrl}/Accounts/ResetPassword`, request)
      .toPromise()
  }

  verifyEmail(request: IVerifyEmailRequest) {
    return this.http.post <any>(`${environment.apiUrl}/Accounts/VerifyEmail`, request)
      .toPromise()
  }

  sendEmailVerification(request: SendEmailVerification) {
    return this.http.post <any>(`${environment.apiUrl}/Accounts/ResendEmailVerification`, request)
      .toPromise()
  }

  resendAccountCredentials(request: SendEmailVerification) {
    return this.http.post <any>(`${environment.apiUrl}/Accounts/ResendAccountCredentials`, request)
      .toPromise()
  }

  generateInviteId(accountId: string) {
    return this.http.post<any>(`${environment.apiUrl}/Accounts/GenerateInviteId`, {accountId: accountId})
      .toPromise()
  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('tta-currentUser');
    sessionStorage.removeItem('tta-refreshToken')
    localStorage.removeItem('tta-playerToken')
    localStorage.removeItem('tta-currentPlayer')
    localStorage.removeItem('tta-isPlayerPlaying')
    localStorage.removeItem('tta-currentorderid')
    localStorage.removeItem('basketProducts')
    localStorage.removeItem('tta-discord-app-download')
    this.currentUserSubject.next(null);
    this.shouldConfirmAccount.next(false);
    this.stopRefreshTokenTimer();
    this.userNameSubject.next(null);
    this.router.navigate(['/'])
  }

  updateCurrentUser(user: IAuthenticateResponse) {
    localStorage.removeItem('tta-currentUser');
    localStorage.setItem('tta-currentUser', JSON.stringify(user));
  }

  private startRefreshTokenTimer(user: IAuthenticateResponse) {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(user.jwtToken.split('.')[1]));
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);

    this.refreshTokenTimeout = setTimeout(async () => {
      await this.refreshToken()
    }, timeout)
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
