import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export const EmailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
export const PhonePattern = /^((\+|0{0,2})(\d){1,3})?[-.●\s]?\(?(\d{2,3})\)?[-.●\s]?(\d{3})[-.●\s]?(\d{4})$/
export const DiscordPattern = /^[^#]{2,32}#\d{4}$/
export const DigitPattern = /^(\d+)$/

export const FiscalCodeValidators: ValidatorFn[] = [
  Validators.required,
  Validators.minLength(16),
  Validators.maxLength(16)
]
export const VatNumberValidators: ValidatorFn[] = [
  Validators.required,
  Validators.minLength(11),
  Validators.maxLength(11)
]
export const CodiceUnivocoValidators: ValidatorFn[] = [
  Validators.required,
  Validators.minLength(7),
  Validators.maxLength(7)
]
export const PostalCodeValidators: ValidatorFn[] = [
  Validators.required,
  Validators.minLength(5),
  Validators.maxLength(5),
  Validators.pattern(DigitPattern)
]

export const MultiChartsNETValidators: ValidatorFn[] = [
  Validators.required,
  Validators.minLength(5),
  Validators.pattern(DigitPattern)
]

export const MultiChartsValidators: ValidatorFn[] = [
  Validators.required,
  Validators.minLength(5),
  Validators.pattern(/^MC_\d+$/)
]

export const MetTraderValidators: ValidatorFn[] = [
  Validators.required,
  Validators.minLength(5),
  Validators.pattern(DigitPattern)
]

export const passwordMatching: ValidatorFn = (c: AbstractControl): ValidationErrors | null => {
  return c.get('password')?.value === c.get('confirmPassword')?.value ? null : {notMatched: true}
}
