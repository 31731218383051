import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Subject } from "rxjs";

declare var Tawk_API: any;

@Injectable()
export class TawkToService {

  private loaded: boolean;
  private loadSubject: Subject < boolean > = new Subject < boolean > ();
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document) {
        // Tawk_API = environment.
    this.renderer = rendererFactory.createRenderer(null, null)
    this.load()
  }

  private load() {
    if (this.loaded) return;

    const script = this.renderer.createElement('script');

    script.type = 'text/javascript';
    script.text = `WIDGET_SCRIPT`;

    this.renderer.appendChild(this._document.body, script);
    Tawk_API.onLoad = this.loadedEvent.bind(this)
  }

  private loadedEvent() {
    this.loaded = true;
    this.loadSubject.next(this.loaded);
  }

  public updateTawkUser = (user: any) => this.loadedWrapper(() => { this.updateAtrributes })

  private loadedWrapper(func: any) {
    if (!this.loaded) {
      var sub = this.loadSubject.asObservable().subscribe({
        next: () => {
          func();
          sub.unsubscribe();
        },
        error: () => {}
      });
    } else {
      func();
    }
  }

  public ExpandChatWindow(show: boolean = false) {
    this.loadedWrapper(() => show ? Tawk_API.maximize() : Tawk_API.minimize());
  }

  public SetChatVisibility(show: boolean = false) {
    this.loadedWrapper(() => show ? Tawk_API.showWidget() : Tawk_API.hideWidget());
  }

  private updateAtrributes(user: any) {
    Tawk_API.setAttributes({
      'name': `${user.firstname} ${user.surname}`,
      'email': user.email,
      'id': user.id,
    }, function(error) {});
  }
}