import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StripeService } from "ngx-stripe";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { environment } from "@envs/environment";
import { OrderDetailService } from "./orderDetails.service";

@Injectable({providedIn: 'root'})
export class StripeTTAService {

  constructor(
    private toastr: ToastrService,
    private http: HttpClient,
    private stripeService: StripeService,
    private orderDetailService: OrderDetailService) {
  }

  getPaymentIntentStatus(sessionId: string): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/Stripe/GetPaymentIntentStatus`, {stripeIdentifier: sessionId})
      .toPromise()
  }

  checkoutNoReg(productPrice: string, productName: string) {
    this.http.post(`${environment.apiUrl}/Stripe/CreateSessionNoReg`, {
      productPrice: productPrice,
      productName: productName
    })
      .pipe(
        switchMap((session: any) => {
          return this.stripeService.redirectToCheckout({sessionId: session.session})
        }),
        catchError(() => of(this.toastr.warning("C'è stato un errore nell'inizializzare il pagamento", "Attenzione")))
      ).subscribe(() => {
    });
  }

  checkoutSubscription(productName: string, priceId: string, productId: number, freeTrialDuration: number, hasDiscord: boolean, isIndicator: boolean, orderId: number): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/Stripe/CheckoutSubscription`,
      {
        productName: productName,
        priceId: priceId,
        productId: productId,
        freeTrialDuration: freeTrialDuration,
        hasDiscord: hasDiscord,
        isIndicator: isIndicator,
        orderId: orderId
      }).toPromise()
/*       .pipe(
        switchMap((session: any) => {
          
          return this.stripeService.redirectToCheckout({sessionId: session.session})
        }),
        catchError(() => of(this.toastr.warning("C'è stato un errore nell'inizializzare il pagamento", "Attenzione")))
      )
      .subscribe(() => {
      }) */
  }

  checkoutTopUp(price: string, coinAmount: number) {
    this.http.post(`${environment.apiUrl}/Stripe/CreateTopUpSession`, {priceId: price, coinAmount: coinAmount})
      .pipe(
        switchMap((session: any) => {
          return this.stripeService.redirectToCheckout({sessionId: session.session})
        }),
        catchError(() => of(this.toastr.warning("C'è stato un errore nell'inizializzare il pagamento", "Attenzione")))
      )
      .subscribe(() => {
      })
  }

  getCustomerPortal(): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/Stripe/CreateCustomerPortal`)
      .toPromise()
  }
}