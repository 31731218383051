import { Pipe, PipeTransform } from '@angular/core';
import { ProductType } from '../enums/productType.enum';
import { PlatformType } from '../enums/platformType.indicator';
import { TypeCovers } from '../enums/typeCovers.enum';

@Pipe({
  name: 'filterProductPlatformArray',
  pure: false
})

export class FilterProductPlatformArrayPipe implements PipeTransform {
  transform(items: any[], productType: ProductType, platformType: PlatformType): any {
    return items.filter(item => item.productType === productType && item.platformType === platformType)
  }
}