import { Component } from '@angular/core';
import { AuthService } from "@/helpers/services/auth.service";
import { DiscordLoginResponse } from "@/helpers/modals/discordLoginResponse.model";
import { fade } from "@/helpers/animations/fade.animation";

@Component({
  selector: 'app-discord-redirect-modal',
  templateUrl: './discord-redirect-modal.component.html',
  styleUrls: ['./discord-redirect-modal.component.scss'],
  animations: [fade]
})
export class DiscordRedirectModalComponent {

  shouldShow: boolean = false;
  loading: boolean = false;

  constructor(public auth: AuthService) {
  }

  async continueDiscordAuth() {
    this.loading = true
    this.auth.loginWithDiscord(this.auth.currentUserValue.accountId)
      .then((res: DiscordLoginResponse) => {
        window.location.href = res.url
      })
      .catch(() => {
        this.loading = false
      })
  }

  toggleModal = (event?: any) => this.auth.shouldShowPendingDiscordRedirection.next(false)
}

