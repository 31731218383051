<div *ngIf="registerShow" [@fade] aria-labelledby="modal-title"
     aria-modal="true" class="fixed z-40 inset-0 overflow-y-auto overscroll-contain" role="dialog">
  <div
    class="transition-std flex items-end justify-center min-h-full h-full w-full sm:px-4 pb-20 text-center sm:block">
    <div (click)="toggleModal($event)" aria-hidden="true"
         class="fixed inset-0 bg-customDark opacity-70 transition-std min-h-full h-full"></div>
    <span aria-hidden="true" class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

    <div
      class="inline-flex m-auto rounded-lg text-left shadow-xl transform transition-std sm:my-8 sm:align-middle w-full">
      <svg (click)="toggleModal($event)"
           class="z-50 absolute top-5 right-5 h-6 w-6 cursor-pointer text-white hover:text-redCustom transition-std"
           fill="none"
           stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      </svg>
      <div
        class="flex bg-customDark border rounded-lg overflow-visible border-gray-700 px-4 pt-5 pb-4 sm:p-10 sm:pb-4 w-full">
        <div class="w-full mx-auto">
          <div class="w-full m-auto">
            <div class="mt-5 md:mt-0 md:col-span-6">
              <form (ngSubmit)="onSubmit()" [formGroup]="form">
                <div class="overflow-hidden sm:rounded-md">
                  <div class="flex flex-col w-full mx-auto px-4">
                    <h2
                      class="pt-8 sm:pt-0 mb-6 underline underline-offset-4 decoration-redCustom text-center text-3xl text-bold text-white">
                      Registrazione
                    </h2>
                    <h3 class="mb-2 md:mb-0 text-center text-sm text-gray-400">Tutti i campi contrassegnati con <span
                      class="text-red-600">*</span> sono obbligatori</h3>
                  </div>
                  <div
                    class="px-8 md:px-0 w-full m-auto overflow-hidden sm:rounded-md flex flex-col sm:flex-row space-y-4 sm:space-y-0">
                    <!-- ANAGRAFICA -->
                    <div class="w-full sm:p-6">
                      <h1
                        class="text-2xl text-gray-300 text-center sm:text-left underline underline-offset-4 decoration-redCustom mb-4">
                        Anagrafica</h1>
                      <div class="grid grid-cols-6 gap-x-6 gap-y-3">
                        <!--TITOLO-->
                        <div class="col-span-3 sm:col-span-3">
                          <label class="label-std-left-validated">Titolo</label>
                          <select [(ngModel)]="userTitle" class="form-control input-std-dark" formControlName="title"
                                  name="title">
                            <option *ngFor="let item of title | enumToArray" [ngValue]='item.index'>{{item.name}}
                            </option>
                          </select>
                          <p *ngIf="m.title.invalid && m.title.dirty" class="invalid-label">
                            Titolo necessario.
                          </p>
                        </div>
                        <!--TIPO UTENTE-->
                        <div class="col-span-3 sm:col-span-3">
                          <label class="label-std-left-validated">Tipologia Utente</label>
                          <select [(ngModel)]="request.kindOfClient" class="input-std-dark"
                                  formControlName="kindOfClient"
                                  name="title">
                            <option *ngFor="let item of kindOfClient | enumToArray" [ngValue]='item.index'>{{item.name}}
                            </option>
                          </select>
                          <p *ngIf="m.kindOfClient.invalid && m.kindOfClient.dirty" class="invalid-label">
                            Tipologia necessaria.
                          </p>
                        </div>
                        <!--NOME-->
                        <div class="col-span-6 sm:col-span-3">
                          <label class="label-std-left-validated" for="firstName">Nome</label>
                          <input [(ngModel)]="request.firstName" [value]="form.get('firstName').value | titlecase"
                                 class="input-std-dark" formControlName="firstName" id="firstName" name="firstName"
                                 placeholder="Nome"
                                 type="text">
                          <p *ngIf="m.firstName.invalid && m.firstName.dirty" class="invalid-label">
                            Nome necessario.
                          </p>
                        </div>
                        <!--COGNOME-->
                        <div class="col-span-6 sm:col-span-3">
                          <label class="label-std-left-validated">Cognome</label>
                          <input [(ngModel)]="request.lastName" [value]="form.get('lastName').value | titlecase"
                                 class="input-std-dark" formControlName="lastName" name="lastName" placeholder="Cognome"
                                 type="text">
                          <p *ngIf="m.lastName.invalid && m.lastName.dirty" class="invalid-label">
                            Cognome necessario.
                          </p>
                        </div>
                        <!--USERNAME-->
                        <div [ngClass]="{'col-span-3': request.kindOfClient === kindOfClient.Impresa}"
                             class="col-span-6">
                          <label class="label-std-left-validated">Username</label>
                          <input [(ngModel)]="request.userName" class="input-std-dark"
                                 formControlName="userName"
                                 name="userName" placeholder="mario123"
                                 type="text">
                          <p *ngIf="m.userName.invalid && m.userName.dirty" class="invalid-label">
                            Username necessario.
                          </p>
                        </div>
                        <!--RAGIONE SOCIALE (SOLO IMPRESA)-->
                        <div *ngIf="request.kindOfClient === kindOfClient.Impresa"
                             [ngClass]="{'col-span-3': request.kindOfClient === kindOfClient.Impresa}"
                             class="col-span-6">
                          <label class="label-std-left-validated">Ragione Sociale</label>
                          <input [(ngModel)]="request.businessName" class="input-std-dark"
                                 formControlName="businessName"
                                 name="businessName" placeholder="Contoso Srl"
                                 type="text">
                          <p *ngIf="m.businessName.invalid && m.businessName.dirty" class="invalid-label">
                            Ragione Sociale necessaria.
                          </p>
                        </div>
                        <!-- PASSWORD -->
                        <div class="col-span-6 sm:col-span-3">
                          <label class="label-std-left-validated">Password</label>
                          <input [(ngModel)]="request.password"
                                 class="input-std-dark" formControlName="password" name="password"
                                 type="password">
                          <p *ngIf="m.password.invalid && m.password.dirty" class="invalid-label">
                            Minimo 8 caratteri.
                          </p>
                        </div>
                        <!--CONFERMA PASSWORD-->
                        <div class="col-span-6 sm:col-span-3">
                          <label class="label-std-left-validated">Conferma Password</label>
                          <input [(ngModel)]="request.confirmPassword"
                                 class="input-std-dark" formControlName="confirmPassword" name="confirmPassword"
                                 type="password">
                          <p *ngIf="m.confirmPassword.dirty && form.errors?.notMatched" [@fade]
                             class="invalid-label">
                            Le password non coincidono.
                          </p>
                          <p *ngIf="m.confirmPassword.dirty && m.confirmPassword.errors?.required" [@fade]
                             class="invalid-label">
                            Ripeti la password.
                          </p>
                        </div>
                        <!-- INDIRIZZO -->
                        <div class="col-span-6 sm:col-span- lg:col-span-3">
                          <label class="label-std-left-validated">Indirizzo</label>
                          <input [(ngModel)]="request.street" [value]="form.get('street').value | titlecase"
                                 class="input-std-dark"
                                 formControlName="street" name="street" placeholder="Via Mario Rossi, 10"
                                 type="text">
                          <p *ngIf="m.street.invalid && m.street.dirty" [@fade] class="invalid-label">
                            Indirizzo necessario.
                          </p>
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label class="label-std-left-validated">Città</label>
                          <input [(ngModel)]="request.city" [value]="form.get('city').value | titlecase"
                                 class="input-std-dark"
                                 formControlName="city" name="city" placeholder="Milano"
                                 type="text">
                          <p *ngIf="m.city.invalid && m.city.dirty" [@fade] class="invalid-label">
                            Città necessaria.
                          </p>
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label class="label-std-left-validated">
                            Provincia
                          </label>
                          <input [(ngModel)]="request.state" [value]="form.get('state').value | titlecase"
                                 class="input-std-dark"
                                 formControlName="state" name="state" placeholder="Milano"
                                 type="text">
                          <p *ngIf="m.state.invalid && m.state.dirty" class="invalid-label">
                            Provincia necessaria.
                          </p>
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label class="label-std-left-validated">CAP</label>
                          <input [(ngModel)]="request.postalCode" autocomplete="postal-code" class="input-std-dark"
                                 formControlName="postalCode"
                                 name="postalCode" placeholder="20212"
                                 type="text">
                          <div *ngIf="m.postalCode.invalid" [@fade]>
                            <p *ngIf="m.postalCode.errors?.minlength" class="invalid-label">
                              Devono esserci 5 numeri.
                            </p>
                            <p *ngIf="m.postalCode.errors?.maxlength" class="invalid-label">
                              Massimo 5 numeri.
                            </p>
                            <p *ngIf="m.postalCode.errors?.pattern" class="invalid-label">
                              Il CAP deve essere un numero.
                            </p>
                          </div>
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-2">
                          <label class="label-std-left-validated">
                            Paese
                          </label>
                          <input [(ngModel)]="request.country" [value]="form.get('country').value | titlecase"
                                 class="input-std-dark" formControlName="country" name="country"
                                 placeholder="Italia"
                                 type="text">
                          <p *ngIf="m.country.invalid && m.country.dirty" class="invalid-label">
                            Paese necessario.
                          </p>
                        </div>
                        <!--CODICE FISCALE-->
                        <div [ngClass]="{'lg:col-span-6': request.kindOfClient === kindOfClient.Privato }"
                             class="col-span-6 sm:col-span-6 lg:col-span-3">
                          <label class="label-std-left-validated">Codice Fiscale</label>
                          <input (ngModelChange)="request.fiscalCode = $event.toUpperCase()"
                                 [ngModel]="request.fiscalCode"
                                 class="input-std-dark" formControlName="fiscalCode" name="fiscalCode"
                                 placeholder="RSSMRA07P01F205O"
                                 type="text">
                          <div *ngIf="m.fiscalCode.invalid">
                            <p *ngIf="m.fiscalCode.errors?.minlength" class="invalid-label">
                              Devono esserci 16 caratteri.
                            </p>
                            <p *ngIf="m.fiscalCode.errors?.maxlength" class="invalid-label">
                              Massimo 16 numeri.
                            </p>
                          </div>
                        </div>

                        <!-- SOLO IMPRESE -->
                        <!--PARTITA IVA-->
                        <div *ngIf="request.kindOfClient === kindOfClient.Impresa" [@fade]
                             class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label class="label-std-left-validated">P.IVA</label>
                          <input [(ngModel)]="request.vatNumber" class="input-std-dark"
                                 formControlName="vatNumber"
                                 name="vatNumber"
                                 placeholder="86334519757"
                                 type="text">
                          <p *ngIf="m.vatNumber.dirty && m.vatNumber.invalid" class="invalid-label">
                            Partita IVA necessaria.
                          </p>
                          <p *ngIf="m.vatNumber.errors?.minlength || m.vatNumber.errors?.maxlength"
                             class="invalid-label">
                            Il Partita IVA ha una lunghezza di 11 caratteri.
                          </p>
                        </div>
                        <!--PEC-->
                        <div *ngIf="request.kindOfClient === kindOfClient.Impresa" [@fade]
                             class="col-span-6 sm:col-span-6 lg:col-span-3">
                          <label class="label-std-left-validated">PEC</label>
                          <input [(ngModel)]="request.pec" [value]="form.get('pec').value | lowercase"
                                 class="input-std-dark"
                                 formControlName="pec" name="fiscalCode"
                                 placeholder="mario@mario.pec.com"
                                 type="text">
                          <p *ngIf="m.pec.invalid && m.pec.dirty" class="invalid-label">
                            PEC necessaria.
                          </p>
                        </div>
                        <!--CODICE UNIVOCO-->
                        <div *ngIf="request.kindOfClient === kindOfClient.Impresa" [@fade]
                             class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label class="label-std-left-validated">Codice
                            Univoco</label>
                          <input [(ngModel)]="request.codiceUnivoco"
                                 [value]="form.get('codiceUnivoco').value | uppercase"
                                 class="input-std-dark"
                                 formControlName="codiceUnivoco"
                                 name="vatNumber"
                                 placeholder="VF658YP"
                                 type="text">
                          <p *ngIf="m.codiceUnivoco.invalid && m.codiceUnivoco.dirty" class="invalid-label">
                            Codice Univoco necessario.
                          </p>
                          <p *ngIf="m.codiceUnivoco.errors?.minlength || m.codiceUnivoco.errors?.maxlength"
                             class="invalid-label">
                            Il Codice Univoco ha una lunghezza di 7 caratteri.
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- CONTATTI -->
                    <div class="w-full pb-4 sm:pb-0 sm:p-6">
                      <h1
                        class="text-2xl text-gray-300 text-center sm:text-left underline underline-offset-4 decoration-redCustom mb-4">
                        Contatti</h1>
                      <div class="grid grid-cols-6 gap-x-6 gap-y-3">
                        <div class="col-span-6 sm:col-span-3">
                          <label class="label-std-left-validated">Email</label>
                          <input [(ngModel)]="request.email" [value]="form.get('email').value | lowercase"
                                 class="input-std-dark" formControlName="email"
                                 name="email"
                                 placeholder="mario@mario.it"
                                 type="text">
                          <div *ngIf="m.email.invalid && m.email.touched" class="invalid-label">
                            <p *ngIf="m.email.errors?.email">
                              Email non valida
                            </p>
                            <p *ngIf="m.email.errors?.required">
                              Email necessaria
                            </p>
                          </div>
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label class="label-std-left-validated">Telefono</label>
                          <input [(ngModel)]="request.phone"
                                 class="input-std-dark" formControlName="phone"
                                 name="phone"
                                 placeholder="3291234567"
                                 type="text">
                          <div *ngIf="m.phone.invalid && m.phone.touched" class="invalid-label">
                            <p *ngIf="m.phone.invalid && m.phone.dirty">
                              Telefono Non valido.
                            </p>
                            <p *ngIf="m.phone.errors?.required">
                              Telefono necessario.
                            </p>
                          </div>
                        </div>
                        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label class="label-std-left-validated">Social più
                            usato</label>
                          <select [(ngModel)]="request.mostUsedSocial"
                                  class="input-std-dark" formControlName="mostUsedSocial"
                                  name="mostUsedSocial">
                            <option *ngFor="let item of socialNetworks | enumToArray" [ngValue]='item.index'>
                              {{item.name}}</option>
                          </select>
                          <p *ngIf="m.mostUsedSocial.touched && m.mostUsedSocial.errors?.required">
                            Scegli uno degli elementi proposti.
                          </p>
                        </div>

                        <div class="col-span-6 sm:col-span-3 lg:col-span-3">
                          <label class="label-std-left-validated">App più usata</label>
                          <select [(ngModel)]="request.mostUsedSocialOutlets" class="input-std-dark"
                                  formControlName="mostUsedSocialOutlets"
                                  name="mostUsedSocialOutlets">
                            <option *ngFor="let item of socialOutlets | enumToArray" [ngValue]='item.index'>
                              {{item.name}}</option>
                          </select>
                          <p *ngIf="m.mostUsedSocialOutlets.touched && m.mostUsedSocialOutlets.errors?.required">
                            Scegli uno degli elementi proposti.
                          </p>
                        </div>
                        <!-- ORE DI CONTATTO -->
                        <div class="col-span-3 sm:col-span-3 lg:col-span-3">
                          <label class="label-std-left-validated">Ora di contatto, da</label>
                          <select (change)="hoursRangeBuilder()" [(ngModel)]="fromHour"
                                  [ngModelOptions]="{standalone: true}"
                                  class="input-std-dark-nv">
                            <option *ngFor="let item of contactHours">{{ item }}</option>
                          </select>
                        </div>
                        <div class="col-span-3 sm:col-span-3 lg:col-span-3">
                          <label class="label-std-left-validated">Ora di contatto,
                            a</label>
                          <select (change)="hoursRangeBuilder();hoursValidator()"
                                  [(ngModel)]="toHour" [ngModelOptions]="{standalone: true}"
                                  class="input-std-dark-nv">
                            <option *ngFor="let item of contactHours">{{ item }}</option>
                          </select>
                        </div>
                        <!-- INVITO -->
                        <div class="col-span-6 sm:col-span-3">
                          <label class="label-std-left">Invito</label>
                          <input [(ngModel)]="request.invitedById"
                                 [ngModelOptions]="{standalone: true}"
                                 class="input-std-dark"
                                 name="invite" placeholder="Invito"
                                 type="text">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col m-auto w-full">
                  <div class="flex-col w-full mx-auto mt-8">
                    <button
                      class="cursor-pointer flex mx-auto rounded-full shadow-dark text-black font-semibold bg-redCustom border-0 py-2 px-8 focus:outline-none hover:bg-redCustom-dark hover:scale-105 transition-std transform text-lg items-center"
                      type="submit">
                      <span *ngIf="!loading">Registrami</span>
                      <span *ngIf="loading">Carico</span>
                      <div *ngIf="!loading && !succeeded">
                        <svg class="h-5 w-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                          <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                                stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="2"/>
                        </svg>
                      </div>
                      <app-loading-animation-small *ngIf="loading" class="ml-2"></app-loading-animation-small>
                      <div *ngIf="!loading && succeeded">
                        <svg class="h-5 w-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 13l4 4L19 7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </svg>
                      </div>
                    </button>
                    <!--CONSENSI-->
                    <div class="flex flex-col w-full mx-auto mt-4">
                      <div class="inline-flex w-full mx-auto space-x-8 items-center mt-3">
                        <div
                          class="mx-auto w-4/5 md:w-1/4 col-span-6 text-gray-400 text-xs text-left mt-2 space-y-4">
                          <!--TERMINI & CONDIZIONI-->
                          <div class="flex w-full mx-auto flex-row">
                            <div class="flex w-4/5 flex-col">
                              <a href="/terms-conditions" rel="noreferrer" target="_blank">
                                <span
                                  class="ml-2 before:content-['*'] after:ml-0.5 before:text-red-500 text-gray-300 text-sm hover:text-redCustom transition-std">
                                  Termini & Condizioni
                                </span>
                              </a>
                              <p *ngIf="m.acceptTerms.invalid" class="text-red-500">
                                Devi accettare Termini & Condizioni
                              </p>
                            </div>
                            <div class="flex w-1/5 flex-col items-center">
                              <input [(ngModel)]="request.acceptTerms" [value]="true"
                                     class="inline-flex rounded-full my-auto text-redCustom"
                                     formControlName="acceptTerms"
                                     type="checkbox">
                            </div>
                          </div>
                          <!--DISCLAIMER-->
                          <div class="flex w-full mx-auto flex-row">
                            <div class="flex w-4/5 flex-col">
                              <a href="/disclaimer" rel="noreferrer" target="_blank">
                                <span
                                  class="ml-2 before:content-['*'] after:ml-0.5 before:text-red-500 text-gray-300 text-sm hover:text-redCustom transition-std">
                                  Disclaimer
                                </span>
                              </a>
                              <p *ngIf="m.disclaimer.invalid" class="text-red-500">
                                Devi accettare il Disclaimer
                              </p>
                            </div>
                            <div class="flex w-1/5 flex-col items-center">
                              <input [(ngModel)]="request.disclaimer" [value]="true"
                                     class="inline-flex rounded-full my-auto text-redCustom"
                                     formControlName="disclaimer"
                                     type="checkbox">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--PRIVACY-->
                    <div class="flex flex-col w-full mx-auto mt-4">
                      <div class="inline-flex mx-auto space-x-8 items-center mt-3">
                        <div class="col-span-6 sm:col-span-6 text-gray-400 text-xs text-left mt-2 space-y-4">
                          <!--TRATTAMENTO-->
                          <div class="flex flex-row space-x-4">
                            <div class="flex flex-col">
                              <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                                Acconsento al trattamento dei dati secondo quanto definito dalla vostra
                                Informativa sulla Privacy esposta in questo sito.
                              </p>
                              <p *ngIf="m.privacyTrattamento.invalid" [@fade]="m.privacyTrattamento.invalid"
                                 class="text-red-500">
                                Consenso obbligatorio
                              </p>
                            </div>
                            <div class="flex flex-col justify-center">
                              <p>Si</p>
                              <input [(ngModel)]="request.privacyTrattamento" [value]="true"
                                     class="radio-redCustom"
                                     formControlName="privacyTrattamento"
                                     type="radio">
                            </div>
                            <div class="flex flex-col justify-center">
                              <p>No</p>
                              <input [(ngModel)]="request.privacyTrattamento" [value]="false"
                                     class="radio-redCustom"
                                     formControlName="privacyTrattamento"
                                     type="radio">
                            </div>
                          </div>
                          <!--CONTATTO-->
                          <div class="flex flex-row space-x-4">
                            <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                              Acconsento ad essere contattato da un vostro consulente e ricevere
                              tramite sms, e-mail o notifiche Push vostre informazioni
                              Pubblicitarie.
                            </p>
                            <div class="flex flex-col justify-center">
                              <p>Si</p>
                              <input [(ngModel)]="request.privacyContatto"
                                     [value]="true" class="radio-redCustom"
                                     formControlName="privacyContatto"
                                     type="radio">
                            </div>
                            <div class="flex flex-col justify-center">
                              <p>No</p>
                              <input [(ngModel)]="request.privacyContatto"
                                     [value]="false" class="radio-redCustom"
                                     formControlName="privacyContatto"
                                     type="radio">
                            </div>
                          </div>
                          <!--THIRD PARTY-->
                          <div class="flex flex-row space-x-4">
                            <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                              Acconsento alla comunicazione e/o cessione di dati personali a società
                              terze per le loro attività di marketing, quali l’invio di materiale pubblicitario,
                              la vendita diretta, il compimento di ricerche di mercato e di comunicazione commerciale.
                            </p>
                            <div class="flex flex-col justify-center">
                              <p>Si</p>
                              <input [(ngModel)]="request.privacyThirdParty"
                                     [value]="true" class="radio-redCustom"
                                     formControlName="privacyThirdParty"
                                     type="radio">
                            </div>
                            <div class="flex flex-col justify-center">
                              <p>No</p>
                              <input [(ngModel)]="request.privacyThirdParty"
                                     [value]="false" class="radio-redCustom"
                                     formControlName="privacyThirdParty"
                                     type="radio">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirmation-modal>

</app-confirmation-modal>
