import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lead } from '../models/lead.model';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class LeadsService {

  constructor(private http: HttpClient) {
  }

  getLeadByEmail(email: string): Promise<Lead> {
    return this.http.get <Lead>(`${environment.apiUrl}/Leads/GetLeadByEmail/${email}`)
        .toPromise()
  }

  createLead(lead: Lead): Promise<Lead> {
    return this.http.post <Lead>(`${environment.apiUrl}/Leads/CreateLead`, lead)
        .toPromise()
  }

  addLeadToLocal(lead: Lead) {
    let storageLead: Lead = JSON.parse(localStorage.getItem('tta-lead' || null))
    if (!storageLead) {
      localStorage.setItem('tta-lead', JSON.stringify(lead))
    } else {
      if (!storageLead.whichCourses.includes(lead.whichCourses)) {
        lead.whichCourses = storageLead.whichCourses.concat(';', lead.whichCourses)
      }
      localStorage.setItem('tta-lead', JSON.stringify(lead))
    }
  }

  getLocalLead() {
    return localStorage.getItem('tta-lead' || null)
  }
}
