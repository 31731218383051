import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@envs/environment";
import { SignalType } from "@enums/signalType.enum";
import { SearchResult } from "@models/searchResult.model";
import { SignalAmmiratore } from "@models/signalAmmiratore.model";
import { BehaviorSubject, Observable } from "rxjs";
import { AmmiratoreLiveService } from "@/helpers/services/ammiratore-live.service";
import { map } from "rxjs/operators";


@Injectable({providedIn: 'root'})
export class SignalService {

  signals: BehaviorSubject<SignalAmmiratore[]>
  public lastSignal: BehaviorSubject<SignalAmmiratore>
  public ammiratoreCustomHeader: any;

  constructor(
    private http: HttpClient,
    private ammiratoreLiveService: AmmiratoreLiveService) {
    this.lastSignal = new BehaviorSubject<SignalAmmiratore>(null)
    this.signals = new BehaviorSubject<SignalAmmiratore[]>([])
  }

  async connectHub() {
    await this.ammiratoreLiveService.ConnectHub()
  }

  public setAmmiratoreSubscriberHeader = () => this.ammiratoreCustomHeader = {'X-ClientId': 'Ammiratore-Subscriber'}

  public get ammiratoreSubscriberStatus(): boolean {
    return this.ammiratoreLiveService.ammiratoreSubscriberSubject.value
  }

  public ammiratoreSubscriberStatusChange(status: boolean): void {
    this.ammiratoreLiveService.ammiratoreSubscriberSubject.next(status)
  }

  searchAmmiratoreSignals(search: string, page: number, count: number, sender: string, signalType: SignalType, subscribed: boolean): Promise<SearchResult<SignalAmmiratore>> {
    return this.http.post<SearchResult<SignalAmmiratore>>(`${environment.apiUrl}/Signals/SearchAmmiratoreSignals`,
      {search: search, page: page, count: count, sender: sender, signalType: signalType, subscribed: subscribed},
      {headers: this.ammiratoreCustomHeader})
      .toPromise()
  }

  getActiveSignals(sender?: string): Observable<SearchResult<SignalAmmiratore>> {
    return this.http.get<SearchResult<SignalAmmiratore>>(`${environment.apiUrl}/Signals/GetActiveSignals/${sender}`)
      .pipe(map((result: SearchResult<SignalAmmiratore>) => {
        this.ammiratoreLiveService.openPositions.next(result.result)
        return result
      }))
  }

  getOperationsForPosition(positionId: string): Promise<SearchResult<SignalAmmiratore>> {
    return this.http.get<SearchResult<SignalAmmiratore>>(`${environment.apiUrl}/Signals/GetOperationsForPosition/${positionId}`)
      .toPromise()
  }
}
