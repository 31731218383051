import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class DiscordService {

  constructor(private http: HttpClient) {}

  hasRole(discordId: string, roleName: string): Promise < any > {
    return this.http.post < any > (`${environment.apiUrl}/Discord/HasRole`, { discordId: discordId, roleName: roleName })
      .toPromise()
  }

  addUserToAmmiratore(discordUsername: string): Promise < any > {
    return this.http.post < any > (`${environment.apiUrl}/Discord/AddUserToAmmiratore`, { discordUsername: discordUsername })
      .toPromise()
  }

  removeUserRole(userId: string, roleToRemove: string): Promise < any > {
    return this.http.post < any > (`${environment.apiUrl}/Discord/RemoveUserRole`, { userId: userId, roleToRemove: roleToRemove })
      .toPromise()
  }

  addUserToChannelRole(discordUsername: string, roleName: string): Promise < any > {
    return this.http.post < any > (`${environment.apiUrl}/Discord/AddUserToChannelRole`, { discordUsername: discordUsername, roleName: roleName })
      .toPromise()
  }

  isUserInGuildByUsername(username: string): Promise < any > {
    return this.http.post < any > (`${environment.apiUrl}/Discord/IsUserInGuildByUsername`, { username})
    // return this.http.get < any > (`${environment.apiUrl}/Discord/IsUserInGuildByUsername/${username}`)
      .toPromise()
  }
}