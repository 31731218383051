import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "@/helpers/modules/shared.module";
import { IntroVideoComponent } from "./intro-video/intro-video.component";
import { ProductDetailComponent } from "./product-detail/product-detail.component";
import { ProductsListComponent } from "./products-list/products-list.component";
import { ProductsRoutingModule } from "./products-routing.module";
import { ProductsComponent } from "./products.component";
import { InstructionVideoComponent } from './instruction-video/instruction-video.component';
import { ProductCardComponent } from "@/components/product-card/product-card.component";
import { NgxPaginationModule } from "ngx-pagination";
import { PendingChangesGuard } from "@/helpers/guards/pendingChanges.guard";
import { ProductPlatformComponent } from "@/components/product-platform/product-platform.component";
import { ProductCoverComponent } from "@/components/product-cover/product-cover.component";

@NgModule({
  declarations: [
    ProductsComponent,
    ProductsListComponent,
    ProductDetailComponent,
    IntroVideoComponent,
    InstructionVideoComponent,
    ProductCardComponent,
    ProductPlatformComponent,
    ProductCoverComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    ProductsRoutingModule,
    NgxPaginationModule
  ],
  exports: [
    ProductCardComponent,
  ],
  providers: [PendingChangesGuard]
})

export class ProductsModule {
}
