import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ProductType } from '../../helpers/enums/productType.enum';
import { IAuthenticateResponse } from '../../helpers/models/authenticate.model';
import { OrderDetail } from '../../helpers/models/orderDetail.model';
import { Product } from '../../helpers/models/product.model';
import { AuthService } from '../../helpers/services/auth.service';
import { OrderDetailService } from '../../helpers/services/orderDetails.service';
import { OrderService } from '../../helpers/services/orders.service';
import { ProductService } from '../../helpers/services/products.service';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { fade } from '../../helpers/animations/fade.animation';
import { exit } from '../../helpers/animations/exit.animation';
import { SearchResult } from '../../helpers/models/searchResult.model';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Order } from '@/helpers/models/order.model';

let that: any

@Component({
  selector: 'app-shopping-cart',
  animations: [fade, exit],
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, AfterViewInit {

  serverUrl: string = environment.storageUrl;
  defaultImage: string = "https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/tt_logo.png"
  paypalOrderId: string = ''
  cartShow: boolean = false;
  products: Product[] = [];
  productType = ProductType;
  basketTotal: number = 0;
  productCount: number = 0;
  showSuccess: boolean = false;
  showError: boolean = false;
  showCancel: boolean = false;
  @AutoUnsubscribe()
  shouldEnablePaypal = new BehaviorSubject<boolean>(false);
  shouldEnablePaypal$ = this.shouldEnablePaypal.asObservable();
  @AutoUnsubscribe()
  shouldEnablePaypalSubscription: Subscription
  currentUser: IAuthenticateResponse
  currentOrderId!: number;
  basket: OrderDetail[] = [];
  @ViewChild("paypalRef", {static: true})
  private paypalRef: ElementRef

  constructor(
    private router: Router, // sembra non utilizzato siccome è usato con that invece di this (se lo cancelli sei un mongolo)
    private productService: ProductService,
    private orderDetailService: OrderDetailService,
    private authService: AuthService,
    private orderService: OrderService) {
    that = this
  }

  async ngOnInit() {
    this.authService.currentUserSubject.subscribe(user => {
      this.currentUser = user
    })
    this.productService.getProductCount()
      .subscribe(value => (value != undefined && true) ? this.productCount = value : 0)
    await this.orderService.getCurrentOrderId(this.currentUser.accountId)
      .then(result => {
        this.currentOrderId = result
        localStorage.setItem('tta-currentorderid', JSON.parse(this.currentOrderId.toString()))
      })
    if (this.currentUser && this.currentUser.jwtToken) {
      await this.loadFromDb()
    } else {
      this.checkCacheProducts()
      await this.countProducts()
      this.getTotal()
    }
  }

  async toggleCart() {
    this.checkCacheProducts()
    this.getTotal()
    await this.countProducts()
    this.cartShow = !this.cartShow;
  }

  checkCacheProducts = () => this.products = JSON.parse(localStorage.getItem('basketProducts') || '[]')

  getTotal = () => this.basketTotal = this.productService.getBasketTotal()

  async removeProduct(product: Product) {
    //await this.orderDetailService.deleteOrderDetail(this.currentUser.accountId, product.productId, this.currentOrderId)
    await this.orderDetailService.deleteOrderDetail(this.currentUser.accountId, product.productId, 0)
    this.productService.removeProductFromBasket(product);
    this.checkCacheProducts()
    this.getTotal()
    await this.countProducts()

    // Chiudi carrello se non ho prodotti dentro
    if (this.productCount == 0 && this.cartShow) {
      setTimeout(() => {
        this.cartShow = false
      }, 500)
    }
  }

  async countProducts() {
    this.productCount = this.products.length
    if (this.productCount > 0) {
      this.shouldEnablePaypal.next(true)
    } else {
      this.shouldEnablePaypal.next(false)
    }
  }

  async loadFromDb() {
    this.productCount = 0
    //basket:
    /*this.checkCacheProducts()
    this.productService.getProductBasket();
    this.getTotal()
    await this.countProducts()*/
    await this.orderDetailService.loadCart(this.currentUser.accountId)
      .then((details: SearchResult<OrderDetail>) => {
        if (details.totalCount > 0) {
          this.productService.emptyCart()
          details.result.forEach(async (detail: OrderDetail) => {
            if (detail.product != undefined) {
               if (detail.product.productType == this.productType.Assistenza || detail.product.productType == this.productType.Pacchetto_Indicatori) {
                await this.orderDetailService.deleteOrderDetail(this.currentUser.accountId, detail.productId, 0)
              } else { 
                detail.product.price = detail.price
                detail.product.duration = detail.duration
                detail.product.freeTrialDuration = detail.freeTrialDuration
                detail.typePurchases = detail.typePurchases
                this.productService.addProductToBasket(detail.product)
              } 
             }
          })
          this.getTotal()
          this.productCount = details.totalCount
          if (this.productCount > 0) {
            this.shouldEnablePaypal.next(true)
          }
        } else {
          this.productCount = 0
          localStorage.removeItem('basketProducts')
        }
      }).catch(() => {
        this.productCount = 0
        localStorage.removeItem('basketProducts')
      }) 
  }

  // PAYPAL
  ngAfterViewInit() {
    if (this.currentUser && this.currentUser.jwtToken) {
      window.paypal.Buttons({
        style: {
          layout: 'horizontal',
          tagline: false
        },

        onInit: function (data, actions) {
          that.shouldEnablePaypalSubscription = that.shouldEnablePaypal$.subscribe(shouldIDoIt => {
            if (shouldIDoIt) {
              actions.enable()
            } else
              actions.disable()
          })
        },

        createOrder() {
          if (that.shouldEnablePaypal.value == true) {
            // console.log('inizia pagamento')
            return fetch(`${environment.apiUrl}/PayPal/CreateOrder`, {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${that.currentUser.jwtToken}`
              },
              body: JSON.stringify(that.products)
            }).then(function (res) {
              return res.json();
            }).then(function (orderData) {
              that.paypalOrderId = orderData.id
              return orderData.id;
            })
          } else {
            return that.toastr.warning('Non hai prodotti nel carrello', 'Attenzione')
          }
        },

        onApprove: function (data, actions) {
          return fetch(`${environment.apiUrl}/PayPal/CaptureOrder`, {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${that.currentUser.jwtToken}`
            },
            body: JSON.stringify(that.paypalOrderId)
          }).then(function (res) {
            localStorage.setItem(`paypalOrder-${that.currentUser.accountId}`, that.paypalOrderId)
            return res.json();
          }).then(function (orderData) {
            let errorDetail = Array.isArray(orderData.details) && orderData.details[0];

            if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
              that.toastr.error('Il tuo metodo di pagamento è stato respinto', 'Pagamento respinto')
              return actions.restart(); // Recoverable state, per:
              // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
            }

            if (errorDetail) {
              return that.toastr.error(`Il tuo pagamento non è stato processato correttamente.`, 'Errore pagamento'); // Show a failure message (try to avoid alerts in production environments)
            }

            // Successful capture! For demo purposes:
            let transaction = orderData.purchase_units[0].payments.captures[0];
            let navigationExtras: NavigationExtras = {
              queryParams: {
                'isPaypal': 'paypal',
                'session_id': transaction.id,
                'tx_status': transaction.status,
                'amount': transaction.amount.value,
                'productId': that.products[0].productId,
              }
            }
            that.cartShow = !that.cartShow;
            that.router.navigate(['/dashboard/checkout/success'], navigationExtras)
          })
        }
      }).render(this.paypalRef.nativeElement)
    }
  }
}
