import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@envs/environment";
import { PlatformType } from "@enums/platformType.indicator";
import { Indicator } from "@models/indicator.model";

@Injectable({providedIn: 'root'})
export class IndicatorService {

  constructor(private http: HttpClient) {
  }

  getIndicator(accountId: string, indicatorId: number): Promise<Indicator> {
    return this.http.get <Indicator>(`${environment.apiUrl}/Indicators/GetIndicator/${accountId}/${indicatorId}`)
      .toPromise()
  }

  searchIndicators(search: string, page: number, count: number, platformType: PlatformType, productId?: number): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/Indicators/SearchIndicators`,
      {search: search, page: page, count: count, platformType: platformType, productId: productId})
      .toPromise()
  }

  createIndicator(indicator: Indicator): Promise<Indicator> {
    return this.http.post <Indicator>(`${environment.apiUrl}/Indicators/CreateIndicator`, indicator)
      .toPromise()
  }

  addIndicatorsRequest(indicator: Indicator): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/Indicators/AddIndicatorRequest`, indicator)
      .toPromise()
  }

/*   changeIndicatorLicense(accountId: string, licenses: string, platformType: PlatformType): Promise <any> {
    return this.http.post <any> (`${environment.apiUrl}/Indicators/ChangeIndicatorLicense`, {
      accountId: accountId, 
      licenses: licenses, 
      platformType: platformType
    })
    .toPromise();
  } */
}