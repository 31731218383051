<div
  class="flex justify-center min-h-screen w-full h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0 bg-customDark">
  <div class="flex h-full">
    <div class="container px-5 py-24 mx-auto">
      <h1 class="text-white text-4xl font-medium mb-1">Aiuto</h1>
      <h2 class="text-gray-300">Sezione aiuto con video esplicativi, per risolvere i dubbi più frequenti.</h2>
      <div class="container mx-auto">
        <!-- <h1
          class="text-white text-3xl underline decoration-redCustom underline-offset-4 font-medium mb-1 py-12 text-focus-in">
          MultiCharts</h1>
        <div class="flex flex-wrap mx-auto pt-4">
          <div class="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div *ngFor="let item of multiChartsVideos"
                 class="relative bg-grayBg w-full h-auto rounded-lg text-focus-in-fast transition-std">
              <div class="flex w-full">
                <app-video-player
                  [id]="item.title.replaceAll(' ', '-').toLowerCase()"
                  [facadeId]="item.id"
                  [label]="item.title"
                  [vimeoLink]="item.url"
                  class="w-full h-full">
                </app-video-player>
              </div>
            </div>
          </div>
        </div>
        <h1
          class="text-white text-3xl underline decoration-redCustom underline-offset-4 font-medium mb-1 py-12 text-focus-in">
          MetaTrader</h1>
        <div class="flex flex-wrap mx-auto pt-4">
          <div class="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div *ngFor="let item of metatraderVideos"
                 class="relative bg-grayBg w-full rounded-lg text-focus-in-fast transition-std">
              <div class="flex w-full">
                <app-video-player
                  [id]="item.title.replaceAll(' ', '-').toLowerCase()"
                  [facadeId]="item.id"
                  [label]="item.title"
                  [vimeoLink]="item.url"
                  class="w-full h-full">
                </app-video-player>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <h1 
          class="text-white text-3xl underline decoration-redCustom underline-offset-4 font-medium md:mb-1 py-12 text-focus-in"
          id=discord>Discord</h1>
        <div class="flex flex-wrap mx-auto md:pt-4">
          <div class="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div *ngFor="let item of discordVideos"
                 class="relative bg-grayBg w-full rounded-lg text-focus-in-fast transition-std">
              <div class="flex w-full">
                <app-video-player
                  [id]="item.title.replaceAll(' ', '-').toLowerCase()"
                  [facadeId]="item.id"
                  [label]="item.title"
                  [vimeoLink]="item.url"
                  class="w-full h-full">
                </app-video-player>
              </div>
            </div>
          </div>
        </div> -->

        <div *ngFor="let section of productSections">
          <h1 class="text-white text-3xl underline decoration-redCustom underline-offset-4 font-medium mb-1 py-12 text-focus-in">
            {{ section | replaceAll:'_':' ' | titleCaseCustom }}
          </h1>

          <div class="flex flex-wrap mx-auto pt-4">
            <div class="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 container">
              <div *ngFor="let product of products | filterSection: section"
                   class="relative bg-grayBg w-full h-auto rounded-lg text-focus-in-fast transition-std container">
                <div class="flex w-full">
                  <app-video-player
                    [id]="product.name.replaceAll(' ', '-').toLowerCase()"
                    [label]="product.description"
                    [vimeoLink]="product.presentationVideo"
                    class="w-full h-full">
                  </app-video-player>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
