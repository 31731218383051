import { Component } from '@angular/core';
import { AuthService } from "@/helpers/services/auth.service";
import { fade } from "@/helpers/animations/fade.animation";

@Component({
  selector: 'app-discord-app-download',
  templateUrl: './discord-app-download.component.html',
  styleUrls: ['./discord-app-download.component.scss'],
  animations: [fade]
})
export class DiscordAppDownloadComponent {

  constructor(public auth: AuthService) {
  }

  toggleModal = (event?: any) => this.auth.shouldShowDiscordAppDownload.next(false)
}
