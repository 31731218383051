import { Injectable } from '@angular/core'; 
import { Meta, Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class SEOService {
  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'og:title', content: title })
    this.meta.updateTag({ name: 'og:site_name', content: title })
  }

  updateOgUrl =(url: string) => this.meta.updateTag({ name: 'og:url', content: url })

  updateOgLocale = () => this.meta.updateTag({ name: 'og:locale', content: 'it_IT' })

  updateOgType = (type: string) => this.meta.updateTag({ name: 'og:type', content: type })

  updateOgImage(url: string) {
    this.meta.updateTag({ name: 'og:image', content: url }) 
    this.meta.updateTag({ name: 'og:image:secure_url', content: url }) 
  }
  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
    this.meta.updateTag({ name: 'og:description', content: desc })
  }
}