<div class="fixed z-20 top-6 lg:top-7 right-2 md:right-6 slide-in-blurred-top">
  <span (click)="toggleCart()">
    <button
      class="px-1 relative border-2 border-transparent text-white rounded-full hover:text-redCustom focus:outline-none focus:text-gray-500 transition-std"
      aria-label="Cart">
      <svg class="h-8 w-8 lg:h-6 lg:w-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
           viewBox="0 0 24 24" stroke="currentColor">
        <path
          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z">
        </path>
      </svg>
      <span *ngIf="productCount != undefined && productCount != null && productCount > 0"
            class="absolute inset-0 object-right-top -mr-6 -translate-y-3">
        <div
          class="inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-semibold leading-4 bg-redCustom text-black">
          {{ productCount }}
        </div>
      </span>
    </button>
  </span>
</div>
<div [@exit]="cartShow" [hidden]="!cartShow" class="z-40 fixed inset-0 overflow-hidden"
     aria-labelledby="slide-over-cart" role="dialog" aria-modal="true">
  <div class="absolute inset-0 overflow-hidden slide-in-right">
    <div (click)="toggleCart()" class="absolute inset-0" aria-hidden="true"></div>
    <div class="fixed inset-y-0 right-0 max-w-full flex">
      <div class="w-screen max-w-md">
        <div class="h-full flex flex-col bg-customDark border-l border-gray-700 shadow-darkhHeavy">
          <div class="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
            <div class="flex items-start justify-between">
              <h2 class="text-2xl font-semibold text-white" id="slide-over-title">
                Carrello
              </h2>
              <div class="ml-3 h-7 flex items-center">
                <button (click)="toggleCart()" type="button" class="-m-2 p-2 text-gray-300 hover:text-redCustom">
                  <span class="sr-only">Chiudi</span>
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                  </svg>
                </button>
              </div>
            </div>

            <div class="mt-8">
              <div class="flow-root">
                <ul role="list" class="-my-6 divide-y divide-gray-400">
                  <li class="py-6 flex" *ngFor="let product of products" [@fade]="product">
                    <div class="flex-shrink-0 w-24 h-24 rounded-md overflow-hidden">
                      <img [src]="product.imageUrl != '' ? serverUrl + product.imageUrl : defaultImage"
                           [alt]="product.name" class="w-full h-full object-center object-cover">
                    </div>

                    <div class="ml-4 flex-1 flex flex-col">
                      <div>
                        <div class="flex justify-between text-base font-semibold text-gray-200">
                          <h3>
                            <a href="#">
                              {{ product.name }}
                            </a>
                          </h3>
                          <p *ngIf="product.productType != productType.Membership
                          && product.productType !== productType.Assistenza
                          && product.productType !== productType.Indicatore" class="ml-4">
                            {{ product.price | currency:'EUR' }}
                          </p>
                          <p *ngIf="product.productType == productType.Membership" class="ml-4">
                            {{ product.price | currency:'EUR'}}/Mese
                          </p>
                        </div>
                        <p class="mt-1 text-sm text-gray-200">
                          {{ productType[product.productType] }}
                        </p>
                        <p class="my-2 text-xs text-gray-300"
                           *ngIf="product.productType === productType.Assistenza
                          || product.productType === productType.Indicatore">
                          N.B: Non Rimuovere per ottenere il prodotto
                        </p>
                      </div>
                      <div class="flex-1 flex items-end justify-between text-sm">
                        <p class="text-gray-200">
                          Q.tà: 1
                        </p>
                        <div class="flex">
                          <button (click)="removeProduct(product)" type="button"
                                  class="font-medium text-redCustom hover:text-redCustom-light">Rimuovi
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="border-t border-gray-700 py-6 px-4 sm:px-6">
            <div class="flex justify-between text-lg font-medium text-gray-200">
              <p>Totale</p>
              <p>{{ basketTotal | currency:'EUR' }}</p>
            </div>
            <div *ngIf="(currentUser | json) != '{}'" class="flex text-center text-sm text-gray-300 mt-2">
              <p class="m-auto">Cliccando verrai indirizzato alla pagina di checkout.</p>
            </div>
            <!-- STRIPE -->
            <div *ngIf="(currentUser | json) != '{}'" class="mt-6">
              <app-checkout></app-checkout>
            </div>
            <!-- PAYPAL -->
            <div class="mt-2 text-white" #paypalRef></div>

            <div class="mt-6 flex justify-center text-sm text-center text-gray-500">
              <a (click)="toggleCart()" [routerLink]="['/shop']" routerLinkActive="router-link-active">
                <button type="button"
                        class="inline-flex flex-row text-gray-300 hover:text-redCustom font-medium transition-std">
                  <svg xmlns="http://www.w3.org/2000/svg" class="inline-flex my-auto ml-2 h-4 w-4" fill="none"
                       viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12"/>
                  </svg>
                  <span>Continua a fare Shopping</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
