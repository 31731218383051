import { Pipe, PipeTransform } from '@angular/core';
import { ProductType } from '../enums/productType.enum';

@Pipe({
  name: 'filterProductArray',
  pure: false
})
export class FilterProductArrayPipe implements PipeTransform {
  transform(items: any[], productType: ProductType): any {
    return items.filter(item => item.productType === productType)
  }
}
