<div class="flex flex-col m-auto w-full py-5px-4 sm:p-6">
  <h2
    class="mb-6 underline underline-offset-4 decoration-redCustom py-3 text-center text-2xl sm:text-3xl text-bold text-white">
    Conferma Abbonamento
  </h2>
  <div class="flex flex-col m-auto max-w-sm text-gray-300">
    <p class="overflow-auto custom-justify">
      Se desideri proseguire clicca <span class="font-bold">Continua</span>, altrimenti clicca il
      simbolo <span class="font-bold ">X</span> in alto a destra o clicca fuori dalla
      finestra che stai visualizzando.
      Una volta attivato l'abbonamento, in caso di mancato pagamento il prodotto da te richiesto verrà
      disattivato. <br>
      Per disdire l'abbonamento contattaci all'indirizzo email: <br>
      <a class="text-redCustom hover:text-redCustom-dark transition-std before:content-['_✉']"
         href="mailto:assistenza@top-tradingacademy.com?subject=Assistenza abbonamenti">
        assistenza@top-tradingacademy.com
      </a>
    </p>
    <p *ngIf="!isIndicator" class="font-bold mt-6">Dettagli Abbonamento:</p>
    <ul class="list-disc ml-6" *ngIf="!isIndicator">
      <li>
        <span class="font-bold underline text-white decoration-redCustom underline-offset-2">
        Prezzo:</span> {{ subscriptionPrice | currency:'EUR' }}
      </li>
      <li *ngIf="subscriptionFreeTrial > 0">
        <span class="font-bold underline text-white decoration-redCustom underline-offset-2">
          Periodo Prova:</span> {{ subscriptionFreeTrial }} giorni
      </li>
      <li><span
        class="font-bold underline text-white decoration-redCustom underline-offset-2">Rinnovi:</span>
        ogni {{ subscriptionCycle }} giorni
      </li>
    </ul>
    <!--SOLO INDICATORI-->
    <form *ngIf="isIndicator" [formGroup]="form">
      <label class="label-std-left-validated my-4">
        Scegli la ricorrenza che preferisci
      </label>
      <ul>
        <li>
          <input [(ngModel)]="whichPrice"
                 [value]="firstSubscriptionPriceId" class="radio-redCustom mb-1"
                 formControlName="whichPrice"
                 type="radio">
          <span class="ml-2">
            {{ subscriptionPrice | currency:'EUR' }}
            <span class="text-xs">/ 3 Mesi</span>
          </span>
        </li>
        <li>
          <input [(ngModel)]="whichPrice"
                 [value]="secondSubscriptionPriceId" class="radio-redCustom mb-1"
                 formControlName="whichPrice"
                 type="radio">
          <span class="ml-2">
            {{ secondSubscriptionPrice | currency:'EUR' }}
            <span class="text-xs">/ 12 Mesi</span>
            <span class="ml-2 text-xs font-bold text-redCustom">(Risparmia un trimestre)</span>
          </span>
        </li>
      </ul>
    </form>
  </div>
  <div class="flex flex-col m-auto mt-8">
    <button [ngClass]="{'disabled opacity-20': isIndicator && !form.valid}"
            [disabled]="isIndicator && !form.valid"
            (click)="toggleDisclaimerConfirmation($event)"
            class="button-small">
      <div class="flex flex-row">
        <svg *ngIf="!subscriptionConfirmation" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke-width="1.5" stroke="currentColor"
             class="inline-flex my-auto h-4 w-4 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"/>
        </svg>
        <app-loading-animation-small
          *ngIf="subscriptionConfirmation"
          class="mr-2 inline-flex m-auto">
        </app-loading-animation-small>
        <span *ngIf="!subscriptionConfirmation">Continua</span>
        <span *ngIf="subscriptionConfirmation">Carico...</span>
      </div>
    </button>
  </div>
  <p class="m-auto text-center text-gray-400 text-xs mt-2">
    Cliccando continua confermi di aver letto il paragrafo sovrastante.
  </p>
</div>