<!-- PREVIEW -->
<div (mouseover)="renderFacade()" class="relative">
  <label [for]="facadeId" class="label-std-center pointer-events-none">
    {{ label }}
  </label>
  <picture *ngIf="hoverActive !== facadeId">
    <source srcset="../../../assets/images/placeholders/logo-rosso-verde.webp" type="image/webp">
    <img [height]="dimensions[1]"
         [id]="facadeId" [width]="dimensions[0]"
         alt="Video Top Trading Academy"
         class="border border-gray-700 rounded-lg"
         src="../../../assets/images/placeholders/logo-rosso-verde.jpg"
         title="Video Top Trading Academy">
  </picture>
  <!-- PLAY BUTTON -->
  <div *ngIf="hoverActive !== facadeId" class="inline-flex absolute inset-0 text-redCustom z-20">
    <svg class="m-auto h-6 w-6" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="m4.5 21 15-9-15-9v18Z"></path>
    </svg>
  </div>
</div>
<!-- PLYR -->
<div class="video-container relative" [ngClass]="{'watermark': !encodedVideo}">
  <div (plyrEnded)="onVideoEnded($event)" *ngIf="hoverActive === facadeId"
       [plyrPlaysInline]="true"
       [plyrSources]="source"
       class="w-full h-full"
       plyr>
  </div>
</div>
