import { trigger, style, animate, transition, state } from '@angular/animations';
import { Component } from '@angular/core';

@Component({
  selector: 'app-cookies-pop-up',
  animations: [
    trigger(
      'exitAnimation', [
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('500ms ease-in', style({transform: 'translateY(0%)', opacity: 0}))
        ])
      ]
    ),
    trigger('fade', [
      state('in', style({
        opacity: 0
      })),
      state('out',   style({
        opacity: 1
      })),
      transition('in => out', animate('500ms ease-in')),
      transition('out => in', animate('500ms ease-out'))
    ])
  ],
  templateUrl: './cookies-pop-up.component.html',
  styleUrls: ['./cookies-pop-up.component.scss']
})
export class CookiesPopUpComponent {

  hidden: boolean = false;

  constructor() {
    if (localStorage.getItem('tta-cookie-seen') == 'seen')
      this.hidden = true
  }

  close() {
    this.hidden = true
    localStorage.setItem('tta-cookie-seen', 'seen')
  }
}
