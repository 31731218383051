export enum ProductType {
  Corso = 1,
  Lezione = 2,
  Assistenza = 3,
  Coaching = 4,
  Membership = 5,
  //VideoAmmiratore = 6,
  AssistenzaFree = 7,
  IntroCorso = 8,
  Pacchetto_Indicatori = 9, 
  Indicatore = 10,
  //Indicatore_Singolo = 11,
  Lezione_Membership = 12,
  //Indicatori_NinjaTrader = 13,
  //Corso_Free = 14
  Aiuto = 11
}