import { Component } from '@angular/core';
import { SEOService } from '@services/seo.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent {

  ttaLogoUrl: string = 'https://ttgitaliastorage.blob.core.windows.net/ttgitalia-container/logo-rosso-verde.png'

  constructor(private seo: SEOService) {
    this.seo.updateTitle('Contatti | Top-Trading Academy')
    this.seo.updateDescription('Vuoi avere maggiori informazioni su chi siamo e sui nostri prodotti? Non esitare a scriverci, siamo a disposizione.')
  }
}
