import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {OrderDetail} from "../models/orderDetail.model";
import {SearchResult} from "../models/searchResult.model";
import { TypePurchases } from "../enums/typePurchases.enum";
import { ProductStatus } from "../enums/productStatus.enum";

@Injectable({providedIn: 'root'})
export class OrderDetailService {

  constructor(private http: HttpClient) {
  }

  getOrderDetail(accountId: string, productId: number, orderId: number): Promise<OrderDetail> {
    return this.http.get <OrderDetail>(`${environment.apiUrl}/OrderDetails/GetOrderDetail/${accountId}/${productId}/${orderId}`)
      .toPromise()
  }

  searchOrderDetails(search: string, page: number, count: number): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/OrderDetails/SearchOrderDetails`, {
      search: search,
      page: page,
      count: count
    })
      .toPromise()
  }

  loadCart(accountId: string): Promise<SearchResult<OrderDetail>> {
    return this.http.get<SearchResult<OrderDetail>>(`${environment.apiUrl}/OrderDetails/LoadCart/${accountId}`)
      .toPromise()
  }

  createOrderDetail(orderDetail: OrderDetail): Promise<OrderDetail> {
    return this.http.post <OrderDetail>(`${environment.apiUrl}/OrderDetails/CreateOrderDetail`, orderDetail)
      .toPromise()
  }

  createBasketOrder(orderDetail: OrderDetail): Promise<OrderDetail> {
    return this.http.post <OrderDetail>(`${environment.apiUrl}/OrderDetails/CreateBasketOrder`, orderDetail)
      .toPromise()
  }

  updateOrderDetail(orderDetail: OrderDetail): Promise<OrderDetail> {
    return this.http.post <OrderDetail>(`${environment.apiUrl}/OrderDetails/UpdateOrderDetail`, orderDetail)
      .toPromise()
  }

  deleteOrderDetail(accountId: string, productId: number, orderId: number): Promise<number> {
    return this.http.post <number>(`${environment.apiUrl}/OrderDetails/DeleteOrderDetail`, {
      accountId: accountId,
      productId: productId,
      orderId: orderId
    })
      .toPromise()
  }

  doesOwnProduct(accountId: string, productName: string, productId ?: number): Promise<any> {
    let baseUrl = `${environment.apiUrl}/OrderDetails/DoesOwnProduct/${accountId}/${productName}`
    if (productId != undefined) baseUrl += `/${productId}`
    return this.http.get <any>(baseUrl)
      .toPromise()
  }

  assignProductToNewAccount(productId: number, accountId: string, extTxId: string, amountPaid: number, isSepa: boolean, duration: number, freeTrialDuration: number, typePurchases: TypePurchases): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/OrderDetails/AssignProductToNewAccount`, {
      productId: productId,
      accountId: accountId,
      extTxId: extTxId,
      amountPaid: amountPaid,
      isSepa: isSepa,
      duration: duration, 
      freeTrialDuration: freeTrialDuration, 
      typePurchases: typePurchases
    })
      .toPromise()
  }

  activatePreviouslyAssignedProducts(accountId: string): Promise<number> {
    return this.http.get <number>(`${environment.apiUrl}/OrderDetails/ActivatePreviouslyAssignedProducts/${accountId}`)
      .toPromise()
  }

  changeOrderDetailActivation(productId: number, productStatus: ProductStatus): Promise<number> {
    return this.http.post <number>(`${environment.apiUrl}/OrderDetails/ChangeOrderDetailActivation`, {
      productId: productId,
      productStatus: productStatus
    }).toPromise()
  }

  purchaseProductWithCoins(productId: number, amountPaid: number): Promise<boolean> {
    return this.http.post <boolean>(`${environment.apiUrl}/OrderDetails/PurchaseProductWithCoins`, {
      productId: productId,
      amountPaid: amountPaid
    })
      .toPromise()
  }
}
