<app-cookies-pop-up class="fixed bottom-0 z-40 w-full slide-in-blurred-top"></app-cookies-pop-up>
<app-calendly-button></app-calendly-button>
<!-- <app-menu *ngIf="!router.url.includes('king-of-trading')" class="fixed z-30 w-full transition-all duration-300 slide-in-blurred-top lg:bg-gradient-to-b from-black/50 via-black/20" id="navbar"></app-menu> -->
<app-menu *ngIf="!isPlaying"
          class="fixed z-30 w-full transition-std slide-in-blurred-top lg:bg-gradient-to-b from-black/50 via-black/20"
          id="navbar">
</app-menu>
<app-shopping-cart *ngIf="currentUser && currentUser.jwtToken" class="fixed z-30"
                   id="shopping-cart">
</app-shopping-cart>
<app-discord-redirect-modal></app-discord-redirect-modal>
<!-- <app-landscape-mobile></app-landscape-mobile> -->
<router-outlet></router-outlet>
<app-footer *ngIf="!router.url.includes('king-of-trading')" class="relative z-40"></app-footer>
<!-- <app-footer class="relative z-40"></app-footer> -->
