import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe } from "@angular/common";

@Pipe({
  name: 'titleCaseCustom'
})

export class TitleCaseCustomPipe extends TitleCasePipe implements PipeTransform {
  transform(value: string): any {
    if (value === undefined) return value
    let split = value.split(' ');
    let newValue = '';
    split.forEach((word) => {
      if (word.length > 1) {
        newValue += super.transform(word) + ' '
      } else {
        newValue += word.toLowerCase() + ' '
      }
    })
    return newValue;
  }
}