import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { StripeService } from 'ngx-stripe';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { ProductService } from '@services/products.service';
import { environment } from '@envs/environment';
import { Product } from '@models/product.model';
import { ToastrService } from 'ngx-toastr';
import { asyncScheduler, of } from "rxjs";
import { OrderDetail } from '@/helpers/models/orderDetail.model';
import { TypePurchases } from '@/helpers/enums/typePurchases.enum';
import { OrderDetailService } from '@services/orderDetails.service';
import { IAuthenticateResponse } from '@/helpers/models/authenticate.model';
import { AuthService } from '@services/auth.service';
import { OrderService } from '@/helpers/services/orders.service';

const defaultValues: OrderDetail = {
  accountId: '',
  productId: 0,
  orderId: 0,
  quantity: 1,
  orderStatus: 1,
  duration: 0,
  evasion: 0,
  freeTrialDuration: 0, 
  price: 0,
  typePurchases: TypePurchases.Days
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {

  products!: string[]
  session: any;
  loading: boolean = false;
  orderDetail = defaultValues;  
  currentUser: IAuthenticateResponse

  constructor(
    private toastr: ToastrService,
    private http: HttpClient,
    private stripeService: StripeService,
    private productService: ProductService,
    private orderDetailService: OrderDetailService,
    private auth: AuthService,
    private orderService: OrderService) {
      this.currentUser = this.auth.currentUserValue
    }

  async createOrderDetail() {
    for (const product of this.productService.basketProducts) {
      this.orderDetail.accountId = this.currentUser.accountId
      this.orderDetail.productId = product.productId
      this.orderDetail.typePurchases = product.typePurchases
      this.orderDetail.freeTrialDuration = product.freeTrialDuration
      this.orderDetail.duration = product.duration
      this.orderDetail.price = product.price
      await this.orderDetailService.createOrderDetail(this.orderDetail)
    }
  }

  async checkout() {
    this.products = this.productService.basketProducts
      .map((product: Product) => product.stripePriceId)
    if (this.products != undefined && this.products.length > 0) {
      this.loading = true;

      this.http.post(`${environment.apiUrl}/Stripe/CreateSession`, {products: this.products})
        .pipe(
          switchMap((session: any) => {
            return this.stripeService.redirectToCheckout({sessionId: session.session})
          }),
          catchError(_ => of(
            this.toastr.warning("C'è stato un errore nell'inizializzazione del pagamento", "Attenzione")
          )),
          finalize(() =>
            this.loading = false
          )
        ).subscribe(() => {
      })
    } else this.toastr.warning('Non hai prodotti nel carrello', 'Attenzione')
  }
}