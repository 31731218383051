import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@envs/environment";

@Injectable({providedIn: 'root'})
export class PayPalService {

  constructor(private http: HttpClient) {
  }

  getTransaction(orderId: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/PayPal/GetTransaction/${orderId}`).toPromise()
  }

  sendPurchaseConfirmationEmail(customerName: string, customerEmail: string, productName: string, productImage: string) {
    return this.http.post(`${environment.apiUrl}/PayPal/SendPurchaseConfirmationEmail`,
      {customerName, productName, productImage, customerEmail})
      .toPromise()
  }
}