import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IAuthenticateResponse } from '../../models/authenticate.model';
import { AuthService } from '../../services/auth.service';
import { fade } from '../../animations/fade.animation';
import { Router } from '@angular/router';
import { Role } from "@/helpers/enums/role.enum";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [fade]
})
export class MenuComponent implements OnInit {

  toggle: boolean = false
  isUserLogged: boolean = false;
  menuDropdown: boolean = false;

  currentUsername: string = '';
  defaultImage: string = ''
  serverUrl: string = environment.storageUrl;
  currentUser: IAuthenticateResponse;
  ebookUrl: string = environment.eBookUrl;

  allowedRoles: Role[] = [Role.Admin, Role.SuperAdmin]

  constructor(
    private router: Router,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.currentUserSubject.subscribe(user => {
      this.currentUser = user
    })
    if (this.currentUser.jwtToken) {
      this.isUserLogged = true
      this.currentUsername = this.currentUser.firstName
      this.getUserAvatar()
    }
  }

  toggleMenu = () => this.toggle = !this.toggle;

  toggleMenuDropdown = () => this.menuDropdown = !this.menuDropdown

  logOut() {
    this.authService.logout()
  }

  animateMenu() {
    const div = document.getElementById('menu')
    div.classList.remove('active')
  }

  getUserAvatar = () => this.defaultImage = `https://eu.ui-avatars.com/api/background=D97706&color=000?name=${this.currentUser.firstName}+${this.currentUser.lastName}`

  async openLogin() {
    await this.router.navigate(['/'], {state: {data: {loginShow: true}}})
  }
}
