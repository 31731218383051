import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "@/helpers/services/auth.service";
import { Role } from "@/helpers/enums/role.enum";

@Injectable({providedIn: 'root'})
export class AdminGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser = this.auth.currentUserValue
    return new Promise(async (resolve) => {
      if (currentUser && currentUser.jwtToken) {
        const role: Role = currentUser.role
        if (role === Role.Admin || role === Role.SuperAdmin) {
          return resolve(true)
        }
      }
      await this.router.navigate(['/'])
      return resolve(false)
    })
  }
}