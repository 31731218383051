import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { fade } from "@animations/fade.animation";

@Component({
  selector: 'app-intro-video',
  animations: [fade],
  templateUrl: './intro-video.component.html',
  styleUrls: ['./intro-video.component.scss']
})
export class IntroVideoComponent implements OnInit {

  @Input() showIntroVideo: boolean;
  @Input() introChildName: string;
  @Input() introChildLink: string;
  @Output() modalCallback: EventEmitter<any> = new EventEmitter()

  introChildLinkSanitized: SafeResourceUrl = ''

  constructor(public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    if (this.introChildLink != '' || this.introChildLink != undefined)
      this.introChildLinkSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.introChildLink)
  }

  toggleVideoModal = (event: any) => this.modalCallback.emit(this.showIntroVideo)
}
