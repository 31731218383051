<div
  class="flex justify-center w-full h-screen sm:block sm:p-0 bg-customDark sm:bg-orange-blob-dark bg-cover bg-no-repeat text-white">
  <div class="flex flex-col m-auto md:pt-72 lg:pt-24">
    <h1
      class="mx-auto title-font text-4xl md:text-3xl mb-4 font-medium underline decoration-redCustom underline-offset-2">
      Contatti</h1>
    <p class="leading-relaxed max-w-md m-auto mb-5 text-center pt-4 text-focus-in text-gray-300">Vuoi avere maggiori
      informazioni su chi siamo e sui nostri prodotti? Non esitare a scriverci, siamo a disposizione.</p>
    <div
      class="lg:w-1/3 md:w-1/2 bg-customDark border border-gray-700 mx-auto rounded-lg p-8 flex flex-col md:ml-auto w-full mt-2 md:mt-0 text-focus-in">
      <div class="flex mx-auto">
        <img alt="Top Trading Academy Logo" class="w-40 md:w-56 h-auto filter invert" height="29" src="{{ ttaLogoUrl }}"
             width="150">
      </div>
      <div class="relative mb-4 pt-4">
        <label
          class="mx-auto title-font mb-4 font-medium text-white align-top border-b-2 border-redCustom">Email</label>
        <a href="mailto:info@top-tradingacademy.com?subject=Contatto%20da%20Sito%20web" id="mailto-link">
          <p class="mt-2 text-gray-300 hover:text-redCustom transition-std">info@top-tradingacademy.com<i
            aria-hidden="true" class="fa fa-italic"></i></p>
        </a>
      </div>
      <div class="relative mb-4">
        <label class="mx-auto title-font mb-4 font-medium text-white align-top border-b-2 border-redCustom">
          Sede Legale
        </label>
        <p class="mt-2 text-gray-300">Via Edmondo Borri 8 - 28922 Verbania (VB) - Italia</p>
      </div>
      <div class="relative mb-4">
        <label class="mx-auto title-font mb-4 font-medium text-white align-top border-b-2 border-redCustom">
          Sede Operativa
        </label>
        <p class="mt-2 text-gray-300">Parco Rodari 12 - 28887 Omegna (VB) - Italia</p>
      </div>
    </div>
  </div>
</div>
