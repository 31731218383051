import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private toastr: ToastrService,
    private router: Router,
    private authenticationService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser = this.authenticationService.currentUserValue;
    return new Promise(async (resolve) => {
      if (currentUser && currentUser.jwtToken) {
        // logged in so return true
        return resolve(true);
      } else {
        this.toastr.warning("Login scaduto, effettua l'accesso", "Attenzione");
        await this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
        resolve(false);
      }

      // not logged in so redirect to login page with the return url
      await this.router.navigate(['/'], {queryParams: {returnUrl: state.url}});
      resolve(false);
    });
  }
}
