import { Component, HostListener, OnInit } from '@angular/core';
import { Product } from '@models/product.model';
import { ProductType } from '@enums/productType.enum';
import { ProductService } from '@services/products.service';
import { SEOService } from '@services/seo.service';
import { fade } from '@animations/fade.animation';
import { Subscription } from "rxjs";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe-decorator";
import { SearchResult } from "@models/searchResult.model";
import { ProductAvailability } from "@enums/productAvailability.enum";
import { TypeCovers } from '@/helpers/enums/typeCovers.enum';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
  animations: [fade]
})
export class ProductsListComponent implements OnInit {

  products: Product[] = null;
  availableTypes: ProductType[] = [];
  productType = ProductType;
  loading = false;
  searchNote: string = ''
  typeCovers = TypeCovers;

  @AutoUnsubscribe()
  productSubscription: Subscription

  constructor(
    private seo: SEOService,
    private productService: ProductService) {
    this.seo.updateTitle('Shop | Top-Trading Academy')
    this.seo.updateDescription('Scopri i migliori corsi di Trading Online basati sui Cicli di Borsa e sui Volumi. Diventa un Trader Professionista grazie ad un percorso formativo personalizzato')
  }

  @HostListener('window:beforeunload', ['$event']) beforeUnloadHandler(event: any) {
    return !this.loading;
  }

  async ngOnInit() {
    this.productSubscription = this.productService.products.subscribe(async val => {
      if (val && val.length) {
        this.products = val
        await this.getAvailableTypes(this.products)
        this.loading = false
      } else {
        //this.loading = true
        await this.productService.searchProducts(this.searchNote, 0, 0, 0, undefined, undefined, this.typeCovers.Cover)
          .then((_products: SearchResult<Product>) => {
            if (_products.totalCount > 0) {
              _products.result = _products.result.filter((product: Product) => product.productAvailability !== ProductAvailability.NonDisponibile)
              this.productService.products.next(_products.result)
            }
          }).catch(() => {
            this.products = []
          }).finally(() => this.loading = false)
      }
    })
  }

  async getAvailableTypes(products: Product[]) {
    return this.availableTypes = products
      .filter((thing, i, arr) => arr.findIndex(t => t.productType === thing.productType) === i)
      .map(x => x.productType);
  }
}
