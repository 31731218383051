import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  CheckoutSuccessfulNoregComponent
} from "../../components/checkout-successful-noreg/checkout-successful-noreg.component";
import { InstructionVideoComponent } from "./instruction-video/instruction-video.component";
import { ProductDetailComponent } from "./product-detail/product-detail.component";
import { ProductsListComponent } from "./products-list/products-list.component";
import { PendingChangesGuard } from "@/helpers/guards/pendingChanges.guard";

const routes: Routes = [
  {path: '', component: ProductsListComponent},
  {path: 'product/:productId/:productName', component: ProductDetailComponent},
  {path: 'product-instruction/:instructionVideoId', component: InstructionVideoComponent},
  {
    path: 'checkout/success-noreg/:productName',
    canDeactivate: [PendingChangesGuard],
    component: CheckoutSuccessfulNoregComponent
  },
  {
    path: 'checkout/success-noreg/:productName/:grId',
    canDeactivate: [PendingChangesGuard],
    component: CheckoutSuccessfulNoregComponent
  },

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ProductsRoutingModule {
}
