import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@/helpers/services/auth.service';
import { ProductService } from '@/helpers/services/products.service';
import { StripeTTAService } from '@/helpers/services/stripeTTA.service';
import { IAuthenticateResponse } from '@/helpers/models/authenticate.model';
import { StripePaymentCheck } from '@/helpers/models/stripePaymentCheck.model';
import { Product } from '@/helpers/models/product.model';
import { LeadsService } from '@/helpers/services/leads.service';
import { Lead } from '@/helpers/models/lead.model';
import { RegisterMinimalRequest } from '@/helpers/requests/registration.request';
import { fade } from '@/helpers/animations/fade.animation';
import { OrderDetailService } from '@/helpers/services/orderDetails.service';
import { PayPalService } from '@/helpers/services/paypal.service';
import { ProductType } from '@/helpers/enums/productType.enum';
import { environment } from '@envs/environment';
import { SendEmailVerification } from '@/helpers/requests/sendEmailVerification.request';
import { Observable } from "rxjs";
import { ComponentCanDeactivate } from "@/helpers/guards/pendingChanges.guard";
import { AddContactOptions } from "@/helpers/models/addContact.getResponse.model";
import { GetResponseService } from "@/helpers/services/getResponse.service";
import { HubSpotService } from '@/helpers/services/hubspot.service';
import { PaymentType } from '@/helpers/enums/paymentType.enum';

@Component({
  selector: 'app-checkout-successful-noreg',
  templateUrl: './checkout-successful-noreg.component.html',
  styleUrls: ['./checkout-successful-noreg.component.scss'],
  animations: [fade]
})
export class CheckoutSuccessfulNoregComponent implements OnInit, ComponentCanDeactivate {

  serverUrl = environment.storageUrl
  sessionId: string = '';
  productName: string = ''
  titleMessage: string = 'Elaboro pagamento'
  registrationMessage: string = ''

  // getResponseId
  getResponseId: string = ''

  // PayPal parameters
  isPaypal: string = ''
  tx_id: string = ''
  paypalOrderID: string = ''
  transactionStatus: string = ''
  transactionAmount: string = ''

  leadName: string = ''
  leadEmail: string = ''
  leadPhone: string = ''

  accountEmail: string = ''

  product: Product;
  productId: number;

  productType = ProductType

  loading: boolean = false;
  finishedProcess: boolean = false;
  alreadyAssigned: boolean = false;

  currentUser: IAuthenticateResponse;
  orderId: number = 0

  constructor(
    private getResponse: GetResponseService,
    private paypalService: PayPalService,
    private productService: ProductService,
    private orderDetailService: OrderDetailService,
    private leadService: LeadsService,
    private stripe: StripeTTAService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private hubSpotService: HubSpotService) {
    this.loading = true
    this.route.queryParams.subscribe(params => {
      this.sessionId = params['session_id']
      this.tx_id = params['tx_id']
      this.paypalOrderID = params['orderID']
      this.isPaypal = params['isPaypal']
      this.transactionStatus = params['tx_status']
      this.transactionAmount = params['amount']
      this.leadName = params['userName']
      this.leadEmail = params['userEmail']
      this.leadPhone = params['userPhone']
    })
    this.route.params.subscribe(params => {
      this.productName = params['productName']
      this.getResponseId = params['grId']
      this.productId = Number(params['productId'])
    })
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.loading;
  }

  async ngOnInit() {
    // workflow stripe
    // #region Workflow Stripe
    if (this.sessionId != undefined && this.isPaypal !== 'paypal') {
      // carica prodotto (lo mostro all'utente)
      const productName = this.productService.polishProductName(this.productName)
      if (productName != undefined && this.productId != 0) {
        await this.getProduct(productName)
      }
      // Verifico che il pagamento sia andato a buon fine o che sia un pagamento dilazionato
      await this.isStripePaymentSuccessful(this.sessionId)
        .then(async (paymentCheck: StripePaymentCheck) => {
          if (paymentCheck.paymentIntentStatus === 'succeeded' || paymentCheck.paymentIntentStatus === 'processing') {
            if (paymentCheck.customer.email != null) {
              // carico il lead
              await this.leadService.getLeadByEmail(paymentCheck.customer.email)
                // se lo trovo proseguo con la registrazione
                .then(async (lead: Lead) => {
                  // creo un account con le info del lead
                  let fullName = this.productService.polishProductName(lead.fullName)
                  // getResponse
                  await this.sendGetResponseInfo(this.getResponseId, fullName, lead.email)
                  let request: RegisterMinimalRequest = {
                    firstName: fullName.split(' ')[0],
                    lastName: fullName.split(' ')[1],
                    email: lead.email,
                    phone: lead.phone
                  }
                  await this.auth.registerMinimal(request)
                    .then(async result => {
                      this.registrationMessage = "Abbiamo creato un account per permetterti di visualizzare il prodotto da acquistato, controlla la mail per istruzioni su come proseguire."
                      let isSepa = paymentCheck.paymentIntentStatus === 'processing'
                      await this.orderDetailService.assignProductToNewAccount(this.product.productId, result.accountId, paymentCheck.paymentIntentId, paymentCheck.amountTotal, isSepa, this.product.duration, this.product.freeTrialDuration, this.product.typePurchases)
                        .then(() => {
                          this.finishedProcess = true
                          this.alreadyAssigned = true
                          this.registrationMessage = "Abbiamo creato un account per permetterti di visualizzare il prodotto da acquistato, controlla la mail per istruzioni su come proseguire."
                        }).catch(() => {
                          this.toastr.warning('assegnazione fallita')
                        })
                    }).catch(() => {
                      // qua devi fare una get dell'account per avere l'account id e poi
                      // procedere con l'assegnazione
                      this.loading = false
                      this.finishedProcess = true
                      this.toastr.warning("Un altro utente si è registrato con questa mail, se sei tu prova a fare l'accesso o a recuperare la password, dopodiché contattaci per ottenere il prodotto da te acquistato", 'Attenzione')
                    })
                  // se non lo trovo utilizzo i dati forniti da Stripe
                }).catch(async () => {
                  let fullName = paymentCheck.billingName
                  await this.sendGetResponseInfo(this.getResponseId, fullName, paymentCheck.customer.email)
                  let request: RegisterMinimalRequest = {
                    firstName: fullName.split(' ')[0],
                    lastName: fullName.split(' ')[1],
                    email: paymentCheck.customer.email,
                    phone: paymentCheck.customer.phone
                  }

                  this.accountEmail = request.email
                  // Faccio la registrazione con questi dati
                  await this.createAccount(request)
                    .then(async result => {
                      this.registrationMessage = "Abbiamo creato un account per permetterti di visualizzare il prodotto da acquistato, controlla la mail per istruzioni su come proseguire."
                      let productName = this.productService.polishProductName(this.productName)
                      if (productName != undefined) {
                        await this.getProduct(productName)
                        // assegno il prodotto al nuovo account
                        let isSepa = paymentCheck.paymentIntentStatus === 'processing'
                        await this.orderDetailService.assignProductToNewAccount(this.product.productId, result.accountId, paymentCheck.paymentIntentId, paymentCheck.amountTotal, isSepa, this.product.duration, this.product.freeTrialDuration, this.product.typePurchases)
                          .then(() => {
                            this.loading = false
                            this.finishedProcess = true
                            this.alreadyAssigned = true
                            this.registrationMessage = "Il processo è andato a buon fine, controlla la tua email per confermare l'account e per visualizzare le credenziali di accesso."
                          }).catch(() => {
                            this.loading = false
                            this.finishedProcess = true
                            this.toastr.warning('assegnazione fallita')
                          })
                      }
                      // Mostro all'utente un errore in caso di email gia' presente nel database
                    }).catch(() => {
                      this.loading = false
                      this.finishedProcess = true
                      this.alreadyAssigned = true
                      this.titleMessage = "Prodotto assegnato"
                      this.registrationMessage = "Effettua il login e completa l'account per visualizzarlo."
                    })
                })
            }
          } else {
            this.toastr.warning("C'è stato un errore nel caricare la transazione, aggiorna la pagina o contatta il servizio clienti tramite il pulsante in basso a destra.", "Attenzione")
          }
        })
    }
      //#endregion
    // #region Workflow PayPal
    else {
      if (this.isPaypal === 'paypal' && this.transactionStatus === 'COMPLETED') {
        // carica prodotto (lo mostro all'utente)
        let productName = this.productService.polishProductName(this.productName)
        if (productName != undefined) {
          await this.getProduct(productName)
        }
        // Controllo valido sia per landing che per shop, (landing restituisce 'undefined', shop non restituisce niente, quindi undefined)
        if (this.leadName === 'undefined' || this.leadEmail === 'undefined' || this.leadName === undefined || this.leadEmail === undefined) {
          await this.paypalService.getTransaction(this.paypalOrderID)
            .then(async order => {

              this.route.queryParams.subscribe(params => {
                this.getResponseId = params['pyplGrId']
              })

              await this.sendGetResponseInfo(this.getResponseId, `${order.payer.name.given_name} ${order.payer.name.surname}`, order.payer.email_address)

              await this.paypalService.sendPurchaseConfirmationEmail(
                order.payer.name.given_name,
                order.payer.email_address,
                this.product.name,
                this.product.imageUrl)
                .then(() => {
                })

              const request: RegisterMinimalRequest = {
                firstName: order.payer.name.given_name,
                lastName: order.payer.name.surname,
                email: order.payer.email_address
              }

              this.accountEmail = request.email
              await this.createAccount(request)
                .then(async result => {
                  this.registrationMessage = "Abbiamo creato un account per permetterti di visualizzare il prodotto da te acquistato, controlla la mail per istruzioni su come proseguire."
                  await this.orderDetailService.assignProductToNewAccount(this.product.productId, result.accountId, this.tx_id, parseFloat(this.transactionAmount), false, this.product.duration, this.product.freeTrialDuration, this.product.typePurchases)
                    .then(async () => {
                      await this.hubSpotService.hubSpotConfirmPurchase(result.email, this.product.name, parseFloat(this.transactionAmount), PaymentType.PayPal)  

                      this.loading = false
                      this.finishedProcess = true
                      this.alreadyAssigned = true
                      this.registrationMessage = "Il processo è andato a buon fine, controlla la tua email per confermare l'account e per visualizzare le credenziali di accesso."
                    }).catch(() => {
                      this.loading = false
                      this.finishedProcess = true
                      this.toastr.warning('assegnazione fallita')
                    })
                }).catch(() => {
                  this.finishedProcess = true
                  this.loading = false;
                  this.alreadyAssigned = true
                  this.titleMessage = "Prodotto assegnato"
                  this.registrationMessage = "Effettua il login e completa l'account per visualizzarlo."
                }).finally(() => {
                  this.loading = false
                })
            })
        } else {
          const request: RegisterMinimalRequest = {
            firstName: this.leadName.split(' ')[0],
            lastName: this.leadName.split(' ')[1],
            email: this.leadEmail
          }

          await this.createAccount(request)
            .then(async result => {
              this.registrationMessage = "Abbiamo creato un account per permetterti di visualizzare il prodotto da te acquistato, controlla la mail per istruzioni su come proseguire."
              await this.orderDetailService.assignProductToNewAccount(this.product.productId, result.accountId, this.tx_id, parseFloat(this.transactionAmount), false, this.product.duration, this.product.freeTrialDuration, this.product.typePurchases)
                .then(async () => {
                  await this.hubSpotService.hubSpotConfirmPurchase(result.email, this.product.name, parseFloat(this.transactionAmount), PaymentType.PayPal)  

                  this.loading = false
                  this.finishedProcess = true
                  this.registrationMessage = "Il processo è andato a buon fine, controlla la tua email per confermare l'account e per visualizzare le credenziali di accesso."
                }).catch(() => {
                  this.loading = false
                  this.finishedProcess = true
                  this.toastr.warning("C'è stato un errore in fase di assegnazione del prodotto, aggiorna la pagina o contatta il servizio clienti tramite il pulsante in basso a destra.")
                })
            }).catch(() => {
              this.finishedProcess = true
              this.loading = false;
              this.alreadyAssigned = true
              this.titleMessage = "Prodotto assegnato"
              this.registrationMessage = "Effettua il login e completa l'account per visualizzarlo."
            })
        }
      }
    }
  }

  //#endregion

  async resendEmailVerification() {
    let request: SendEmailVerification = {
      email: this.accountEmail
    }
    await this.auth.sendEmailVerification(request)
      .then(() => {
        this.toastr.success("Email di conferma re-inviata con successo, controlla anche nella casella spam.");
      }).catch(() => {
        this.toastr.warning("C'è stato un errore nell'invio della mail, contatta il servizio clienti tramite il pulsante in basso a destra.", "Attenzione")
      })
  }

  async resendCredentials() {
    let request: SendEmailVerification = {
      email: this.accountEmail
    }
    await this.auth.resendAccountCredentials(request)
      .then(() => {
        this.toastr.success("Email re-inviata con successo, controlla anche nella casella spam.");
      }).catch(() => {
        this.toastr.warning("C'è stato un errore nell'invio della mail, contatta il servizio clienti tramite il pulsante in basso a destra.", "Attenzione")
      })
  }

  async getProduct(productName: string) {
    this.product = await this.productService.getProductMinimalByName(productName)
  }

  async createAccount(request: RegisterMinimalRequest) {
    return await this.auth.registerMinimal(request)
  }

  async isStripePaymentSuccessful(sessionId: string) {
    return await this.stripe.getPaymentIntentStatus(sessionId)
  }

  async sendGetResponseInfo(grId: string, name: string, email: string) {
    if (grId === null || grId === undefined || grId === '') {
      return
    }

    let info: AddContactOptions = {
      name: name,
      campaign: {
        campaignId: grId
      },
      email: email,
      dayOfCycle: '0',
      ipAddress: '0.0.0.0',
      customFieldValues: []
    }

    await this.getResponse.addContact(info)
      .then(() => {
        console.log('getResponse added', info)
      })
      .catch(() => {
        console.log('error on getResponse', info)
      })
  }
}
