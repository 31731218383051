import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '@/helpers/services/auth.service';
import { IAuthenticateResponse } from '@/helpers/models/authenticate.model';
import { SEOService } from '@/helpers/services/seo.service';
import { fade } from "@/helpers/animations/fade.animation";
import { iif, Subscription } from "rxjs";
import { AutoUnsubscribe } from "ngx-auto-unsubscribe-decorator";
import { ActivatedRoute, Router, RouterStateSnapshot } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DiscordPattern } from "@/helpers/patterns/validators.patterns";
import { SubscriptionService } from "@/helpers/services/subscription.service";
import { mergeMap, take } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [fade]
})
export class HomeComponent implements OnInit {

  loginShow: boolean = false;
  confirmShow: boolean = false;
  currentUser: IAuthenticateResponse;
  @AutoUnsubscribe()
  currentUserSubscription: Subscription

  website = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://top-tradingacademy.com',
    name: 'Top Trading Academy',
    contactPoint: {
      '@type': 'ContactPoint',
      availableLanguage: 'IT',
      email: 'info@top-tradingacademy.com',
      contactType: 'Customer Service'
    }
  };

  organization = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://top-tradingacademy.com',
    name: 'Top Trading Academy',
    contactPoint: {
      '@type': 'ContactPoint',
      availableLanguage: 'IT',
      email: 'info@top-tradingacademy.com',
      contactType: 'Customer service'
    }
  }

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private seo: SEOService,
    private authService: AuthService) {
    this.seo.updateTitle('Home | Top-Trading Academy')
    this.seo.updateDescription("Fai Trading ma non riesci ad essere profittevole? I nostri corsi basati sui Cicli di Borsa e sull'utilizzo dei Volumi cambieranno il tuo modo di fare Trading")
    this.seo.updateOgUrl('https://top-tradingacademy.com')
  }

  async ngOnInit() {
    this.currentUserSubscription = this.authService.currentUserSubject.subscribe(async user => {
      this.currentUser = user
    })
    await this.checkDiscordUnverifiedEmail()
    await this.checkAccountCompletion()
    this.showDiscordDownloadModal()
    this.currentUserSubscription.add(
      this.authService.shouldConfirmAccount.subscribe(shouldConfirm => {
        this.confirmShow = shouldConfirm
      })
    )

    if (history.state.data != undefined) {
      if (history.state.data.loginShow != undefined)
        this.loginShow = history.state.data.loginShow
    }
  }

  toggleLoginModal = () => this.loginShow = !this.loginShow

  showLogin = (event: any) => this.loginShow = !this.loginShow

  showConfirm = (event: any) => this.confirmShow = !this.confirmShow

  /**
   * Logs in a user that came from Discord OAuth2 login.
   * Checks if the user has completed the account and if it hasn't
   * opens the modal to complete the account.
   * Happens at every page refresh until the user completes the account.
   * The account completion requirement also works for non discord OAuth2 users.
   */
  async checkAccountCompletion() {
    const account = Object.fromEntries(new URLSearchParams(location.search))

    if (Object.keys(account).length > 0 && account.accountId) {
      await this.clearRoute()
      const _temp = JSON.stringify(account)
      const user: IAuthenticateResponse = JSON.parse(_temp)
      // user is not logged in, log him in and check if he completed the account
      this.authService.setCurrentUser(user)
      this.toastr.success('Ci fa piacere riaverti tra noi!', `Ciao ${user.firstName}!`)
      const returnUrl = localStorage.getItem('tta-temp-returnUrl')
      await this.router.navigate([returnUrl || '/'])
        .then(() => {
          localStorage.removeItem('tta-temp-returnUrl')
        })
      if (this.checkCompletedAccount(user)) {
        this.authService.shouldConfirmAccount.next(true)
      }
    }
    // no user to login so check for account completion
    else if (this.currentUser?.jwtToken) {
      if (this.checkCompletedAccount(this.currentUser)) {
        this.authService.shouldConfirmAccount.next(true)
      }
    }
  }

  checkCompletedAccount(user): boolean {
    if (user != undefined) {
      return user.fiscalCode == undefined || false || user.fiscalCode == ''
    }
    return false
  }

  async checkDiscordUnverifiedEmail() {
    const error = this.route.snapshot.queryParams['discordError']
    if (error) {
      const containsAuthError: boolean = error.includes('iscriverti manualmente')
      this.toastr.info(error, null, {
        timeOut: 10000,
      })
        .onTap
        .pipe(containsAuthError ? take(1) : take(0))
        .subscribe(() => window.open(environment.DiscordInviteLink, "_blank"))
      await this.clearRoute()
    }
  }

  /**
   * Controlla se mostrare la modal con i link per scaricare discord
   * (la mostra solo agli utenti che hanno uno username
   * che rispetta il formato discord)
   */
  showDiscordDownloadModal() {
    if (localStorage.getItem('tta-discord-app-download') == null) {
      this.authService.shouldShowDiscordAppDownload.next(true)
      localStorage.setItem('tta-discord-app-download', 'true')
    }
  }

  async clearRoute() {
    await this.router.navigate(
      [], {
        relativeTo: this.route,
        queryParams: {}
      }
    )
  }
}
