import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-calendly-button',
  templateUrl: './calendly-button.component.html',
  styleUrls: ['./calendly-button.component.scss']
})
export class CalendlyButtonComponent implements OnInit {
  calendlyUrl: string = environment.calendlyUrl;
  calendlyUrlOneToOne: string = environment.calendlyUrlOneToOne;

  constructor() { }

  ngOnInit(): void {
    
  }
}
