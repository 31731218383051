import { Component } from '@angular/core';
import { fade } from '../../animations/fade.animation';

@Component({
  selector: 'app-loading-animation',
  animations: [ fade ],
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss']
})
export class LoadingAnimationComponent {

  loading: boolean = true
  constructor() { }
}
