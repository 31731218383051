import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { SubscriptionData } from "@/helpers/models/subscriptionData.model";

@Injectable({providedIn: 'root'})
export class SubscriptionService {

  public subscriptionData: Subject<SubscriptionData>
  public whichPrice: BehaviorSubject<string>

  constructor() {
    this.subscriptionData = new Subject<SubscriptionData>();
    this.whichPrice = new BehaviorSubject<string>(null);
  }
}