<div *ngIf="this.auth.shouldConfirmAccount.asObservable() | async" [@fade]="confirmShow" aria-labelledby="modal-title"
     aria-modal="true" class="fixed z-40 inset-0 overflow-y-auto overscroll-contain" role="dialog">
  <div
    class="transition-std ease-in-out flex items-end justify-center min-h-screen h-full w-full pt-4 sm:px-4 pb-20 text-center sm:block sm:p-0">
    <div aria-hidden="true" class="fixed inset-0 bg-customDark opacity-70 transition-opacity"></div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

    <div
      class="inline-flex m-auto rounded-lg text-left shadow-xl transform transition-std sm:my-8 sm:align-middle w-full">

      <div
        class="flex bg-customDark border rounded-lg overflow-visible border-gray-700 px-4 pt-5 pb-4 sm:p-10 sm:pb-4 w-full">
        <div class="w-full mx-auto mt-10 sm:mt-0">
          <div class="w-full m-auto">
            <div class="mt-5 md:mt-0 md:col-span-6">
              <form (ngSubmit)="onSubmit()" [formGroup]="form">
                <div class="flex flex-col w-full mx-auto">
                  <h2
                    class="pt-8 sm:pt-0 mb-6 underline underline-offset-4 decoration-redCustom text-center text-3xl text-bold text-gray-300">
                    Completa Account
                  </h2>
                  <p class="flex flex-col text-gray-300 text-center text-sm my-4">
                    <span>
                      Inserisci i dati richiesti per confermare il tuo account e visualizzare il prodotto da te acquistato.
                    </span>

                  </p>
                  <p *ngIf="this.auth.shouldAddDiscordAccount.asObservable() | async"
                     class="flex space-x-2 text-gray-300 mx-auto mt-4">
                    <span class="text-redCustom font-bold">ATTENZIONE: </span>
                    Una volta inserito i dati sarai reindirizzato verso il login con Discord.
                  </p>
                </div>
                <div
                  class="w-full m-auto overflow-hidden sm:rounded-md flex flex-col sm:flex-row space-y-4 sm:space-y-0">
                  <!-- ANAGRAFICA -->
                  <div class="w-full sm:p-6">
                    <h1
                      class="text-2xl text-gray-300 text-center sm:text-left underline underline-offset-4 decoration-redCustom mb-4">
                      Anagrafica</h1>
                    <div class="grid grid-cols-6 gap-x-6 lg:gap-x-12 gap-y-3 md:gap-y-6">
                      <div class="col-span-6">
                        <label class="label-std-left-validated">Tipologia Utente</label>
                        <select [(ngModel)]="currentUser.kindOfClient" class="input-std-dark"
                                formControlName="kindOfClient"
                                name="title">
                          <option *ngFor="let item of kindOfClient | enumToArray"
                                  [ngValue]='item.index'>{{item.name}}
                          </option>
                        </select>
                        <p *ngIf="m.kindOfClient.invalid && m.kindOfClient.dirty"
                           class="invalid-label">
                          Tipologia necessaria.
                        </p>
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated" for="firstName">Nome</label>
                        <input [(ngModel)]="currentUser.firstName"
                               [value]="form.get('firstName')?.value | titlecase"
                               class="input-std-dark" formControlName="firstName" id="firstName"
                               name="firstName"
                               placeholder="Nome"
                               type="text">
                        <p *ngIf="m.firstName.invalid && m.firstName.dirty"
                           class="invalid-label">
                          Nome necessario.
                        </p>
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated">Cognome</label>
                        <input [(ngModel)]="currentUser.lastName"
                               [value]="form.get('lastName')?.value | titlecase"
                               class="input-std-dark" formControlName="lastName" name="lastName"
                               placeholder="Cognome"
                               type="text">
                        <p *ngIf="m.lastName.invalid && m.lastName.dirty" class="invalid-label">
                          Cognome necessario.
                        </p>
                      </div>
                      <div class="col-span-6 mx-auto-0">
                        <label class="label-std-left-validated">Codice Fiscale</label>
                        <input (ngModelChange)="currentUser.fiscalCode = $event?.toUpperCase()"
                               [(ngModel)]="currentUser.fiscalCode"
                               class="input-std-dark" formControlName="fiscalCode"
                               name="fiscalCode"
                               placeholder="RSSMRA07P01F205O"
                               type="text">
                        <div *ngIf="m.fiscalCode.invalid">
                          <p *ngIf="m.fiscalCode.errors?.minlength" class="invalid-label">
                            Devono esserci 16 caratteri.
                          </p>
                          <p *ngIf="m.fiscalCode.errors?.maxlength" class="invalid-label">
                            Massimo 16 numeri.
                          </p>
                        </div>
                      </div>
                      <!-- IMPRESA -->
                      <div *ngIf="currentUser.kindOfClient === kindOfClient.Impresa" [@fade]
                           class="col-span-6 md:col-span-3">
                        <label class="label-std-left-validated">P.IVA</label>
                        <input [(ngModel)]="currentUser.vatNumber" class="input-std-dark"
                               formControlName="vatNumber"
                               name="vatNumber"
                               placeholder="12345678910"
                               type="text">
                        <div *ngIf="m.vatNumber.dirty && m.vatNumber.invalid">
                          <p *ngIf="m.vatNumber.errors?.minlength" class="invalid-label">
                            Devono esserci 11 caratteri.
                          </p>
                          <p *ngIf="m.vatNumber.errors?.maxlength" class="invalid-label">
                            Massimo 11 numeri.
                          </p>
                        </div>
                      </div>
                      <div *ngIf="currentUser.kindOfClient === kindOfClient.Impresa" [@fade]
                           class="col-span-4 md:col-span-3">
                        <label class="label-std-left-validated">Codice
                          Univoco</label>
                        <input [(ngModel)]="currentUser.codiceUnivoco"
                               [value]="form.get('codiceUnivoco').value | uppercase"
                               class="input-std-dark"
                               formControlName="codiceUnivoco"
                               name="vatNumber"
                               placeholder="VF658YP"
                               type="text">
                        <p *ngIf="m.codiceUnivoco.invalid && m.codiceUnivoco.dirty"
                           class="invalid-label">
                          Codice Univoco necessario.
                        </p>
                        <p *ngIf="m.codiceUnivoco.errors?.minlength || m.codiceUnivoco.errors?.maxlength"
                           class="invalid-label">
                          Il Codice Univoco ha una lunghezza di 7 caratteri.
                        </p>
                      </div>
                      <div *ngIf="currentUser.kindOfClient === kindOfClient.Impresa" [@fade]
                           class="col-span-6 md:col-span-3">
                        <label class="label-std-left-validated">PEC</label>
                        <input [(ngModel)]="currentUser.pec"
                               [value]="form.get('pec').value | lowercase"
                               class="input-std-dark"
                               formControlName="pec" name="fiscalCode"
                               placeholder="mario@mario.pec.com"
                               type="text">
                        <p *ngIf="m.pec.invalid && m.pec.dirty" class="invalid-label">
                          PEC necessaria.
                        </p>
                      </div>
                      <!-- FINE IMPRESA -->
                      <div class="col-span-6 md:col-span-3">
                        <label class="label-std-left-validated">Indirizzo</label>
                        <input [(ngModel)]="currentUser.street"
                               [value]="form.get('street').value | titlecase"
                               class="input-std-dark"
                               formControlName="street" name="street"
                               placeholder="Via Mario Rossi, 10"
                               type="text">
                        <p *ngIf="m.street.invalid && m.street.dirty" [@fade]
                           class="invalid-label">
                          Indirizzo necessario.
                        </p>
                      </div>
                      <div class="col-span-4 md:col-span-3">
                        <label class="label-std-left-validated">Città</label>
                        <input [(ngModel)]="currentUser.city"
                               [value]="form.get('city').value | titlecase"
                               class="input-std-dark"
                               formControlName="city" name="city" placeholder="Milano"
                               type="text">
                        <p *ngIf="m.city.invalid && m.city.dirty" [@fade] class="invalid-label">
                          Città necessaria.
                        </p>
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated">
                          Provincia
                        </label>
                        <input [(ngModel)]="currentUser.state"
                               [value]="form.get('state').value | titlecase"
                               class="input-std-dark"
                               formControlName="state" name="state" placeholder="Milano"
                               type="text">
                        <p *ngIf="m.state.invalid && m.state.dirty" class="invalid-label">
                          Provincia necessaria.
                        </p>
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated">CAP</label>
                        <input [(ngModel)]="currentUser.postalCode" autocomplete="postal-code"
                               class="input-std-dark"
                               formControlName="postalCode"
                               name="postalCode" placeholder="20212"
                               type="text">
                        <div *ngIf="m.postalCode.invalid" [@fade]>
                          <p *ngIf="m.postalCode.errors?.minlength" class="invalid-label">
                            Devono esserci 5 numeri.
                          </p>
                          <p *ngIf="m.postalCode.errors?.maxlength" class="invalid-label">
                            Massimo 5 numeri.
                          </p>
                          <p *ngIf="m.postalCode.errors?.pattern" class="invalid-label">
                            Il CAP deve essere un numero.
                          </p>
                        </div>
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated">
                          Paese
                        </label>
                        <input [(ngModel)]="currentUser.country"
                               [value]="form.get('country').value | titlecase"
                               class="input-std-dark" formControlName="country" name="country"
                               placeholder="Italia"
                               type="text">
                        <p *ngIf="m.country.invalid && m.country.dirty" class="invalid-label">
                          Paese necessario.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- CONTATTI -->
                  <div class="w-full pb-4 sm:pb-0 sm:p-6">
                    <h1
                      class="text-2xl text-gray-300 text-center sm:text-left underline underline-offset-4 decoration-redCustom mb-4">
                      Contatti</h1>
                    <div class="grid grid-cols-6 gap-x-6 lg:gap-x-12 gap-y-3 md:gap-y-6 mt-4 sm:mt-0">
                      <div class="col-span-3">
                        <label class="label-std-left-validated">Email</label>
                        <input class="input-std-dark" disabled="disabled" name="email"
                               placeholder="{{ currentUser.email}}"
                               type="text">
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated">Telefono</label>
                        <input [(ngModel)]="currentUser.phone"
                               class="input-std-dark" formControlName="phone"
                               name="phone"
                               placeholder="3291234567"
                               type="text">
                        <div *ngIf="m.phone.invalid && m.phone.touched" class="invalid-label">
                          <p *ngIf="m.phone.invalid && m.phone.dirty">
                            Telefono Non valido.
                          </p>
                          <p *ngIf="m.phone.errors?.required">
                            Telefono necessario.
                          </p>
                        </div>
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated">Social più
                          usato</label>
                        <select [(ngModel)]="currentUser.mostUsedSocial"
                                class="input-std-dark" formControlName="mostUsedSocial"
                                name="mostUsedSocial">
                          <option *ngFor="let item of socialNetworks | enumToArray"
                                  [ngValue]='item.index'>
                            {{item.name}}</option>
                        </select>
                        <p *ngIf="m.mostUsedSocial.touched && m.mostUsedSocial.errors?.required"
                           class="invalid-label">
                          Scegli uno degli elementi proposti.
                        </p>
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated">App più usata</label>
                        <select [(ngModel)]="currentUser.mostUsedSocialOutlets"
                                class="input-std-dark"
                                formControlName="mostUsedSocialOutlets"
                                name="mostUsedSocialOutlets">
                          <option *ngFor="let item of socialOutlets | enumToArray"
                                  [ngValue]='item.index'>
                            {{item.name}}</option>
                        </select>
                        <p *ngIf="m.mostUsedSocialOutlets.touched && m.mostUsedSocialOutlets.errors?.required"
                           class="invalid-label">
                          Scegli uno degli elementi proposti.
                        </p>
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated">Ora di contatto, da</label>
                        <select (change)="hoursRangeBuilder()" [(ngModel)]="fromHour"
                                [ngModelOptions]="{standalone: true}" autocomplete="country"
                                class="input-std-dark-nv"
                                placeholder="8">
                          <option *ngFor="let item of contactHours">{{ item }}</option>
                        </select>
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated">Ora di contatto,
                          a</label>
                        <select (change)="hoursRangeBuilder()" (change)="hoursValidator()"
                                [(ngModel)]="toHour" [ngModelOptions]="{standalone: true}"
                                autocomplete="country" class="input-std-dark-nv"
                                placeholder="20">
                          <option *ngFor="let item of contactHours">{{ item }}</option>
                        </select>
                      </div>
                      <div class="col-span-3">
                        <label class="label-std-left-validated">Cupon</label>
                        <input class="input-std-dark" name="cupon"
                               type="text">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col mx-auto w-full">
                  <div class="flex flex-col w-full mx-auto mt-8">
                    <button
                      class="flex mx-auto rounded-full shadow-dark text-black font-semibold bg-redCustom border-0 py-2 px-8 focus:outline-none hover:bg-redCustom-dark hover:scale-105 transition duration-300 ease-in-out transform text-lg items-center"
                      type="submit">
                      <div *ngIf="!loading && !succeeded">
                        <svg class="h-5 w-5 mr-2" fill="none" stroke="currentColor"
                             viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
                            stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2"/>
                        </svg>
                      </div>
                      <app-loading-animation-small *ngIf="loading" class="mr-2"></app-loading-animation-small>
                      <div *ngIf="!loading && succeeded">
                        <svg class="h-5 w-5 mr-2" fill="none" stroke="currentColor"
                             viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 13l4 4L19 7" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2"/>
                        </svg>
                      </div>
                      <span *ngIf="!loading" class="text-sm">Completa</span>
                      <span *ngIf="loading" class="pr-4 text-sm">Carico</span>

                    </button>
                    <p class="text-xs text-gray-400 mx-auto mt-4">
                      Clicca per confermare le tue scelte.
                    </p>
                    <!--CONSENSI-->
                    <div class="flex flex-col w-full mx-auto mt-4">
                      <div class="inline-flex w-full mx-auto space-x-8 items-center mt-3">
                        <div
                          class="mx-auto w-4/5 md:w-1/4 col-span-6 text-gray-400 text-xs text-left mt-2 space-y-4">
                          <!--TERMINI & CONDIZIONI-->
                          <div class="flex w-full mx-auto flex-row">
                            <div class="flex w-4/5 flex-col">
                              <a href="/terms-conditions" rel="noreferrer"
                                 target="_blank">
                                <span
                                  class="ml-2 before:content-['*'] after:ml-0.5 before:text-red-500 text-gray-300 text-sm hover:text-redCustom transition-std">
                                  Termini & Condizioni
                                </span>
                              </a>
                              <p *ngIf="m.acceptTerms.invalid" class="text-red-500">
                                Devi accettare Termini & Condizioni
                              </p>
                            </div>
                            <div class="flex w-1/5 flex-col items-center">
                              <input [(ngModel)]="currentUser.acceptTerms" [value]="true"
                                     class="radio-redCustom"
                                     formControlName="acceptTerms"
                                     type="checkbox">
                            </div>
                          </div>
                          <!--DISCLAIMER-->
                          <div class="flex w-full mx-auto flex-row">
                            <div class="flex w-4/5 flex-col">
                              <a href="/disclaimer" rel="noreferrer" target="_blank">
                                <span
                                  class="ml-2 before:content-['*'] after:ml-0.5 before:text-red-500 text-gray-300 text-sm hover:text-redCustom transition-std">
                                  Disclaimer
                                </span>
                              </a>
                              <p *ngIf="m.disclaimer.invalid" class="text-red-500">
                                Devi accettare il Disclaimer
                              </p>
                            </div>
                            <div class="flex w-1/5 flex-col items-center">
                              <input [(ngModel)]="currentUser.disclaimer" [value]="true"
                                     class="radio-redCustom"
                                     formControlName="disclaimer"
                                     type="checkbox">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--PRIVACY-->
                    <div class="flex flex-col w-full mx-auto mt-4">
                      <div class="inline-flex mx-auto space-x-8 items-center mt-3">
                        <div class="col-span-6 sm:col-span-6 text-gray-400 text-xs text-left mt-2 space-y-4">
                          <!--TRATTAMENTO-->
                          <div class="flex flex-row space-x-4">
                            <div class="flex flex-col">
                              <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                                Acconsento al trattamento dei dati secondo quanto
                                definito dalla vostra
                                Informativa sulla Privacy esposta in questo sito.
                              </p>
                              <p *ngIf="m.privacyTrattamento.invalid"
                                 [@fade]="m.privacyTrattamento.invalid"
                                 class="text-red-500">
                                Consenso obbligatorio
                              </p>
                            </div>
                            <div class="flex flex-col justify-center">
                              <p>Si</p>
                              <input [(ngModel)]="currentUser.privacyTrattamento"
                                     [value]="true"
                                     class="radio-redCustom"
                                     formControlName="privacyTrattamento"
                                     type="radio">
                            </div>
                            <div class="flex flex-col justify-center">
                              <p>No</p>
                              <input [(ngModel)]="currentUser.privacyTrattamento"
                                     [value]="false"
                                     class="radio-redCustom"
                                     formControlName="privacyTrattamento"
                                     type="radio">
                            </div>
                          </div>
                          <!--CONTATTO-->
                          <div class="flex flex-row space-x-4">
                            <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                              Acconsento ad essere contattato da un vostro consulente e
                              ricevere
                              tramite sms, e-mail o notifiche Push vostre informazioni
                              Pubblicitarie.
                            </p>
                            <div class="flex flex-col justify-center">
                              <p>Si</p>
                              <input [(ngModel)]="currentUser.privacyContatto"
                                     [value]="true" class="radio-redCustom"
                                     formControlName="privacyContatto"
                                     type="radio">
                            </div>
                            <div class="flex flex-col justify-center">
                              <p>No</p>
                              <input [(ngModel)]="currentUser.privacyContatto"
                                     [value]="false" class="radio-redCustom"
                                     formControlName="privacyContatto"
                                     type="radio">
                            </div>
                          </div>
                          <!--THIRD PARTY-->
                          <div class="flex flex-row space-x-4">
                            <p class="before:content-['*'] after:ml-0.5 before:text-red-500 max-w-[15rem]">
                              Acconsento alla comunicazione e/o cessione di dati personali
                              a società
                              terze per le loro attività di marketing, quali l’invio di
                              materiale pubblicitario,
                              la vendita diretta, il compimento di ricerche di mercato e
                              di comunicazione commerciale.
                            </p>
                            <div class="flex flex-col justify-center">
                              <p>Si</p>
                              <input [(ngModel)]="currentUser.privacyThirdParty"
                                     [value]="true" class="radio-redCustom"
                                     formControlName="privacyThirdParty"
                                     type="radio">
                            </div>
                            <div class="flex flex-col justify-center">
                              <p>No</p>
                              <input [(ngModel)]="currentUser.privacyThirdParty"
                                     [value]="false" class="radio-redCustom"
                                     formControlName="privacyThirdParty"
                                     type="radio">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
